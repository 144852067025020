import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { catchError, tap, throwError } from 'rxjs';

@Injectable()
export class PendingRequestsInterceptor implements HttpInterceptor {
  pendingRequests = signal(0);
  errors = signal(0);
  intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    this.pendingRequests.update((value) => value + 1);
    return next.handle(req).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && event.headers != null) {
          this.pendingRequests.update((value) => value - 1);
        }
      }),
      catchError((requestError) => {
        this.pendingRequests.update((value) => value - 1);
        this.errors.update((value) => value + 1);
        return throwError(() => requestError);
      }),
    );
  }

  resetErrors() {
    this.errors.set(0);
  }
}
