import {
  computed,
  DestroyRef,
  inject,
  Injectable,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  NotificationsCommandService,
  NotificationsQueryService,
} from '@salary/common/api/data-services';
import { Guid, Notification, NotificationStatus } from '@salary/common/dumb';
import {
  deleteItems,
  updateAndInsertItems,
} from '@salary/common/standard-facade';
import { NOTIFICATION_SERVICE_TOKEN } from '@salary/common/utils';
import { DateTime } from 'luxon';
import {
  BehaviorSubject,
  catchError,
  filter,
  forkJoin,
  map,
  of,
  takeUntil,
  timer,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsFacade {
  private static readonly POLLING_INTERVAL = 1000 * 30;
  private static readonly POLLING_DUE_TIME = 1000 * 2;
  private static readonly PAGESIZE = 1000;

  private readonly destroyRef = inject(DestroyRef);
  private readonly queryService = inject(NotificationsQueryService);
  private readonly commandService = inject(NotificationsCommandService);
  private readonly notificationService = inject(NOTIFICATION_SERVICE_TOKEN);

  private readonly stopPolling$ = new BehaviorSubject(false);
  private pausePolling = false;
  private readonly isLoading = signal(false);
  private readonly isArchiveLoading = signal(false);
  private readonly entities = signal<Notification[]>([]);
  private readonly archiveEntities = signal<Notification[]>([]);
  private readonly transientEntities = signal<Notification[]>([]);

  constructor() {
    this.listenToIncommingTransientNotifications();
  }

  select = {
    entities: this.entities.asReadonly(),
    archiveEntities: this.archiveEntities.asReadonly(),
    isLoading: this.isLoading.asReadonly(),
    isArchiveLoading: this.isArchiveLoading.asReadonly(),
    transientEntities: this.transientEntities.asReadonly(),
    notArchivedEntities: computed(() =>
      this.entities()
        .concat(this.transientEntities())
        .toSorted(
          (n1, n2) =>
            n2.erstellungszeit?.toMillis() - n1.erstellungszeit?.toMillis(),
        ),
    ),
  };

  queryAllNotifications() {
    this.isLoading.set(true);
    this.queryService
      .getPerPage({
        queryParameters: {
          pageSize: NotificationsFacade.PAGESIZE,
          orderBy: 'erstellungszeit',
          direction: 'desc',
        },
      })
      .pipe(
        map((pageResult) => pageResult.results),
        catchError(() => of(undefined)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (result != null) {
          this.entities.set(result);
        }
        this.isLoading.set(false);
      });
  }

  queryArchiveNotifications() {
    this.isArchiveLoading.set(true);
    this.queryService
      .getPerPage({
        queryParameters: {
          pageSize: NotificationsFacade.PAGESIZE,
          archiviert: true,
          orderBy: 'erstellungszeit',
          direction: 'desc',
        },
      })
      .pipe(
        map((pageResult) => pageResult.results),
        catchError(() => of(undefined)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (result != null) {
          this.archiveEntities.set(result);
        }
        this.isArchiveLoading.set(false);
      });
  }

  startPolling() {
    this.stopPolling$.next(false);
    timer(
      NotificationsFacade.POLLING_DUE_TIME,
      NotificationsFacade.POLLING_INTERVAL,
    )
      .pipe(
        filter(() => !this.pausePolling),
        takeUntil(this.stopPolling$.pipe(filter((stop) => stop))),
      )
      .subscribe(() => this.queryAllNotifications());
  }

  stopPolling() {
    this.stopPolling$.next(true);
  }

  archiveNotification(notification: Notification) {
    if (
      notification.status === NotificationStatus.Archiviert ||
      notification.transient
    ) {
      return;
    }
    const notificationWithArchiveState = {
      ...notification,
      status: NotificationStatus.Archiviert,
    };
    this.archiveEntities.set(
      updateAndInsertItems(
        this.archiveEntities(),
        notificationWithArchiveState,
      ),
    );
    this.entities.set(deleteItems(this.entities(), notification));
    this.commandService
      .update(notificationWithArchiveState)
      .pipe(
        map(() => true),
        catchError(() => of(false)),
        filter((succeeded) => !succeeded),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.entities.set(updateAndInsertItems(this.entities(), notification));
        this.archiveEntities.set(
          deleteItems(this.archiveEntities(), notification),
        );
      });
  }

  restoreNotification(notification: Notification) {
    if (
      notification.status !== NotificationStatus.Archiviert ||
      notification.transient
    ) {
      return;
    }
    const notificationWithRestoredState = {
      ...notification,
      status: NotificationStatus.Gelesen,
    };
    this.archiveEntities.set(deleteItems(this.archiveEntities(), notification));
    this.entities.set(
      updateAndInsertItems(this.entities(), notificationWithRestoredState),
    );
    this.commandService
      .update(notificationWithRestoredState)
      .pipe(
        map(() => true),
        catchError(() => of(false)),
        filter((succeeded) => !succeeded),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.entities.set(deleteItems(this.entities(), notification));
        this.archiveEntities.set(
          updateAndInsertItems(this.archiveEntities(), notification),
        );
      });
  }

  statusReadNotification(notification: Notification) {
    if (notification.status !== NotificationStatus.Neu) return;
    const notificationWithReadState = {
      ...notification,
      status: NotificationStatus.Gelesen,
    };
    if (!notification.transient) {
      this.entities.set(
        updateAndInsertItems(this.entities(), notificationWithReadState),
      );
      this.commandService
        .update(notificationWithReadState)
        .pipe(
          map(() => true),
          catchError(() => of(false)),
          filter((succeeded) => !succeeded),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() =>
          this.entities.set(
            updateAndInsertItems(this.entities(), notification),
          ),
        );
    } else {
      this.transientEntities.set(
        updateAndInsertItems(
          this.transientEntities(),
          notificationWithReadState,
        ),
      );
    }
  }

  deleteNotification(notification: Notification) {
    if (!notification.transient) {
      if (notification.status === NotificationStatus.Archiviert) {
        this.archiveEntities.set(
          deleteItems(this.archiveEntities(), notification),
        );
      } else {
        this.entities.set(deleteItems(this.entities(), notification));
      }
      this.commandService
        .deleteRestorable(notification)
        .pipe(
          map(() => true),
          catchError(() => of(false)),
          filter((succeeded) => !succeeded),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          if (notification.status === NotificationStatus.Archiviert) {
            this.archiveEntities.set(
              updateAndInsertItems(this.archiveEntities(), notification),
            );
          } else {
            this.entities.set(
              updateAndInsertItems(this.entities(), notification),
            );
          }
        });
    } else {
      this.transientEntities.set(
        deleteItems(this.transientEntities(), notification),
      );
    }
  }

  deleteAllNotifications(archived = false) {
    this.pausePolling = true;
    const notificationsToDelete = archived
      ? this.archiveEntities()
      : this.entities();
    if (archived) {
      this.archiveEntities.set([]);
    } else {
      this.entities.set([]);
      this.transientEntities.set([]);
    }
    forkJoin(
      (archived
        ? [NotificationStatus.Archiviert]
        : [NotificationStatus.Neu, NotificationStatus.Gelesen]
      ).map((status) =>
        this.commandService
          .deleteAll(status)
          .pipe(catchError(() => of(status))),
      ),
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((failedStatus) => {
        this.pausePolling = false;
        const failures = failedStatus.filter((status) => status != null);
        if (failures.length === 0) {
          return;
        }
        if (archived) {
          this.archiveEntities.set(notificationsToDelete);
        } else {
          this.entities.set(
            notificationsToDelete.filter((notification) =>
              failedStatus.includes(<number>notification.status),
            ),
          );
        }
      });
  }

  private listenToIncommingTransientNotifications() {
    this.notificationService.notifications$
      .pipe(
        map((notification) => ({
          id: Guid.create(),
          titel: notification.message,
          nachricht: notification.additionalMessage,
          erstellungszeit: DateTime.now(),
          author: notification.source?.name,
          status: NotificationStatus.Neu,
          ergebnisLink: undefined,
          transient: true,
          authorIconName: notification.source?.iconName,
        })),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((notification) =>
        this.transientEntities.set(
          updateAndInsertItems(this.transientEntities(), notification),
        ),
      );
  }
}
