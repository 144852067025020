import {
  DestroyRef,
  Injectable,
  Injector,
  OnDestroy,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { isSalaryError } from '@salary/common/api/base-http-service';
import { BaseModel, BasePageModel, Guid } from '@salary/common/dumb';
import {
  PagingInfo,
  QueryPagePayload,
  StandardFacade,
} from '@salary/common/standard-facade';
import { createCopy, mergeDeep } from '@salary/common/utils';
import { Subject, filter, map, take } from 'rxjs';
import { BreadcrumbService } from '../../layout';
import { DataExchangeService } from '../../router-management';
import { RouteBackService } from '../../router-management/route-back.service';
import { ToolbarDefinition } from '../../utils';
import {
  ProgressIndicationService,
  ProgressOperationType,
} from './progress-indication.service';
import { RecordNavigationComponent } from './record-navigation-component';

@Injectable()
export class RecordNavigationService implements OnDestroy {
  lastDisabled = signal(true);
  previousDisabled = signal(true);
  actionsVisible = signal(false);
  private facade: StandardFacade<unknown>;
  private component: RecordNavigationComponent;
  private rowCount = 0; //base index 1;
  private currentRowNumber: number; //base index 1;
  private currentObjectId = signal<string>(undefined);
  private currentPagingInfo = signal<PagingInfo>(undefined);
  private currentEntites = signal<BaseModel[]>([]);
  private lastRequest: QueryPagePayload;
  private lastRequestfacadeIdentifier: string;
  private requestedRowNumberByNavigationEvent: number;
  private destroyRef = inject(DestroyRef);
  private injector = inject(Injector);
  private dataExchangeService = inject(DataExchangeService);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private routeBackService: RouteBackService,
    private progressIndicatorService: ProgressIndicationService,
    private breadcrumbService: BreadcrumbService,
  ) {
    breadcrumbService.navigateToEntityByNumberCallback =
      this.navigateToRowNumberByNumberOnPage.bind(this);
    breadcrumbService.isNextPageAvailable = computed(
      () =>
        this.currentPagingInfo()?.lastRowOnPage <
        this.currentPagingInfo()?.rowCount,
    );
    breadcrumbService.isPreviousPageAvailable = computed(
      () => this.currentPagingInfo()?.firstRowOnPage > 1,
    );
    breadcrumbService.isLoading =
      this.progressIndicatorService.isProgressActiveByOperationType(
        'OtherLoading',
      );
    this.breadcrumbService.loadNextPageCallback = () =>
      this.requestPage(
        this.currentPagingInfo().lastRowOnPage + 1,
        false,
        'OtherLoading',
      );
    breadcrumbService.loadPreviousPageCallback = () =>
      this.requestPage(
        this.currentPagingInfo().firstRowOnPage - 1,
        false,
        'OtherLoading',
      );

    routeBackService
      .getRouteBackInfo(activatedRoute)
      .pipe(
        filter(
          (info) =>
            info.pageRequest != null &&
            info.focusedRow != null &&
            info.facadeIdentifier != null,
        ),
        take(1),
        takeUntilDestroyed(),
      )
      .subscribe((info) => {
        this.lastRequest = info.pageRequest;
        this.lastRequestfacadeIdentifier = info.facadeIdentifier;
        this.currentRowNumber = info.focusedRow + 1;
        if (this.facade != null) {
          this.init();
        }
      });
  }

  setComponent(component: RecordNavigationComponent) {
    this.component = component;
    this.facade = component.facade;
    if (this.lastRequest && this.facade != null) {
      this.init();
    }

    effect(
      () => {
        const model = this.component.model();
        const initialized = this.currentPagingInfo() != null;
        untracked(() => {
          if (model != null) {
            this.breadcrumbService.detailViewRecordBreadcrumb.set({
              label: this.component.getFormTitle(model, true),
              url: model.id,
              recordItem: true,
              disabled: this.component.isHinzufuegenRoute() || !initialized,
            });
          }
        });
      },
      { injector: this.injector },
    );

    effect(
      () => {
        const entities = this.currentEntites();
        untracked(() => {
          this.breadcrumbService.availableDetailViewRecordBreadcrumbs.set(
            entities.map((e) => ({
              label: this.component.getFormTitle(e, true),
              url: e.id,
            })),
          );
        });
      },
      { injector: this.injector },
    );
  }

  init() {
    const effectRef = effect(
      () => {
        if (this.currentObjectId() != null) {
          untracked(() => {
            effectRef.destroy();
            if (
              this.lastRequestfacadeIdentifier ===
                this.facade.getIdentifier() &&
              this.currentRowNumber
            ) {
              this.activateNavigation();
              this.initCore();
            }
          });
        }
      },
      { injector: this.injector },
    );

    this.activatedRoute.params
      .pipe(
        map((params) => {
          return params['id'];
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((id) => {
        this.currentObjectId.set(id);
      });
  }

  private initCore() {
    const queryPageResult = <BasePageModel<unknown>>(
      this.dataExchangeService.dataExchangeObject
    );
    if (!queryPageResult?.results) {
      if (this.currentRowNumber) {
        this.requestPage(this.currentRowNumber, true);
      }
      return;
    }
    this.handleQueryPageResult(queryPageResult, true);
  }

  activateNavigation() {
    this.actionsVisible.set(true);
  }

  handleButtonStates() {
    this.previousDisabled.set(
      this.rowCount === 0 || this.currentRowNumber <= 1,
    );
    this.lastDisabled.set(
      this.rowCount === 0 || this.currentRowNumber >= this.rowCount,
    );
  }

  navigateFirst() {
    this.tryNavigateToRowNumber(1);
  }

  navigateNext() {
    this.tryNavigateToRowNumber(
      Math.min(this.currentRowNumber + 1, this.rowCount),
    );
  }

  navigatePrevious() {
    this.tryNavigateToRowNumber(Math.max(this.currentRowNumber - 1, 1));
  }

  navigateLast() {
    this.tryNavigateToRowNumber(this.rowCount);
  }

  navigateToRowNumberByNumberOnPage(numberOnPage: number) {
    this.tryNavigateToRowNumber(
      this.currentPagingInfo().firstRowOnPage + numberOnPage,
    );
  }

  tryNavigateToRowNumber(rowNumber: number) {
    if (
      rowNumber < this.currentPagingInfo().firstRowOnPage ||
      rowNumber > this.currentPagingInfo().lastRowOnPage
    ) {
      this.requestedRowNumberByNavigationEvent = rowNumber;
      this.requestPage(rowNumber);
    } else {
      const entity =
        this.currentEntites()[
          rowNumber - this.currentPagingInfo().firstRowOnPage
        ];
      this.navigateToEntity(entity.id, rowNumber);
    }
  }

  navigateToEntity(id: string, rowNumber: number) {
    this.router
      .navigateByUrl(this.router.url.replace(this.currentObjectId(), id))
      .then((success) => {
        if (success) {
          this.setCurrentRowNumber(rowNumber);
          this.handleButtonStates();
        }
      });
  }

  setCurrentRowNumber(rowNumber: number) {
    this.currentRowNumber = rowNumber;
    this.routeBackService.saveFocusedRow(rowNumber - 1);
  }

  requestPage(
    rowNumber: number,
    force?: boolean,
    operationType: ProgressOperationType = 'Loading',
  ) {
    const pageToRequest = Math.ceil(
      rowNumber /
        this.lastRequest?.endpointConfiguration?.queryParameters?.pageSize,
    );
    const lastRequestedPage =
      this.lastRequest?.endpointConfiguration?.queryParameters?.page;
    if (
      !force === true &&
      lastRequestedPage &&
      lastRequestedPage === pageToRequest
    ) {
      return;
    }
    this.lastRequest = mergeDeep(this.lastRequest, {
      endpointConfiguration: {
        queryParameters: {
          page: pageToRequest,
        },
      },
    });

    const completed$ = new Subject<void>();
    this.progressIndicatorService.registerProgressWithSubject(
      { key: Guid.create(), operationType: operationType },
      completed$,
    );

    this.facade
      .queryPage(this.lastRequest)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((result) => {
        completed$.next();
        if (!isSalaryError(result)) {
          this.handleQueryPageResult(result);
        }
      });
  }

  private handleQueryPageResult(
    result: BasePageModel<unknown>,
    initializationResult = false,
  ) {
    if (
      (result.rowCount === 0 ||
        result.firstRowOnPage > this.currentRowNumber ||
        result.lastRowOnPage < this.currentRowNumber) &&
      initializationResult &&
      this.currentRowNumber
    ) {
      this.requestPage(this.currentRowNumber, true);
      return;
    }
    this.saveToEntityFacade(result);
    this.handleButtonStates();
    if (this.requestedRowNumberByNavigationEvent) {
      this.tryNavigateToRowNumber(this.requestedRowNumberByNavigationEvent);
      this.requestedRowNumberByNavigationEvent = undefined;
    }
  }

  private saveToEntityFacade(info: BasePageModel<unknown>) {
    this.rowCount = info.rowCount;
    if (this.currentPagingInfo()) {
      if (info.firstRowOnPage === this.currentPagingInfo().lastRowOnPage + 1) {
        this.currentPagingInfo.update((oldValue) => ({
          ...info,
          firstRowOnPage: oldValue.firstRowOnPage,
        }));
        this.currentEntites.update((oldValue) =>
          createCopy([...oldValue, ...info.results]),
        );
        return;
      }

      if (info.lastRowOnPage === this.currentPagingInfo().firstRowOnPage - 1) {
        this.currentPagingInfo.update((oldValue) => ({
          ...info,
          lastRowOnPage: oldValue.lastRowOnPage,
        }));
        this.currentEntites.update((oldValue) =>
          createCopy([...info.results, ...oldValue]),
        );
        return;
      }
      if (
        this.currentPagingInfo().firstRowOnPage <= info.firstRowOnPage &&
        this.currentPagingInfo().lastRowOnPage >= info.lastRowOnPage
      ) {
        return;
      }
    }
    this.currentPagingInfo.set(info);
    this.currentEntites.set(createCopy(info.results));
  }

  public initializeRecordToolbarDefinitionObservables(
    firstRecordToolbarDefinition: ToolbarDefinition,
    previousRecordToolbarDefinition: ToolbarDefinition,
    nextRecordToolbarDefinition: ToolbarDefinition,
    lastRecordToolbarDefinition: ToolbarDefinition,
  ) {
    firstRecordToolbarDefinition.buttonDisabled = this.previousDisabled;
    firstRecordToolbarDefinition.buttonVisibility = this.actionsVisible;
    previousRecordToolbarDefinition.buttonDisabled = this.previousDisabled;
    previousRecordToolbarDefinition.buttonVisibility = this.actionsVisible;
    nextRecordToolbarDefinition.buttonDisabled = this.lastDisabled;
    nextRecordToolbarDefinition.buttonVisibility = this.actionsVisible;
    lastRecordToolbarDefinition.buttonDisabled = this.lastDisabled;
    lastRecordToolbarDefinition.buttonVisibility = this.actionsVisible;
  }

  deactivateNavigation() {
    this.actionsVisible.set(false);
    this.breadcrumbService.availableDetailViewRecordBreadcrumbs.set([]);
  }

  ngOnDestroy() {
    this.breadcrumbService.detailViewRecordBreadcrumb.set(undefined);
    this.breadcrumbService.navigateToEntityByNumberCallback = undefined;
    this.breadcrumbService.isNextPageAvailable = undefined;
    this.breadcrumbService.isPreviousPageAvailable = undefined;
    this.breadcrumbService.isLoading = undefined;
    this.breadcrumbService.loadNextPageCallback = undefined;
    this.breadcrumbService.loadPreviousPageCallback = undefined;
  }
}
