<div
  class="navigation-menu-search"
  (click)="focusInput()"
  data-testid="navigation_menu_search"
  cdkOverlayOrigin
  #navigationMenuContainer="cdkOverlayOrigin"
  salarySearchAnimation
  [searchAnimationEnabled]="isExpanded()"
>
  <mat-icon
    class="navigation-menu-search-icon"
    matTooltip="In Navigation suchen..."
    >search</mat-icon
  >
  @if (isExpanded()) {
    <div class="input-panel" @slideInOut>
      <salary-navigation-menu-search-input (navigated)="focusInput()" />
    </div>
  }
</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPositions]="positions"
  (backdropClick)="showInputOverlay.set(false)"
  cdkConnectedOverlayBackdropClass="navigation-menu-search-input-backdrop"
  [cdkConnectedOverlayOrigin]="navigationMenuContainer"
  cdkConnectedOverlayHasBackdrop
  [cdkConnectedOverlayOpen]="showInputOverlay() && !isExpanded()"
>
  <div class="input-panel navigation-menu-search-input-overlay">
    <salary-navigation-menu-search-input
      (inputLostFocus)="hideInputOverlay()"
      (navigated)="hideInputOverlay()"
    />
  </div>
</ng-template>
