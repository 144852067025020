import { isColumn, isColumnControlsCol } from '@ag-grid-community/core';
import { getFieldName } from '@salary/common/dumb';
import { getColumnHideFromColumnDefinitionAndSecurity } from '../ag-grid-column-adapter';
import { ColumnDefinition } from '../column';
import { ListComponent } from '../list.component';

export class ColumnWidthCalculator<T> {
  constructor(private listComponent: ListComponent<T>) {}

  public setDefaultWidthToColumnDefinitions() {
    if (!this.listComponent.columnDefinitions?.()) {
      return;
    }
    const dsmContainer = this.listComponent.element.nativeElement.closest(
      '#dsm-outlet-container',
    );
    let availableSpace: number;
    if (dsmContainer) {
      availableSpace = dsmContainer.offsetWidth;
    } else {
      availableSpace =
        this.listComponent.element.nativeElement.offsetWidth > 0
          ? this.listComponent.element.nativeElement.offsetWidth
          : this.listComponent.agGrid()?.nativeElement?.offsetWidth;
    }
    if (!availableSpace) {
      return;
    }
    availableSpace = this.listComponent
      .validationSettings()
      ?.validationEnabled()
      ? availableSpace - 57
      : availableSpace - 10;
    return this.setColumnWidth(availableSpace);
  }

  private setColumnWidth(availableSpace: number) {
    let visibleColumns = this.listComponent
      .columnDefinitions()
      ?.filter(
        (col) =>
          !getColumnHideFromColumnDefinitionAndSecurity(
            col,
            this.listComponent.columnDefs(),
          ),
      );

    const selectionColumn = this.listComponent.gridApi
      ?.getAllGridColumns()
      ?.find((c) => isColumn(c) && isColumnControlsCol(c));
    const selectionColumnWidth = selectionColumn?.getActualWidth() ?? 0;
    availableSpace -= selectionColumnWidth;

    const identificationColumn = visibleColumns.find(
      (c) => c.identificationProperty,
    );
    if (
      identificationColumn != null &&
      !(visibleColumns.length > 5 || visibleColumns.length === 1)
    ) {
      identificationColumn.width = Math.ceil(availableSpace * 0.2);
      availableSpace = availableSpace - identificationColumn.width;
      visibleColumns = visibleColumns.filter(
        (col) => col != identificationColumn,
      );
    }
    visibleColumns.sort((a) => (a.minWidth ? -1 : 1));
    this.calculate(visibleColumns, availableSpace);
  }

  private calculate(
    columnsLeft: ColumnDefinitionInternal[],
    availableSpace: number,
  ) {
    const widthToSet = Math.ceil(availableSpace / columnsLeft.length);
    for (const column of columnsLeft) {
      if (column.minWidth && widthToSet < column.minWidth) {
        column.width = column.minWidth;
        availableSpace -= column.minWidth;
        columnsLeft = columnsLeft.filter((col) => col != column);
        this.calculate(columnsLeft, availableSpace);
        break;
      }
      const maxWidth = this.getMaxWidth(column);
      if (maxWidth != null && widthToSet > maxWidth) {
        column.width = maxWidth;
        availableSpace -= maxWidth;
        columnsLeft = columnsLeft.filter((col) => col != column);
        this.calculate(columnsLeft, availableSpace);
        break;
      } else {
        column.width = widthToSet;
      }
    }
  }

  private getMaxWidth(columnDef: ColumnDefinitionInternal) {
    const fieldName = getFieldName(columnDef.modelPropertyName);
    const colDefOfColumn = this.listComponent
      .columnDefs()
      .find((colDef) => (fieldName ?? columnDef.columnTitle) === colDef.field);
    if (colDefOfColumn != null) {
      return colDefOfColumn.maxWidth;
    }
    return columnDef.maxWidth;
  }
}

export interface ColumnDefinitionInternal<T = unknown>
  extends ColumnDefinition<T> {
  width?: number;
}
