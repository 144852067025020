import {
  Injectable,
  Injector,
  Signal,
  computed,
  effect,
  inject,
  runInInjectionContext,
} from '@angular/core';
import { PRELOADING_STRATEGY, debounceSignal } from '@salary/common/utils';
import Fuse from 'fuse.js';
import { NavMenuSearchItem, NavigationNode } from '../model';
import { NavigationService } from './navigation.service';

@Injectable()
export class NavigationMenuSearchService {
  private static loadingModulesAlreadyTriggered = false;
  private preloadingStrategy = inject(PRELOADING_STRATEGY);
  private navigationService = inject(NavigationService);
  private injector = inject(Injector);

  private flattenItems(rootNodes: NavigationNode[]) {
    return rootNodes
      .filter((rootNode) => !!rootNode.children)
      .reduce<
        NavigationNode[]
      >((result, rootNode) => [...result, ...rootNode.children], []);
  }

  private convertItems(nodes: NavigationNode[]): NavMenuSearchItem[] {
    return nodes.map((node) => ({
      name: node.text,
      icon: node.icon,
      url: node.path,
      synonyms:
        typeof node.synonyms !== 'function'
          ? node.synonyms
          : this.executeSynonymsFunction(node.synonyms)(),
    }));
  }

  private executeSynonymsFunction(
    fn: () => Signal<string[]>,
  ): Signal<string[]> {
    return runInInjectionContext(
      Injector.create({ providers: [], parent: this.injector }),
      () => fn(),
    );
  }

  private prepareSearch(items: NavMenuSearchItem[]): Fuse<NavMenuSearchItem> {
    return new Fuse(items, {
      keys: [
        { name: 'name', weight: 0.7 },
        { name: 'synonyms', weight: 0.3 },
      ],
      threshold: 0.3,
    });
  }
  private navMenuItems = computed(() =>
    this.convertItems(
      this.flattenItems(this.navigationService.navigationDefinition()),
    ),
  );
  private navMenuItemsDebounced = debounceSignal(this.navMenuItems, 250);
  private navMenuItemsWithSearch = computed(() =>
    this.prepareSearch(this.navMenuItemsDebounced()),
  );

  private ensureModulesLoaded() {
    NavigationMenuSearchService.loadingModulesAlreadyTriggered = true;
    this.preloadingStrategy.preloadAll();
  }

  getItemsByTerm(term: Signal<string>): Signal<NavMenuSearchItem[]> {
    if (!NavigationMenuSearchService.loadingModulesAlreadyTriggered) {
      const loadModulesOnFirstSearch = effect(() => {
        if (term()) {
          this.ensureModulesLoaded();
          loadModulesOnFirstSearch.destroy();
        }
      });
    }
    return computed(() =>
      term()
        ? this.navMenuItemsWithSearch()
            .search(term())
            .map((item) => item.item)
        : [],
    );
  }
}
