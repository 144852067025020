import { Injectable, computed, effect, inject, untracked } from '@angular/core';
import { createCopy } from '@salary/common/utils';
import { SettingsFacade } from '../settings';
import {
  AppSettings,
  DisplaySettingsOptions,
  getDefaultAppSettings,
} from './app-settings.model';

@Injectable({ providedIn: 'root' })
export class AppSettingsFacade {
  static readonly SETTINGS_KEY = 'appSettings';
  private settings = inject(SettingsFacade);
  private appSettings = this.settings.selectBenutzerSettingByKey<AppSettings>(
    AppSettingsFacade.SETTINGS_KEY,
  );
  select = {
    all: computed(() => {
      const settings = this.appSettings();
      return untracked(() =>
        Object.assign(createCopy(getDefaultAppSettings()), settings?.value),
      );
    }),

    setting: (setting: DisplaySettingsOptions) =>
      computed(() => {
        const settings = this.select.all();
        return untracked(() => settings.display.includes(setting));
      }),
    loaded: this.settings.select.isSettingsLoaded,
  };
  update(settings: AppSettings) {
    this.settings.createOrUpdateUserSetting({
      key: AppSettingsFacade.SETTINGS_KEY,
      value: settings,
    });
  }
  constructor() {
    effect(() => {
      const darkMode = this.select.setting('darkMode')();
      untracked(() => {
        if (darkMode) {
          this.settings.createOrUpdateUserSetting(
            { key: 'darkMode', value: true },
            'localStorage',
          );
        } else {
          this.settings.deleteUserSetting('darkMode', 'localStorage');
        }
      });
    });
  }
}
