import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
} from '@angular/core';
import { AuthenticationService } from '@salary/common/authentication';
import { Rolle } from '@salary/common/dumb';
import { LohnkontextAbrechnungenFacade } from '@salary/common/facade';
import { APP_LONG_RUNNING_PROCESS_SERVICE_TOKEN } from '@salary/common/utils';
import { CurrentUserToolbarItemComponent } from '../../current-user';
import {
  LohnkontextAbrechnungszeitraumComponent,
  LohnkontextClosedIconComponent,
  LohnkontextSearchComponent,
} from '../../lohnkontext/components';
import { AppLongRunningProcessToolbarItemComponent } from '../../long-running-process';
import { TermineCarouselComponent } from '../../termine/termine-carousel.component';
import { TermineService } from '../../termine/termine-service';
import { ToolbarDefinition } from '../../utils';
import { ApplicationToolbarItemComponent } from '../application-toolbar-button/application-toolbar-item.component';
import { BrzIconComponent } from '../brz-icon/brz-icon.component';
import { GlobalSearchComponent } from '../global-search/global-search.component';
import { NotificationToolbarItemComponent } from './notification-toolbar-item.component';
import { TipsToolbarItemComponent } from './tips-toolbar-item.component';

@Component({
  selector: 'salary-top-toolbar',
  template: `<salary-toolbar
    classes="top-toolbar"
    data-testid="applicationToolbar"
    hideItemsDirection="leftToRight"
    [toolbarDefinitions]="topToolbarDefinitions"
  />`,
  styles: `
    ::ng-deep .top-toolbar.mat-toolbar {
      font-weight: 400;
      height: 56px;
      padding: 0 3px;
    }

    ::ng-deep
      .toolbar-overflow-menu
      .mat-mdc-menu-content:has(salary-lohnkontext-container) {
      align-items: center;
    }

    :host ::ng-deep {
      .carousel {
        max-width: 295px;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopToolbarComponent {
  private authenticationService = inject(AuthenticationService);
  protected topToolbarDefinitions: ToolbarDefinition[] = [
    {
      title: '...',
      actionType: 'moreOptionButton',
      alwaysRoot: true,
    },
    {
      title: 'BrzIconComponent',
      actionType: 'customComponent',
      componentType: BrzIconComponent,
      componentWidth: 152,
      removable: true,
    },
    {
      title: 'TermineCarouselComponent',
      actionType: 'customComponent',
      componentType: TermineCarouselComponent,
      componentWidth: 295,
      buttonVisibility: inject(TermineService).showTermine,
      removable: true,
      shrink: true,
    },
    {
      title: 'GlobalSearchComponent',
      actionType: 'customComponent',
      componentType: GlobalSearchComponent,
      componentWidth: 250,
      buttonVisibility: computed(
        () => this.authenticationService.benutzerRolle() != Rolle.TS,
      ),
      alignment: 'right',
      removable: true,
    },
    {
      title: 'LohnkontextClosedIconComponent',
      actionType: 'customComponent',
      componentType: LohnkontextClosedIconComponent,
      componentWidth: 32,
      buttonVisibility: inject(LohnkontextAbrechnungenFacade).select.isClosed,
      alignment: 'right',
      removable: true,
    },
    {
      title: 'LohnkontextAbrechnungszeitraumComponent',
      actionType: 'customComponent',
      componentType: LohnkontextAbrechnungszeitraumComponent,
      componentWidth: 169,
      alignment: 'right',
    },
    {
      title: 'LohnkontextSearchComponent',
      actionType: 'customComponent',
      componentType: LohnkontextSearchComponent,
      componentWidth: 332,
      alignment: 'right',
      alwaysRoot: true,
    },
    {
      title: 'AppLongRunningProcessToolbarItemComponent',
      actionType: 'customComponent',
      componentType: AppLongRunningProcessToolbarItemComponent,
      buttonVisibility: inject(APP_LONG_RUNNING_PROCESS_SERVICE_TOKEN)
        .buttonVisible,
      componentWidth: 56,
      alignment: 'right',
      stretch: true,
    },
    {
      title: 'Notifications',
      actionType: 'customComponent',
      componentType: NotificationToolbarItemComponent,
      componentWidth: 56,
      alignment: 'right',
      stretch: true,
    },
    {
      title: 'Tipps',
      actionType: 'customComponent',
      componentType: TipsToolbarItemComponent,
      componentWidth: 56,
      alignment: 'right',
      stretch: true,
    },
    {
      title: 'Help',
      actionType: 'customComponent',
      componentType: ApplicationToolbarItemComponent,
      componentWidth: 56,
      customComponentInput: new Map<string, unknown>([
        ['name', 'contexthelp'],
        ['icon', 'question_mark'],
        ['tooltip', 'Feldhilfe'],
      ]),
      alignment: 'right',
      stretch: true,
    },
    {
      title: 'CurrentUserToolbarItemComponent',
      actionType: 'customComponent',
      componentType: CurrentUserToolbarItemComponent,
      componentWidth: 50,
      alignment: 'right',
      stretch: true,
    },
  ];
}
