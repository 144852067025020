import {
  ChangeDetectionStrategy,
  Component,
  computed,
  signal,
} from '@angular/core';
import { replaceSpecialCharacters } from '@salary/common/dumb';
import {
  FieldType,
  assignFieldValue,
  convertToSignal,
} from '@salary/common/formly';
import { ToolbarDefinition } from '../../../utils';
import { ExpandablePanelFieldConfig } from './expandable-panel-field-config';

@Component({
  selector: 'salary-expandable-panel',
  template: `
    @if (exists() || !field().addRemoveAvailable) {
      @let headerTitle =
        ('headerTitle' | toSignal: field())() | formatObject: field().model();
      @let testId = getTestId(headerTitle);
      <mat-expansion-panel
        [attr.data-testid]="
          'expansionpanel_' + testId | convertSpecialCharacter
        "
        [id]="id"
        [expanded]="field()?.expanded ?? true"
      >
        <mat-expansion-panel-header
          [attr.data-testid]="'panelheader_' + testId | convertSpecialCharacter"
        >
          <mat-panel-title #titleElement>
            <salary-invalid-control-icon
              [field]="field()"
              [testId]="'panelheader_icon_' + testId"
              [connectedTo]="titleElement"
            />
            {{ headerTitle }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        @for (field of field().fieldGroup; track field.id; let i = $index) {
          <salary-form-field
            [salaryFocus]="isFieldToFocus(i)"
            [config]="field"
            [field]="field"
          />
        }
        @if (addRemoveButtonVisible()) {
          <button
            [attr.data-testid]="
              'button_panel_entfernen_' + testId + '(0)'
                | convertSpecialCharacter
            "
            mat-button
            color="primary"
            type="button"
            (click)="remove()"
          >
            Entfernen
          </button>
        }
      </mat-expansion-panel>
    } @else {
      <salary-detail-list-empty-state
        [toolbarDefinition]="hinzufuegenToolbarDefinition()"
        [captionPlural]="field().modelPluralName"
      />
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SalaryExpandablePanelTypeComponent extends FieldType<ExpandablePanelFieldConfig> {
  private focusBecauseOfAdd = false;
  protected hinzufuegenToolbarDefinition = signal<ToolbarDefinition>(undefined);
  protected exists = computed(() => this.field().model?.() != null);
  protected addRemoveButtonVisible = computed(() => {
    if (
      convertToSignal('disabled', this.field())() ||
      this.options?.formState?.disabled?.()
    ) {
      return false;
    }
    return this.field().addRemoveAvailable;
  });

  ngAfterViewInit() {
    this.hinzufuegenToolbarDefinition.set({
      title: 'Hinzufügen',
      actionHandler: () => {
        this.add();
      },
      buttonVisibility: this.addRemoveButtonVisible,
      testId: replaceSpecialCharacters(
        'button_panel_hinzufuegen_' + this.field().key,
      ),
    });
  }

  private add() {
    this.focusBecauseOfAdd = true;
    this.formControl.markAsDirty();
    assignFieldValue(this.field(), {});
    this.options.fieldChanges.next({
      field: this.field(),
      value: {},
    });
    setTimeout(() => this.formControl.updateValueAndValidity());
  }

  protected getTestId(formattedHeaderTitle: string) {
    if (this.field().testId) {
      return this.field().testId;
    }
    if (this.field().addRemoveAvailable) {
      return this.getFieldKey();
    } else {
      return formattedHeaderTitle ?? this.getFieldKey();
    }
  }

  protected getFieldKey() {
    return this.field().key;
  }

  protected remove() {
    this.formControl.reset();
    this.formControl.markAsDirty();
    assignFieldValue(this.field(), null);
    this.options.fieldChanges.next({
      field: this.field(),
      value: undefined,
    });
    setTimeout(() => this.formControl.updateValueAndValidity());
  }

  protected isFieldToFocus(index: number) {
    if (index === 0 && this.focusBecauseOfAdd) {
      this.focusBecauseOfAdd = false;
      return true;
    }
    return false;
  }
}
