import {
  CellClassParams,
  ColDef,
  ColumnState,
  SuppressNavigableCallbackParams,
} from '@ag-grid-community/core';
import { getFieldName } from '@salary/common/dumb';
import { SortOrder } from '../utils';
import { getCustomComparer } from './ag-grid-custom-comparator';
import { CheckboxRendererComponent } from './checkbox.renderer.component';
import { ColumnDefinition } from './column';
import { FileDownloadCellRendererComponent } from './file-download-cell-renderer.component';
import { ListButtonRendererComponent } from './list-button.renderer.component';
import { ListInputRendererComponent } from './list.input.renderer.component';
import { ColumnDefinitionInternal } from './utils/column-width-calculator';

export function createAgGridDefaultColumn(): ColDef {
  return {
    sortable: true,
    sortingOrder: ['asc', 'desc', null],
    tooltipComponent: 'customTooltipComponent',
    suppressHeaderMenuButton: true,
    suppressHeaderContextMenu: true,
    suppressKeyboardEvent: (params) =>
      (params.editing && params.event.key === 'Enter') ||
      params.event.key === 'Delete',
    wrapHeaderText: true,
    autoHeaderHeight: true,
  };
}

export function createAgGridColumn(
  columnDefinition: ColumnDefinitionInternal,
): ColDef {
  const columnDef = initializeColDef(columnDefinition);

  // we should not set headerClass if not defined, because also undefined value  overrides rightAligned logic of ag-grid
  if (columnDefinition.headerClass) {
    columnDef.headerClass = columnDefinition.headerClass;
  }

  if (
    !columnDefinition.editable &&
    !columnDefinition.showBorderOnSelectedCell
  ) {
    columnDef.cellStyle = { ...columnDef.cellStyle, border: 'none' };
  }
  if (
    typeof columnDefinition.editable === 'function' &&
    !columnDefinition.showBorderOnSelectedCell &&
    !columnDef.cellStyle
  ) {
    columnDef.cellStyle = (params: CellClassParams) =>
      params.api.getColumn(params.colDef).isCellEditable(params.node)
        ? undefined
        : { border: 'none' };
  }
  if (columnDefinition.sortOrder) {
    columnDef.sort = convertSortOrder(columnDefinition.sortOrder);
  }

  defineCellRenderer(columnDefinition, columnDef);

  if (columnDefinition.alignRight) {
    columnDef.type = 'rightAligned';
  }
  if (columnDefinition.valueGetter) {
    columnDef.valueGetter = columnDefinition.valueGetter;
  }
  return columnDef;
}

function initializeColDef(columnDefinition: ColumnDefinitionInternal): ColDef {
  const fieldName = getFieldName(columnDefinition.modelPropertyName);
  return {
    cellDataType: false,
    resizable: false,
    headerName: columnDefinition.columnTitle,
    headerTooltip:
      columnDefinition.headerTooltip ?? columnDefinition.columnTitle, //needed, because without customTooltipComponent is not called
    field: fieldName ?? columnDefinition.columnTitle,
    editable: columnDefinition.editable,
    hide: getColumnHideFromColumnDefinition(columnDefinition),
    width: columnDefinition.width,
    sortable: columnDefinition.disableSorting === true || !!fieldName,
    cellStyle: columnDefinition.cellStyle,
    lockVisible:
      columnDefinition.cellRendererOptions?.routerLink != null ||
      columnDefinition.identificationProperty ||
      columnDefinition.lockVisible,
    lockPosition:
      columnDefinition.cellRendererOptions?.lockPosition ??
      (columnDefinition.cellRendererOptions?.routerLink != null ||
        columnDefinition.identificationProperty),
    lockPinned: true,
    suppressNavigable: (params: SuppressNavigableCallbackParams) => {
      const editable = params.colDef.editable;
      return typeof editable === 'function'
        ? !editable(params)
        : !(params.colDef.editable as boolean);
    },
    headerComponentParams: {
      alwaysShowHeaderTooltip: columnDefinition.alwaysShowHeaderTooltip,
    },
    cellEditor: columnDefinition.cellEditor ?? 'inputEditor',
    cellEditorParams: {
      displayFormat: columnDefinition.displayFormat,
      displayText: columnDefinition.displayText,
      editorOptions: columnDefinition.editorOptions,
    },
    comparator: getCustomComparer(
      getFieldName(columnDefinition.sortModelPropertyName),
    ),
    rowGroup: columnDefinition.rowGroup,
    aggFunc: columnDefinition.aggFunc,
    maxWidth: columnDefinition.maxWidth,
  };
}

function defineCellRenderer(
  columnDefinition: ColumnDefinitionInternal,
  columnDef: ColDef,
) {
  if (columnDefinition.cellRenderer === 'checkboxCellRenderer') {
    if (Array.isArray(columnDef.headerClass)) {
      columnDef.headerClass.push('checkbox-header');
    } else if (typeof columnDefinition.headerClass === 'string') {
      columnDef.headerClass = ['checkbox-header', columnDefinition.headerClass];
    } else {
      columnDef.headerClass = 'checkbox-header';
    }
    columnDef.cellRenderer = CheckboxRendererComponent;
    columnDef.cellStyle = { ...columnDef.cellStyle, textAlign: 'center' };
  } else if (columnDefinition.cellRenderer === 'buttonCellRenderer') {
    const buttonFieldName = 'buttonColumn';
    columnDef.field = buttonFieldName;
    columnDefinition.modelPropertyName = buttonFieldName;
    Object.assign(columnDef, createDefaultButtonColumn());
  } else if (columnDefinition.cellRenderer === 'fileDownloadCellRenderer') {
    columnDef.cellRenderer = FileDownloadCellRendererComponent;
  } else if (
    typeof columnDefinition.cellRenderer !== 'string' &&
    columnDefinition.cellRenderer
  ) {
    columnDef.cellRenderer = columnDefinition.cellRenderer;
  } else {
    columnDef.cellRenderer = ListInputRendererComponent;
  }
  columnDef.cellRendererParams = {
    ...columnDefinition.cellRendererOptions,
    displayText: columnDefinition.displayText,
    displayFormat: columnDefinition.displayFormat,
    modelMapping:
      columnDefinition.editorOptions &&
      'modelMapping' in columnDefinition.editorOptions
        ? columnDefinition.editorOptions.modelMapping
        : undefined,
    refreshCellRenderer$: columnDefinition.refreshCellRenderer$,
    fieldId:
      columnDefinition.fieldId ?? columnDefinition.cellRendererOptions?.fieldId,
  };
}

export function createDefaultButtonColumn(): ColDef {
  return {
    cellRenderer: ListButtonRendererComponent,
    headerName: '',
    editable: false,
    cellClass: 'cell-button-style',
    suppressNavigable: true,
    maxWidth: 30,
  };
}

export function getColumnHideFromColumnDefinitionAndSecurity(
  columnDefinition: ColumnDefinition,
  colDefs: ColDef[],
) {
  const fieldName = getFieldName(columnDefinition.modelPropertyName);
  const colDef = colDefs.find((c) => c.field === fieldName);
  return (
    getColumnHideFromColumnDefinition(columnDefinition) ||
    colDef?.['hiddenBySecurity'] === true
  );
}

export function getColumnHideFromColumnDefinition(
  columnDefinition: ColumnDefinition,
) {
  return columnDefinition.visibility !== true;
}

export function getColumnDefinitionVisibilityFromHide(
  columnState: ColumnState,
  columnDefinition: ColumnDefinition,
) {
  if (!columnState.hide) {
    return true;
  }
  return columnDefinition.visibility === false ? false : 'onlyInColumnChooser';
}

export function convertSortOrder(sortOrder?: SortOrder) {
  return (sortOrder ? sortOrder.toString() : null) as 'asc' | 'desc' | null;
}
