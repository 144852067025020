import { Injectable, Signal, computed, signal, untracked } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  BaseModel,
  ValidationCategory,
  ValidationResult,
  ValidationStatus,
} from '@salary/common/dumb';
import {
  FieldConfig,
  convertToSignal,
  getErrorMessage,
  getFormState,
  getFullKey,
  injectFormlyConfig,
} from '@salary/common/formly';
import { wrapFunction } from '@salary/common/utils';
import { DateTime } from 'luxon';
import {
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  takeUntil,
} from 'rxjs';
import {
  DetailBaseContainerComponent,
  DetailSingleContainerComponent,
} from '../../detail-container';
import { isGroupField } from '../types';

@Injectable({ providedIn: 'root' })
export class SalaryValidationExtension {
  private formlyConfig = injectFormlyConfig();
  private errorMap = signal(new Map<string, ClientValidationResult>(), {
    equal: () => false,
  });

  registerClientValdationErrors(field: FieldConfig) {
    if (isGroupField(field)) {
      return;
    }

    const destroyRef$ = new Subject<void>();

    const onDestroy = (f: FieldConfig) => {
      destroyRef$.next();
      this.removeError(f);
    };
    const onAfterViewInit = (f: FieldConfig) => {
      if (f.formControl == null) {
        return;
      }
      const hideSignal = convertToSignal('hide', f);
      combineLatest([
        toObservable(hideSignal, {
          injector: f._injector,
        }),
        f.formControl.statusChanges.pipe(
          filter(() => !hideSignal()),
          startWith(f.formControl.status),
          map((status) => status === 'INVALID'),
        ),
      ])
        .pipe(distinctUntilChanged(), debounceTime(300), takeUntil(destroyRef$))
        .subscribe(([, invalid]) => {
          if (
            invalid &&
            !(f.hide ? convertToSignal('hide', f) : signal(false))()
          ) {
            this.addError(f);
          } else {
            this.removeError(f);
          }
        });
    };

    if (field.hooks.afterViewInit) {
      field.hooks.afterViewInit = wrapFunction(
        field.hooks.afterViewInit,
        onAfterViewInit,
      );
    } else {
      field.hooks.afterViewInit = onAfterViewInit;
    }
    if (field.hooks.onDestroy) {
      field.hooks.onDestroy = wrapFunction(field.hooks.onDestroy, onDestroy);
    } else {
      field.hooks.onDestroy = onDestroy;
    }
  }

  private addError(field: FieldConfig) {
    const component = getFormState(field)
      ?.component as DetailBaseContainerComponent<BaseModel>;
    if (!component) {
      return;
    }
    const errorMessage = getErrorMessage(field, this.formlyConfig);
    if (!errorMessage) {
      return;
    }
    const rootComponent =
      component.getRootComponent() as DetailSingleContainerComponent;
    if (
      Array.from(this.errorMap().values()).some(
        (value) => value.rootComponent !== rootComponent,
      )
    ) {
      this.errorMap.update((m) => {
        m.clear();
        return m;
      });
    }
    this.sendError(errorMessage, field, rootComponent, component);
  }

  private sendError(
    errorMessage: string,
    field: FieldConfig,
    rootComponent: DetailSingleContainerComponent,
    component: DetailBaseContainerComponent,
  ) {
    const key = this.getIdentificationKey(field);
    const oldValue = this.errorMap().get(key);
    if (oldValue && oldValue.errorMessage === errorMessage) {
      return;
    }
    this.errorMap.set(
      this.errorMap().set(key, {
        errorMessage: errorMessage,
        errorMessageDisplay: this.getErrorMessageToDisplay(errorMessage, field),
        additionalMessage: 'Korrigieren Sie die Eingabe um speichern zu können',
        status: ValidationStatus.Failed,
        propertyNames: [getFullKey(field)],
        rootComponent: rootComponent,
        component: component,
        category: ValidationCategory.Error,
        loadedTime: DateTime.now(),
        domainObjectId: field.model()?.id,
        fieldConfig: field,
      }),
    );
  }

  private getErrorMessageToDisplay(errorMessage: string, field: FieldConfig) {
    if (errorMessage === 'Eingabe erforderlich') {
      return `Für das Feld '${getFullKey(field)}' ist eine Eingabe erforderlich`;
    }
    return undefined;
  }

  private removeError(field: FieldConfig) {
    const component = getFormState(field)
      ?.component as DetailBaseContainerComponent<BaseModel>;
    if (!component) {
      return;
    }

    const key = this.getIdentificationKey(field);
    this.validationMarkerDeletion(field, key);
    const removed = this.errorMap().delete(key);
    if (removed) {
      this.errorMap.set(this.errorMap());
    }
  }

  private validationMarkerDeletion(field: FieldConfig, key: string) {
    const errorMessageToRemove = this.errorMap().get(key)?.errorMessage;
    if (errorMessageToRemove == null) {
      return;
    }
    if (field._validationMarkerText()?.includes(errorMessageToRemove)) {
      field._validationMarkerText.set(undefined);
    }
  }

  public getValidationErrorsOfComponent(
    rootComponent: DetailSingleContainerComponent,
  ): Signal<ValidationResult[]> {
    return computed(
      () => {
        const map = this.errorMap();
        return untracked(() =>
          Array.from(map.values()).filter(
            (v) => v.rootComponent === rootComponent,
          ),
        );
      },
      {
        equal: (
          prev: ClientValidationResult[],
          curr: ClientValidationResult[],
        ) => {
          const prevWithoutComponent = prev.map(
            ({ rootComponent: _, component: __, fieldConfig: ___, ...rest }) =>
              rest,
          );
          const currWithoutComponent = curr.map(
            ({ rootComponent: _, component: __, fieldConfig: ___, ...rest }) =>
              rest,
          );
          return (
            JSON.stringify(prevWithoutComponent) ===
            JSON.stringify(currWithoutComponent)
          );
        },
      },
    );
  }

  private getIdentificationKey(field: FieldConfig) {
    let key = getFullKey(field);
    key += field.id;
    return key;
  }
}

export class ClientValidationResult extends ValidationResult {
  component: DetailBaseContainerComponent;
  rootComponent: DetailSingleContainerComponent;
  fieldConfig: FieldConfig;
}
