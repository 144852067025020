import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { AppSettingsFacade } from '@salary/common/facade';
import { filterNil } from '@salary/common/utils';
import { Subject, switchMap } from 'rxjs';
import { AppSettingsDialogComponent } from './app-settings.dialog';

@Injectable({ providedIn: 'root' })
export class AppSettingsDialogService {
  private openDialog$ = new Subject<void>();
  constructor(settingsFacade: AppSettingsFacade, dialog: MatDialog) {
    this.openDialog$
      .pipe(
        switchMap(() =>
          dialog
            .open(AppSettingsDialogComponent, {
              autoFocus: false,
              data: { ...settingsFacade.select.all() },
              width: '800px',
            })
            .afterClosed(),
        ),
        filterNil(),
        takeUntilDestroyed(),
      )
      .subscribe((settingsToSave) => settingsFacade.update(settingsToSave));
  }

  show() {
    this.openDialog$.next();
  }
}
