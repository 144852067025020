import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { SALARY_IS_STABLE } from '@salary/common/utils';
import { filter, interval, switchMap } from 'rxjs';

export interface NotificationSnackBarData {
  message: string;
  duration: number;
  additionalMessage?: string;
  icon?: string;
  closeButton?: boolean;
  action?: string;
  classname?: string | string[];
  expanded?:boolean;
}

@Component({
  selector: 'salary-notification-snack-bar',
  templateUrl: './notification-snack-bar.component.html',
  styleUrl: './notification-snack-bar.component.scss',
  host: {
    '[attr.data-testid]': '"snack-bar-container"',
    '(mouseenter)': 'hovered.set(true)',
    '(mouseleave)': 'hovered.set(false)',
  },
  animations: [
    trigger('bodyExpansion', [
      state('0', style({ height: 0, opacity: 0 })),
      state('1', style({ height: '*', opacity: '*' })),
      transition('0 <=> 1', animate('225ms cubic-bezier(0.4,0.0,0.2,1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationSnackBarComponent {
  protected data = inject<NotificationSnackBarData>(MAT_SNACK_BAR_DATA);
  protected expanded = signal(this.data.expanded ?? false);
  protected hovered = signal(false); 
  protected snackBarRef = inject(MatSnackBarRef<NotificationSnackBarComponent>);
  private readonly duration =
    this.data.duration === 0 ? 3000 : this.data.duration;
  private readonly timeUntilClose = signal(this.duration);
  protected readonly progress = computed(
    () => (this.timeUntilClose() / this.duration) * 100,
  );
  constructor() {
    inject(SALARY_IS_STABLE)
      .pipe(
        switchMap(() => interval(10).pipe(filter(() => !this.hovered()))),
        takeUntilDestroyed(),
      )
      .subscribe(() =>
        this.timeUntilClose.update((time) => Math.max(time - 10, 0)),
      );
    const effectRef = effect(() => {
      if (this.timeUntilClose() <= 0) {
        effectRef.destroy();
        untracked(() => this.snackBarRef.dismiss());
      }
    });
  }
}
