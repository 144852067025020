import { Injectable } from '@angular/core';
import {
  BaseHttpQueryService,
  decodeJsonString,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { BasePageModel, Setting } from '@salary/common/dumb';
import { map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SettingsQueryService extends BaseHttpQueryService<Setting> {
  constructor(configurationService: EnvironmentConfigService) {
    super({
      url: configurationService.apiAdministrationUrl,
      endpointFormat: 'settings',
    });
  }

  getAllSettings(): Observable<BasePageModel<Setting>> {
    return this.getPerPage({
      queryParameters: {
        pageSize: 1000,
        orderBy: 'key',
        direction: 'asc',
      },
    }).pipe(
      map((pageModel) => {
        pageModel.results = pageModel.results.map((setting) => {
          setting.value = decodeJsonString(setting.value);
          return setting;
        });
        return pageModel;
      }),
    );
  }
}
