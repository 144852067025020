import { Type } from '@angular/core';
import { getFieldName } from '@salary/common/dumb';
import {
  CaptionHelper,
  createDateFieldDisplayformat,
  createNumberFieldDisplayformat,
  createNumberMaskConfig,
  getDateFieldFormat,
  getEnumFieldOptions,
  getFieldId,
  getNestedPropertyValue,
  getNumberFieldOptions,
  getTextFieldOptions,
  isBooleanField,
  OptionsHelper,
} from '@salary/common/utils';
import { SalaryLink } from '../../utils';
import { ColumnDefinition } from './column-definition';
import { DateEditorColumnOptions } from './date-editor-column-options';
import { EnumEditorColumnOptions } from './enum-editor-column-options';
import { InputEditorColumnOptions } from './input-editor-column-options';
import { SearchEditorColumnOptions } from './search-editor-column-options';

export class ColumnDefinitionDecoratorHandler<T> {
  constructor(private modelClass: Type<T>) {}
  process(columns: ColumnDefinition<T>[]): ColumnDefinition<T>[] {
    columns.forEach((column) => {
      if (column.modelPropertyName) {
        this.applyCaption(column);
        this.applyFieldTypes(column);
        this.applyFieldId(column);
      }
      this.applyColumnRootCaption(column);
    });
    return columns;
  }

  private applyCaption(column: ColumnDefinition<T>) {
    if (!column.columnTitle) {
      column.columnTitle = CaptionHelper.getCaption(
        this.modelClass,
        getFieldName(column.modelPropertyName),
      );
    }
  }

  private applyFieldTypes(column: ColumnDefinition<T>) {
    if (this.applyTextFieldType(column)) {
      return;
    }
    if (this.applyNumberFieldType(column)) {
      return;
    }
    if (this.applyDateFieldType(column)) {
      return;
    }
    if (this.applyEnumFieldType(column)) {
      return;
    }
    if (this.applyBooleanFieldType(column)) {
      return;
    }
  }

  private applyTextFieldType(column: ColumnDefinition<T>) {
    if (column.displayFormat) return false;
    const fieldName = getFieldName(column.modelPropertyName);
    const options = getTextFieldOptions(this.modelClass, fieldName);
    if (!options?.format) return false;
    let displayFormat = options.format;
    let routerLink = options.routerLink;
    if (
      column.cellEditor === 'searchInputEditor' &&
      (column.editorOptions as SearchEditorColumnOptions<T>).modelMapping
    ) {
      Array.from<string[]>(
        (column.editorOptions as SearchEditorColumnOptions).modelMapping,
      ).forEach((mapping) => {
        displayFormat = displayFormat.replace(mapping[1], mapping[0]);
        routerLink = routerLink?.replace(mapping[1], mapping[0]);
      });
    }
    displayFormat = this.convertRelativePathsToAbsolutePaths(
      fieldName,
      displayFormat,
    );
    routerLink = this.convertRelativePathsToAbsolutePaths(
      fieldName,
      routerLink,
    );
    if (routerLink && displayFormat && !column.identificationProperty) {
      const speparator = ' - ';
      let endIndexOfLinkPart = displayFormat.indexOf(speparator);
      if (endIndexOfLinkPart === -1) {
        endIndexOfLinkPart = displayFormat.length;
      }
      displayFormat = SalaryLink.createLink({
        link: routerLink,
        linkText: displayFormat.slice(0, endIndexOfLinkPart),
        textAfterLink: displayFormat.slice(endIndexOfLinkPart),
      });
    }
    column.displayFormat = displayFormat;
    column.columnType = 'text';
    return true;
  }

  private convertRelativePathsToAbsolutePaths(
    fieldName: string,
    stringsWithPath: string,
  ) {
    const indexOfLastDot = fieldName.lastIndexOf('.');
    if (indexOfLastDot == -1) return stringsWithPath;
    const currentPropertyPathPrefix = fieldName.substring(0, indexOfLastDot);
    return stringsWithPath?.replace(/{/g, `{${currentPropertyPathPrefix}.`);
  }

  private applyNumberFieldType(column: ColumnDefinition<T>) {
    if (column.displayFormat) return false;
    const options = getNumberFieldOptions(
      this.modelClass,
      getFieldName(column.modelPropertyName),
    );
    if (!options) return false;
    column.displayFormat = createNumberFieldDisplayformat(
      getFieldName(column.modelPropertyName),
      options,
    );
    if (!column.identificationProperty) {
      column.alignRight = true;
    }
    if (options.suffix) {
      column.columnTitle += ` (${options.suffix})`;
    }
    const editorOptions = column.editorOptions as InputEditorColumnOptions;
    if (column.editable) {
      column.cellEditor = 'inputEditor';
      if (!editorOptions?.maskConfig) {
        column.editorOptions = {
          ...column.editorOptions,
          maskConfig: createNumberMaskConfig(options),
        };
      }
      if (editorOptions?.unmask == null) {
        column.editorOptions = { ...column.editorOptions, unmask: 'typed' };
      }
    }
    return true;
  }

  private applyDateFieldType(column: ColumnDefinition<T>) {
    if (column.displayFormat) return false;
    const dateFormat = getDateFieldFormat(
      this.modelClass,
      getFieldName(column.modelPropertyName),
    );
    if (!dateFormat) return false;
    column.displayFormat = createDateFieldDisplayformat(
      getFieldName(column.modelPropertyName),
      dateFormat,
    );
    const enumEditorOptions = column.editorOptions as DateEditorColumnOptions;
    if (column.editable && !enumEditorOptions?.dateFormat) {
      if (enumEditorOptions) {
        enumEditorOptions.dateFormat = dateFormat;
      } else {
        column.editorOptions = { dateFormat };
      }
    }
    column.columnType = 'date';
    return true;
  }

  private applyEnumFieldType(column: ColumnDefinition<T>) {
    if (column.displayText) return false;
    const enumOptions = getEnumFieldOptions(
      this.modelClass,
      getFieldName(column.modelPropertyName),
    );
    if (!enumOptions) return false;
    column.displayText = (model: unknown) => {
      const enumValue = getNestedPropertyValue(
        model,
        getFieldName(column.modelPropertyName),
      );
      const text = OptionsHelper.getText(enumOptions.enumType, enumValue);
      const result = text || enumValue;
      if (result) {
        column.columnType = 'enum';
      }
      return result;
    };
    const enumEditorOptions = column.editorOptions as EnumEditorColumnOptions;
    if (column.editable && !enumEditorOptions?.options) {
      const options = OptionsHelper.getOptions(enumOptions.enumType);
      if (enumEditorOptions) {
        enumEditorOptions.options = options;
      } else {
        column.editorOptions = { options };
      }
    }
    column.columnType = 'enum';
    return true;
  }

  private applyBooleanFieldType(column: ColumnDefinition<T>) {
    if (column.cellRenderer) return false;
    if (
      isBooleanField(this.modelClass, getFieldName(column.modelPropertyName))
    ) {
      column.cellRenderer = 'checkboxCellRenderer';
      column.columnType = 'boolean';
      return true;
    }
    return false;
  }

  private applyColumnRootCaption(column: ColumnDefinition<T>) {
    let rootTitle = column.columnGroupName;
    if (!rootTitle && column.modelPropertyName) {
      rootTitle = this.getRootPropertyTitle(
        this.modelClass,
        getFieldName(column.modelPropertyName),
      );
    }
    if (rootTitle) {
      column.columnTitle += ` (${rootTitle})`;
    }
  }

  private getRootPropertyTitle(modelClass: Type<unknown>, fieldName: string) {
    if (fieldName.includes('.')) {
      const firstPath = fieldName.substring(0, fieldName.indexOf('.'));
      return CaptionHelper.getCaption(modelClass, firstPath);
    }
    return undefined;
  }

  applyFieldId(column: ColumnDefinition<T>) {
    if (column.modelPropertyName) {
      const fieldId = getFieldId(
        this.modelClass,
        getFieldName(column.modelPropertyName),
      );
      if (fieldId) {
        const cellRendererOptions = column.cellRendererOptions ?? {};
        cellRendererOptions.fieldId = fieldId;
        column.cellRendererOptions = cellRendererOptions;
      }
    }
  }
}
