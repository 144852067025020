import { GridApi, ProcessCellForExportParams } from '@ag-grid-community/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import {
  DestroyRef,
  Injectable,
  Injector,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import {
  outputToObservable,
  takeUntilDestroyed,
} from '@angular/core/rxjs-interop';
import {
  EndpointConfigurationQuery,
  SalaryError,
} from '@salary/common/api/base-http-service';
import { NOTIFICATION_SERVICE_TOKEN, filterNil } from '@salary/common/utils';
import { DateTime } from 'luxon';
import { Observable, combineLatest, map, of, switchMap, take } from 'rxjs';
import { ListContainerComponent } from '../list-container';
import { ListComponent } from '../list/list.component';
import { BaseCellRenderer, ClientModeLoader } from '../list/utils';
import { ListExportParams } from './list-export-params';
import { PDFPrintService } from './pdf-print.service';

@Injectable()
export class ListOutputService {
  private pdfPrintService = inject(PDFPrintService);
  private notificationService = inject(NOTIFICATION_SERVICE_TOKEN);
  private overlay = inject(Overlay);
  private destroyRef = inject(DestroyRef);
  private injector = inject(Injector);

  public export(exportParams: ListExportParams) {
    const overlayRef: OverlayRef = this.cdkOverlayCreate();
    const listContainerComponent = this.createListContainerComponent(
      exportParams.componentToExport,
      overlayRef,
    );

    this.prepareListComponent(
      listContainerComponent,
      exportParams.componentToExport.lastRequestPayload.endpointConfiguration,
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([, rowData]) => {
        listContainerComponent.salaryList().rowData.set(rowData);
        setTimeout(() => {
          this.exportCSV(
            exportParams,
            listContainerComponent.salaryList().gridApi,
          );
          overlayRef.detach();
          overlayRef.dispose();
        });
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public print(componentToPrint: ListContainerComponent<any>) {
    const overlayRef: OverlayRef = this.cdkOverlayCreate();
    const listContainerComponent = this.createListContainerComponent(
      componentToPrint,
      overlayRef,
    );

    this.prepareListComponent(
      listContainerComponent,
      componentToPrint.lastRequestPayload.endpointConfiguration,
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([list, entities]) => {
        listContainerComponent.salaryList().rowData.set(entities);
        setTimeout(() => {
          this.pdfPrintService.printPDF(
            list.gridApi,
            componentToPrint.listTitle(),
          );
          overlayRef.detach();
          overlayRef.dispose();
        });
      });
  }

  private cdkOverlayCreate() {
    return this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .global()
        .left('-9999px')
        .top('-9999px'),
    });
  }

  private createListContainerComponent(
    componentToPrint: ListContainerComponent<unknown>,
    overlayRef: OverlayRef,
  ) {
    const portal = new ComponentPortal<ListContainerComponent<unknown>>(
      componentToPrint.constructor as ComponentType<
        ListContainerComponent<unknown>
      >,
    );
    const componentRef = overlayRef.attach(portal);
    const listContainerComponent = componentRef.instance;
    listContainerComponent.rowModelType.set('clientSide');
    return listContainerComponent;
  }

  private prepareListComponent(
    listContainerComponent: ListContainerComponent<unknown>,
    endpointConfiguration: EndpointConfigurationQuery,
  ): Observable<[ListComponent<unknown>, unknown[]]> {
    const error = signal<SalaryError>(undefined);

    effect(
      () => {
        const err = error();
        untracked(() => {
          if (err != null) {
            this.notificationService.showError(
              'Aktion nicht möglich.',
              listContainerComponent.facade.notificationSource,
              err.message,
            );
          }
        });
      },
      { injector: this.injector },
    );
    return combineLatest([
      of(listContainerComponent.salaryList()).pipe(
        filterNil(),
        switchMap((list) =>
          outputToObservable(list.gridReady).pipe(map(() => list)),
        ),
        take(1),
      ),
      new ClientModeLoader(
        listContainerComponent.facade,
        this.notificationService,
        error,
      ).queryPages(endpointConfiguration),
    ]).pipe(take(1), takeUntilDestroyed(this.destroyRef));
  }

  private exportCSV(params: ListExportParams, gridapi: GridApi) {
    if (params.format === 'CSV') {
      gridapi.exportDataAsCsv({
        fileName: params.fileName,
        processCellCallback: this.processCell,
        columnSeparator: ';',
      });
    }
  }

  private processCell(cell: ProcessCellForExportParams): string {
    if (
      cell.value &&
      (DateTime.isDateTime(cell.value) || typeof cell.value === 'number')
    ) {
      const cellRenderer = cell.api.getCellRendererInstances({
        columns: [cell.column],
        rowNodes: [cell.node],
      })?.[0] as BaseCellRenderer;
      return cellRenderer?.params?.eGridCell?.textContent?.trim();
    }
    if (typeof cell.value === 'string') {
      return cell.value.replace(/^[=+-@\t\r]/, '');
    }
    return cell.value;
  }
}
