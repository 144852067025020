import { Signal, TemplateRef } from '@angular/core';
import { EndpointConfigurationQuery } from '@salary/common/api/base-http-service';
import { NameFunction, StringWithSuggestions } from '@salary/common/dumb';
import { ToolbarDefinition } from '../../utils';
import { ColumnDefinition } from '../column';
export interface ListConfiguration<T = unknown> {
  toolbarDefinitions?: ToolbarDefinition[];
  columnDefinitions: (ColumnDefinition<T> | NameFunction<T>)[];
  /** filter list by lizenznehmerId queryparameter */
  defaultFilter?: 'Lizenznehmer';
  listTitle?: string | TitleOption | Signal<string>;
  onboardingTextKeys?: OnBoardingTextConfiguration;
  /** can be used to map dsm routes to a known lohnkontext property ('standard' | 'lizenznehmer' | 'abrechnungskreis' | 'mandant')
   * i.e. if you have 'mandant' route/tab, which should behave like 'abrechnungskreis' route/tab (filtering)
   */
  dsmModeRouting?: { routeName: string; lohnkontextPropertyName: string }[];
  /** if set modifyComponent on dsm-components will be called if abrechnungsmonat was changed.
   * Choose whether every change in salary context or only the change in year leads to a call.
   */
  modifyDSMComponent?: 'monthYear' | 'year';
  /** after rendering. default is false */
  selectFirstRow?: boolean | Signal<boolean>;
  /** default is multiple */
  rowSelection?: 'multiple' | 'single';
  /** default is false */
  checkboxSelection?: boolean;
  disableEmptyState?: boolean;
  /** dragging columns (remove + order) */
  disableColumnMoving?: boolean;
  disableColumnSorting?: boolean;
  disableTabToNextCell?: boolean;
  emptyStateTemplate?: Signal<TemplateRef<unknown>>;
  /** if not set no validation column is available. Switch boolean value to hide / make visible validationColumn*/
  validationSupport?: Signal<boolean>;
  /** default: true. if false row is not selectable by user. */
  selectableRowCallback?: (rowdata: T) => boolean;
  /** buttons that are displayed within list empty state */
  emptyStateToolbarDefinition?: ToolbarDefinition;
  /** given method will be executed instead of default double click behavior */
  rowDoubleClickHandler?: () => void;
  /** text that should be shown in empty-state-component */
  notFoundText?: Signal<string>;
  /** set to true if newItemRow should be enabled. default is false */
  newItemRow?: boolean;
  /** set to false to disable saving/applying perspectives */
  perspectiveSupport?: boolean;
  tree?: {
    /** define to display expand button in identificationProperty Column of rows where callback returns true*/
    isExpandable: (data: T) => boolean;
    /** used to query children of expanded nodes; props are merged into root endpointConfiguration */
    childrenEndpointConfiguration: (parent: T) => EndpointConfigurationQuery;
  };
  /**define your endpointConfiguration. List will be refreshed after changes*/
  endpointConfiguration?: Signal<EndpointConfigurationQuery>;
}

export enum TitleOption {
  Lizenznehmer,
  Year,
  MonthYear,
}

export type OnBoardingTextConfiguration =
  | string
  | {
      routeSuffix: StringWithSuggestions<
        'standard' | 'lizenznehmer' | 'abrechnungskreis'
      >;
      key: string;
    }[];
