import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HTTP_INTERCEPTORS, HttpInterceptor } from '@angular/common/http';
import {
  DestroyRef,
  Inject,
  Injectable,
  Injector,
  Type,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PendingRequestsInterceptor } from '@salary/common/api/base-http-service';
import { ConfigService, SettingsFacade } from '@salary/common/facade';
import {
  INotificationService,
  NOTIFICATION_SERVICE_TOKEN,
} from '@salary/common/utils';
import {
  catchError,
  forkJoin,
  mergeMap,
  of,
  retry,
  take,
  throwError,
  timer,
} from 'rxjs';
import { DetailSingleBaseComponent } from '../utils';
import {
  DetailOutputDialogComponent,
  NavigationConfigSelection,
} from './detail-output-dialog.component';
import { DetailOutputComponent } from './detail-output.component';

@Injectable({ providedIn: 'root' })
export class DetailOutputService {
  static PRINT_DETAIL_SETTINGS_KEY = 'printDetailsSettings';
  private destroyRef = inject(DestroyRef);
  private injector: Injector;
  private componentType: Type<unknown>;
  private componentKey: string;
  private pendingRequestsInterceptor: PendingRequestsInterceptor;
  constructor(
    private overlay: Overlay,
    private dialog: MatDialog,
    private settingsFacade: SettingsFacade,
    @Inject(NOTIFICATION_SERVICE_TOKEN)
    private notificationService: INotificationService,
    @Inject(HTTP_INTERCEPTORS)
    interceptors: HttpInterceptor[],
  ) {
    this.pendingRequestsInterceptor = interceptors.find(
      (interceptor) => interceptor instanceof PendingRequestsInterceptor,
    ) as PendingRequestsInterceptor;
  }
  private cdkOverlayCreate() {
    return this.overlay.create({
      positionStrategy: this.overlay.position().global().top().left(),
    });
  }
  public execute(component: DetailSingleBaseComponent, injector: Injector) {
    this.injector = injector;
    this.componentType = component.constructor as Type<unknown>;
    this.componentKey = component.facade?.singularModelCaption;
    this.printDefaultComponent(component);
  }

  public executeTransactionPrint(
    componentType: Type<unknown>,
    componentKey: string,
    injector: Injector,
    selectedObjects: unknown[],
    navigationCaptions: string[],
  ) {
    this.injector = injector;
    this.componentType = componentType;
    this.componentKey = componentKey;
    this.printTransactionComponent(selectedObjects, navigationCaptions);
  }

  private printTransactionComponent(
    selectedObjects: unknown[],
    navigationCaptions?: string[],
  ) {
    if (navigationCaptions) {
      const navigationSelection: NavigationConfigSelection[] =
        navigationCaptions.map((caption) => ({
          caption: caption,
          selected: true,
        }));
      this.openDialogAndPrint(navigationSelection, selectedObjects);
    } else {
      this.printWithInjector(undefined, selectedObjects);
    }
  }

  private printDefaultComponent(component: DetailSingleBaseComponent) {
    const links = component.componentConfiguration.subnavigationLinks;
    if (links == null) {
      this.printWithInjector([]);
      return;
    }
    const navigationSelection: NavigationConfigSelection[] = links.map(
      (configSelection) => ({
        caption: configSelection.caption,
        selected: true,
      }),
    );
    this.openDialogAndPrint(navigationSelection);
  }

  private openDialogAndPrint(
    navigationSelection: NavigationConfigSelection[],
    selectedObjects?: unknown[],
  ) {
    const customSettingsKey = this.getSettingsKey();
    const settings: NavigationConfigSelection[] =
      this.settingsFacade.selectBenutzerSettingByKey<
        NavigationConfigSelection[]
      >(customSettingsKey)()?.value;

    const dialogRef: MatDialogRef<
      DetailOutputDialogComponent,
      NavigationConfigSelection[]
    > = this.dialog.open(DetailOutputDialogComponent, {
      data:
        settings != null
          ? settings.concat(
              navigationSelection.filter(
                (x) =>
                  !settings.find((setting) => setting.caption === x.caption),
              ),
            )
          : navigationSelection,
      autoFocus: true,
    });
    dialogRef
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((r) => {
        if (r == null) {
          return;
        }
        const selectedSublinks = r.filter((selection) => selection.selected);
        const subLinksToPrint = selectedSublinks.map((item) => item.caption);
        this.updatePrintSettings(r);
        this.printWithInjector(subLinksToPrint, selectedObjects);
      });
  }

  private updatePrintSettings(
    navigationConfigSelection: NavigationConfigSelection[],
  ) {
    const customSettingsKey = this.getSettingsKey();

    this.settingsFacade.createOrUpdateUserSetting({
      key: customSettingsKey,
      value: navigationConfigSelection,
    });
  }

  private printWithInjector(
    subnavigationLinksToPrint?: string[],
    selectedObjects?: unknown[],
  ) {
    const printComponentInjector = Injector.create({
      providers: [
        {
          provide: ConfigService,
          useFactory: () => {
            const cs = new ConfigService();
            cs.printing.sublinksToPrint = subnavigationLinksToPrint;
            cs.printing.selectedTransactionData = selectedObjects;
            cs.printing.printMode = true;
            return cs;
          },
        },
      ],
      parent: this.injector,
    });
    this.printCore(printComponentInjector);
  }

  private printCore(printComponentInjector: Injector) {
    const overlayRef = this.cdkOverlayCreate();

    const portal = new ComponentPortal(
      DetailOutputComponent,
      null,
      printComponentInjector,
    );
    const componentRef = overlayRef.attach(portal);
    componentRef.instance.componentType = this.componentType;
    this.pendingRequestsInterceptor.resetErrors();
    const pendingRequestsDone$ = timer(1000).pipe(
      mergeMap((val) => {
        if (
          this.pendingRequestsInterceptor.pendingRequests() > 0 ||
          this.pendingRequestsInterceptor.errors() > 0
        ) {
          return throwError(
            () => new Error('There are pending or failed requests.'),
          );
        }
        return of(val).pipe(take(1));
      }),
      retry(30),
      catchError((err) => {
        overlayRef.detach();
        overlayRef.dispose();
        this.notificationService.showError(
          'Leider kann gerade nicht gedruckt werden. Bitte aktualisieren Sie die Seite in Ihrem Browser und versuchen es erneut.',
          { name: 'Drucken', iconName: 'print' },
        );
        return throwError(() => err);
      }),
    );

    forkJoin([
      componentRef.instance.readyToPrint$.pipe(take(1)),
      pendingRequestsDone$.pipe(take(1)),
    ])
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.doPrint(overlayRef);
      });
  }

  getSettingsKey() {
    return (
      DetailOutputService.PRINT_DETAIL_SETTINGS_KEY + '_' + this.componentKey
    );
  }

  doPrint(overlayRef: OverlayRef) {
    print();
    overlayRef.detach();
    overlayRef.dispose();
  }
}
