export class FormContainerTemplate {
  public static readonly FORM_CONTAINER_TEMPLATE_TOP = `
  <div class="outermost-container">
    <salary-detail-header [title]="formTitle()" [subTitle]="subTitle()" [showProgressIndicator]="isProgressActive()"
      (validationButtonClicked)="validationService.toggleValidationPanel()" [toolbarDefinitions]="actionToolbarDefinitions"/>
    <mat-sidenav-container autosize style="height: 100%">
      <mat-sidenav-content  class="small-scrollbar" [style.overflow]="animationService.outletAnimationRunning() ? 'hidden' : 'auto'">
        <div salaryGap="16px" class="container" [style.display]="isLoadingProgressActive() ? 'none' : 'flex'">
        @if(requestErrorsToHandle() == null && !customEmptyStateTemplate()){
              <div class="left-side">
                <form class="left-side-form" [formGroup]="form" data-testid="detail_form">`;

  public static readonly FORM_CONTAINER_TEMPLATE_CONTROLS = `
  <salary-form [form]="form" [fields]="fields" [model]="model$ | async" [options]="options"/> 
  `;

  public static readonly FORM_CONTAINER_TEMPLATE_BOTTOM = `</form>   
                <div class="navigation-overflow sublinks small-scrollbar" data-testid="sub_navigation_list">
                  @if(hasSubnavigationLinks() && !printMode){
                    <salary-sub-navigation/>
                 }                 
                </div>
              </div>     
              <div class="navigation-overflow small-scrollbar navigation-content">
                 @if(hasSubnavigationLinks()){
                  <salary-sub-navigation-content/>
                 }
              </div>
      } @else{
        <ng-container *ngTemplateOutlet="emptyStatesTemplate"/>
      }
        </div>
      </mat-sidenav-content>
      @if(!printMode){
        <mat-sidenav mode="side" fixedInViewport="false" position="end" [opened]="validationService.validationPanelOpen" (openedChange)="validationService.validationPanelOpenedChange($event)">
        <router-outlet/>
       </mat-sidenav>
      }
    </mat-sidenav-container>
  </div>
  `;

  public static readonly FORM_CONTAINER_TEMPLATE_EMPTYSTATES = `  
  <ng-template #emptyStatesTemplate>    
    <div [class.empty-states-template]="emptyStateIconSize === 'large'" >
     @if(requestErrorsToHandle() === 'clientError'){
      <salary-item-not-found-empty-state [size]="emptyStateIconSize"/>
     }
     @if(requestErrorsToHandle() === 'serverError'){
      <salary-server-error-empty-state [size]="emptyStateIconSize"/>
     }
     @if(customEmptyStateTemplate()) {
      <ng-container [ngTemplateOutlet]="customEmptyStateTemplate()"/>
     }    
    </div>
  </ng-template>`;

  public static readonly FORM_CONTAINER_TEMPLATE_DEFAULT =
    FormContainerTemplate.FORM_CONTAINER_TEMPLATE_TOP +
    FormContainerTemplate.FORM_CONTAINER_TEMPLATE_CONTROLS +
    FormContainerTemplate.FORM_CONTAINER_TEMPLATE_BOTTOM +
    FormContainerTemplate.FORM_CONTAINER_TEMPLATE_EMPTYSTATES;

  public static readonly FORM_CONTAINER_TEMPLATE_SUBCOMPONENT_TOP = `
  @if(requestErrorsToHandle() == null){
    <form [formGroup]="form" data-testid="detail_form">
  `;
  public static readonly FORM_CONTAINER_TEMPLATE_SUBCOMPONENT_BOTTOM = `</form>} @else {
    <ng-container *ngTemplateOutlet="emptyStatesTemplate"/>
  }`;

  public static readonly FORM_CONTAINER_TEMPLATE_SUBCOMPONENT =
    FormContainerTemplate.FORM_CONTAINER_TEMPLATE_SUBCOMPONENT_TOP +
    FormContainerTemplate.FORM_CONTAINER_TEMPLATE_CONTROLS +
    FormContainerTemplate.FORM_CONTAINER_TEMPLATE_SUBCOMPONENT_BOTTOM +
    FormContainerTemplate.FORM_CONTAINER_TEMPLATE_EMPTYSTATES;

  public static readonly DEFAULTSTYLE = `
    @media only screen and (max-width: 599px ) {
      .navigation-overflow{
        overflow-y: unset !important;
      }
      .container{
        overflow-y: auto;
        flex-direction: column !important;
      }
      .sublinks{
        display:none;
      }
    }
    @media print{
      .container {
      height: 100% !important;
      overflow: visible !important;
      flex-direction: column !important;
      }      
    }    
    :host ::ng-deep mat-toolbar{
      display:block !important;      
    }

    .outermost-container{
      display:flex;
      flex-direction:column;
      height:100%;
    }

    .left-side{
      max-width:600px;
      display: flex;
      flex: 1 1 0;
      flex-direction: column;
    }  
    .navigation-overflow{
      overflow-y: auto;
    }
    .sublinks{
      padding-right: 8px;     
    }  
    .navigation-content{      
      flex: 2 2 0%;
    }
  
    .container {
      display: flex; 
      height: 100%;     
      padding-right:6px;
      flex-direction: row;
    }
    .left-side-form { 
      height: unset;
    }
    .left-side-form:not(:has(salary-identification-panel)){
      padding-top:12px;
    }
    .mat-divider.mat-divider-vertical{
      border-right-width: 2px;
    }    
    .subComponentCaption{
      font-size:18px;
      margin-bottom: 38px;
    }   
    .empty-states-template {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
    mat-sidenav
    {
      border-top: 1px solid var(--mat-sidenav-container-divider-color);
    }    
    `;
}
