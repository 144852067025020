import { HttpStatusCode } from '@angular/common/http';
import {
  DestroyRef,
  Injectable,
  Signal,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import {
  takeUntilDestroyed,
  toObservable,
  toSignal,
} from '@angular/core/rxjs-interop';
import { ActivationEnd, Router } from '@angular/router';
import { SalaryError } from '@salary/common/api/base-http-service';
import {
  LohnkontextAbrechnungskreisHinweiseQueryService,
  LohnkontextAbrechnungskreiseQueryService,
  LohnkontextLizenznehmerQueryService,
  LohnkontextMandantenQueryService,
} from '@salary/common/api/data-services';
import {
  AbrechnungskreisHinweiseDialogService,
  DialogService,
} from '@salary/common/dialog';
import {
  Abrechnungskreis,
  AbrechnungskreisHinweis,
  DateTimeFormats,
  Lizenznehmer,
  Mandant,
} from '@salary/common/dumb';
import {
  NOTIFICATION_SERVICE_TOKEN,
  SALARY_IS_STABLE,
  dateEquals,
  distinctUntilDateTimeChanged,
  filterNil,
  idEquals,
} from '@salary/common/utils';
import { DateTime } from 'luxon';
import {
  Observable,
  OperatorFunction,
  Subject,
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  of,
  pipe,
  switchMap,
  take,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { SettingsFacade } from '../settings';
import {
  ABRECHNUNGSZEITRAUM_STORAGE_FORMAT,
  LOHNKONEXT_STORAGE_KEY,
  LohnkontextPartial,
  LohnkontextSetting,
  getLizenznehmerFromMandant,
  getMandantFromAbrechnungskreis,
  isLizenznehmerAvailable,
  isMandantAndLizenznehmerAvailbale,
  isSameSetting,
  parseFacadedAbrechnungszeitraum,
  shouldClearLohnkontext,
  traverseSnapshotHierarchy,
} from './lohnkontext-facade.helper';
import { LohnkontextReadonly, LohnkontextState } from './lohnkontext.state';
import { Lohnkontext } from './models';
@Injectable({ providedIn: 'root' })
export class LohnkontextFacade {
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  private readonly mandantenQueryService = inject(
    LohnkontextMandantenQueryService,
  );
  private readonly abrechnungskreisQueryService = inject(
    LohnkontextAbrechnungskreiseQueryService,
  );
  private readonly lizenznehmerQueryService = inject(
    LohnkontextLizenznehmerQueryService,
  );
  private readonly abrechnungskreisHinweiseQueryService = inject(
    LohnkontextAbrechnungskreisHinweiseQueryService,
  );
  private readonly dialogService = inject(DialogService);
  private readonly notificationService = inject(NOTIFICATION_SERVICE_TOKEN);
  private readonly abrechnungskreisHinweiseDialogService = inject(
    AbrechnungskreisHinweiseDialogService,
  );
  private readonly isStable$ = inject(SALARY_IS_STABLE);
  private readonly settingsfacade = inject(SettingsFacade);
  private lohnkontextSettings =
    this.settingsfacade.selectBenutzerSettingByKey<LohnkontextSetting>(
      LOHNKONEXT_STORAGE_KEY,
    );
  private readonly isLoaded = signal(false);
  private readonly isLoaded$ = toObservable(this.isLoaded);
  private setLohnkontextSucceeded$ = new Subject<Lohnkontext>();
  public dateChangedByLogic$ = new Subject<void>();
  private lohnkontextState = signal<LohnkontextState>({
    abrechnungskreis: null,
    abrechnungszeitraum: DateTime.now().startOf('month'),
    lizenznehmer: null,
    mandant: null,
    failedToSetLohnkontext: false,
    readonly: LohnkontextReadonly.None,
  });
  private lohnkontextState$ = toObservable(this.lohnkontextState).pipe(
    filterNil(),
  );
  private selectedLohnkontext = computed(() => {
    if (this.isLoaded()) {
      return this.lohnkontextState();
    }
    return undefined;
  });

  //#region convenience selectors
  public readonly abrechnungszeitraum = computed(
    () => this.selectedLohnkontext()?.abrechnungszeitraum,
    { equal: dateEquals },
  );
  public readonly lizenznehmer = computed(
    () => this.selectedLohnkontext()?.lizenznehmer,
    { equal: idEquals },
  );
  public readonly abrechnungskreis = computed(
    () => this.selectedLohnkontext()?.abrechnungskreis,
    { equal: idEquals },
  ) as Signal<Abrechnungskreis>;
  //#endregion

  constructor() {
    this.setupHinweiseSubscription();
    this.setupLohnkontextSettingsSubscription();
    this.initializeLohnkontextFromSettings();
  }

  private setupHinweiseSubscription(): void {
    this.setLohnkontextSucceeded$
      .pipe(
        switchMap((lohnkontext: Lohnkontext) =>
          this.isStable$.pipe(map(() => lohnkontext)),
        ),
        map((lohnkontext) => lohnkontext.abrechnungskreis?.id),
        filterNil(),
        distinctUntilChanged(),
        switchMap((abrechnungskreisId) =>
          this.abrechnungskreisHinweiseQueryService
            .getPerPage({ id: abrechnungskreisId })
            .pipe(
              map((result) => result?.results),
              catchError(() => of(<AbrechnungskreisHinweis[]>[])),
            ),
        ),
        filter((hinweise) => hinweise?.length > 0),
        withLatestFrom(this.lohnkontextState$),
        switchMap(([hinweise, lohnkontext]) =>
          this.showHinweiseNotification(hinweise, lohnkontext),
        ),
        takeUntilDestroyed(),
      )
      .subscribe(({ hinweise, lohnkontext }) => {
        this.abrechnungskreisHinweiseDialogService.showDialog({
          hinweise,
          abrechnungskreis: lohnkontext.abrechnungskreis,
        });
      });
  }

  private showHinweiseNotification(
    hinweise: AbrechnungskreisHinweis[],
    lohnkontext: Lohnkontext,
  ): Observable<{
    hinweise: AbrechnungskreisHinweis[];
    lohnkontext: Lohnkontext;
  }> {
    return this.notificationService
      .show(
        `${hinweise.length} ${hinweise.length === 1 ? 'Hinweis' : 'Hinweise'}`,
        { duration: 10000, action: 'Anzeigen' },
      )
      .pipe(map(() => ({ hinweise, lohnkontext })));
  }

  private setupLohnkontextSettingsSubscription(): void {
    this.setLohnkontextSucceeded$.pipe(takeUntilDestroyed()).subscribe(() => {
      const newSetting: LohnkontextSetting = {
        abrechnungskreis: this.lohnkontextState().abrechnungskreis?.id,
        lizenznehmer:
          this.lohnkontextState().abrechnungskreis?.id == null
            ? this.lohnkontextState().lizenznehmer?.id
            : undefined,
        abrechnungszeitraum:
          this.lohnkontextState().abrechnungszeitraum.toFormat(
            ABRECHNUNGSZEITRAUM_STORAGE_FORMAT,
          ),
      };
      if (!isSameSetting(this.lohnkontextSettings()?.value, newSetting)) {
        this.settingsfacade.createOrUpdateUserSetting<LohnkontextSetting>({
          value: newSetting,
          key: LOHNKONEXT_STORAGE_KEY,
        });
      }
    });
  }

  private readonly lohnkontextReadonlyFromRouting = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof ActivationEnd),
      map((event) => event.snapshot),
      filter((snapshot) => !!snapshot.component),
      map((snapshot) => traverseSnapshotHierarchy(snapshot.root)),
    ),
  );

  public readonly select = {
    selectedLohnkontext: this.selectedLohnkontext,
    selectedLohnkontext$: toObservable(this.selectedLohnkontext).pipe(
      filterNil(),
    ),
    abrechnungszeitraum: this.abrechnungszeitraum,
    abrechnungszeitraum$: toObservable(this.abrechnungszeitraum).pipe(
      filterNil(),
    ),
    readonly: computed(
      () =>
        Math.max(
          this.lohnkontextState().readonly,
          this.lohnkontextReadonlyFromRouting() ?? LohnkontextReadonly.None,
        ) as LohnkontextReadonly,
    ),
    failedToSetLohnkontext: computed(
      () => this.lohnkontextState().failedToSetLohnkontext,
    ),
    zeitraumMessage: computed(() => this.lohnkontextState().zeitraumMessage),
    loaded: this.isLoaded,
  };

  public setAbrechnungskreis(abrechnungskreis: Abrechnungskreis) {
    this.setLohnkontext({ abrechnungskreis });
  }

  public setLizenznehmer(lizenznehmer: Lizenznehmer) {
    this.setLohnkontext({ lizenznehmer });
  }

  public setAbrechnungszeitraum(monat: DateTime) {
    this.setLohnkontext({ abrechnungszeitraum: monat });
  }

  public setAbrechnungszeitraumMessage(message: string) {
    this.lohnkontextState.set({
      ...this.lohnkontextState(),
      zeitraumMessage: message,
    });
  }

  private setLohnkontext(lohnkontext: LohnkontextPartial) {
    of(lohnkontext)
      .pipe(
        this.filterOutIfAlreadySet(),
        this.fillMissingLohnkontextProperties(),
        this.setAbrechnungszeitraumOperator(),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      )

      .subscribe((result) => {
        if (result) {
          if (shouldClearLohnkontext(result)) {
            this.lohnkontextState.set({
              ...this.lohnkontextState(),
              abrechnungskreis: null,
              mandant: null,
              lizenznehmer: null,
              failedToSetLohnkontext: false,
            });
            this.setLohnkontextSucceeded$.next(result);
            return;
          }
          const newState: LohnkontextState = {
            ...this.lohnkontextState(),
            failedToSetLohnkontext: false,
          };
          if (result.abrechnungszeitraum) {
            newState.abrechnungszeitraum = result.abrechnungszeitraum;
          }
          if (result.abrechnungskreis) {
            newState.abrechnungskreis = result.abrechnungskreis;
            newState.mandant = result.mandant;
            newState.lizenznehmer = result.lizenznehmer;
          } else if (result.lizenznehmer) {
            newState.lizenznehmer = result.lizenznehmer;
            newState.abrechnungskreis = undefined;
            newState.mandant = undefined;
          }
          this.lohnkontextState.set(newState);
          this.setLohnkontextSucceeded$.next(result);
        } else {
          this.lohnkontextState.set({
            ...this.lohnkontextState(),
            ...{ failedToSetLohnkontext: true },
          });
        }
        this.isLoaded.set(true);
      });
  }

  public initializeLohnkontextFromSettings() {
    const effectRef = effect(() => {
      const loaded = this.settingsfacade.select.isSettingsLoaded();
      if (loaded) {
        effectRef.destroy();
        untracked(() => {
          const settings = this.lohnkontextSettings();
          this.setLohnkontext({
            abrechnungskreis: !settings?.value?.abrechnungskreis
              ? undefined
              : {
                  id: settings.value.abrechnungskreis,
                },
            lizenznehmer: !settings?.value?.lizenznehmer
              ? undefined
              : { id: settings.value.lizenznehmer },
            abrechnungszeitraum: parseFacadedAbrechnungszeitraum(
              settings?.value?.abrechnungszeitraum,
            ),
          });
        });
      }
    });
  }

  public setReadonly(readonly: boolean) {
    const readonlyToSet = readonly
      ? LohnkontextReadonly.All
      : LohnkontextReadonly.None;
    if (this.lohnkontextState().readonly === readonlyToSet) {
      return;
    }
    this.lohnkontextState.set({
      ...this.lohnkontextState(),
      readonly: readonlyToSet,
    });
  }

  private filterOutIfAlreadySet(): OperatorFunction<
    LohnkontextPartial,
    LohnkontextPartial
  > {
    return (input$) =>
      input$.pipe(
        withLatestFrom(this.lohnkontextState$),
        filter(([toSet, lohnkontext]) => {
          if (!lohnkontext) return true;
          const abrechnungskreisChanged =
            toSet.abrechnungskreis?.id != lohnkontext?.abrechnungskreis?.id;
          const lizenznehmerChanged =
            toSet?.abrechnungskreis == null &&
            toSet?.lizenznehmer?.id != lohnkontext?.lizenznehmer?.id;
          const abrechnungszeitraumChanged =
            toSet?.abrechnungszeitraum != null &&
            !toSet?.abrechnungszeitraum.equals(
              lohnkontext?.abrechnungszeitraum,
            );
          return (
            abrechnungskreisChanged ||
            lizenznehmerChanged ||
            abrechnungszeitraumChanged
          );
        }),
        map(([payload]) => payload),
      );
  }

  private fillMissingLohnkontextProperties(): OperatorFunction<
    LohnkontextPartial,
    LohnkontextPartial
  > {
    return (input$) =>
      input$.pipe(
        switchMap((toSet) => {
          if (
            toSet.abrechnungskreis == null &&
            toSet.lizenznehmer?.id != null
          ) {
            return this.lizenznehmerQueryService
              .getById({ id: toSet.lizenznehmer.id })
              .pipe(
                map((result) => ({
                  lizenznehmer: {
                    ...toSet.lizenznehmer,
                    nummer: result.nummer,
                    bezeichnung: result.bezeichnung,
                  },
                })),
                this.handleError(toSet),
              );
          } else if (toSet.abrechnungskreis?.id != null) {
            return this.abrechnungskreisQueryService
              .getById({
                id: toSet.abrechnungskreis.id,
              })
              .pipe(
                mergeMap((abrechnungskreis) =>
                  (isMandantAndLizenznehmerAvailbale(abrechnungskreis)
                    ? of(getMandantFromAbrechnungskreis(abrechnungskreis))
                    : this.mandantenQueryService.getById({
                        id: abrechnungskreis.mandantId,
                      })
                  ).pipe(
                    this.checkMandantValid(abrechnungskreis),
                    mergeMap((mandant) =>
                      (isLizenznehmerAvailable(mandant)
                        ? of(getLizenznehmerFromMandant(mandant))
                        : this.lizenznehmerQueryService.getById({
                            id: mandant.lizenznehmerId,
                          })
                      ).pipe(
                        map(
                          (lizenznehmer) =>
                            ({
                              abrechnungskreis: abrechnungskreis,
                              mandant: mandant,
                              abrechnungszeitraum: toSet.abrechnungszeitraum,
                              lizenznehmer: lizenznehmer,
                            }) as Lohnkontext,
                        ),
                      ),
                    ),
                  ),
                ),
                this.handleError(toSet),
              );
          }
          return of({
            ...toSet,
            lizenznehmer: undefined,
            abrechnungskreis: undefined,
          });
        }),
      );
  }

  private checkMandantValid<T extends Mandant>(
    abrechnungskreis: Abrechnungskreis,
  ) {
    return pipe(
      switchMap<T, Observable<T>>((mandant) => {
        if (
          mandant.lizenznehmerDeletedOn != null ||
          mandant.lizenznehmerId == null
        ) {
          const message = `Verbinden Sie Verbuchungsbetrieb ${mandant.nummer} mit einem gültigen Lizenznehmer.`;
          this.dialogService.showMessageNoChoice(message);
          return throwError(() => new Error(message));
        }
        return of(mandant);
      }),
      catchError((error: SalaryError) => {
        if (error.statusCode === HttpStatusCode.NotFound) {
          this.dialogService.showMessageNoChoice(
            `Verbinden Sie Abrechnungskreis ${abrechnungskreis.nummer} mit einem gültigen Verbuchungsbetrieb.`,
          );
        }
        return throwError(() => error);
      }),
    );
  }

  private handleError = (
    lohnkontext: LohnkontextPartial,
  ): OperatorFunction<unknown, Lohnkontext> =>
    catchError(() =>
      lohnkontext.abrechnungszeitraum
        ? of<LohnkontextPartial>({
            abrechnungszeitraum: lohnkontext.abrechnungszeitraum,
          })
        : of(undefined),
    );

  private setAbrechnungszeitraumOperator(): OperatorFunction<
    Lohnkontext,
    Lohnkontext
  > {
    return (input$) =>
      input$.pipe(
        withLatestFrom(
          this.isLoaded$,
          this.lohnkontextState$.pipe(
            map((state) => state.abrechnungszeitraum),
            distinctUntilDateTimeChanged(),
          ),
        ),
        map(([lohnkontext, isLoaded, currentDate]) => {
          if (
            !isLoaded ||
            !lohnkontext?.abrechnungskreis?.letzteAbgeschlosseneAbrechnungsmonat
          ) {
            return lohnkontext;
          }
          const modifiedDate =
            lohnkontext.abrechnungskreis.letzteAbgeschlosseneAbrechnungsmonat.plus(
              { month: 1 },
            );
          if (
            modifiedDate.toFormat(DateTimeFormats.MONTH_YEAR) ===
            currentDate?.toFormat(DateTimeFormats.MONTH_YEAR)
          ) {
            return lohnkontext;
          }
          this.dateChangedByLogic$.next();
          this.notificationService.show(
            `Der gewählte Abrechnungszeitraum wurde auf ${modifiedDate.toFormat(
              DateTimeFormats.MONTH_YEAR,
            )} geändert.`,
            { duration: 8000 },
          );
          return {
            ...lohnkontext,
            abrechnungszeitraum: modifiedDate,
          };
        }),
      );
  }
}
