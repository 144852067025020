import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  AnimationPlayer,
  keyframes,
  style,
} from '@angular/animations';
import {
  ChangeDetectorRef,
  computed,
  Directive,
  effect,
  inject,
  input,
  Signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatBadge } from '@angular/material/badge';
import { StringWithSuggestions } from '@salary/common/dumb';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';

@Directive({ selector: '[salaryTwoDigitBadge]' })
export class TwoDigitBadgeDirective {
  value = input.required<number | StringWithSuggestions<'loading'>>({
    alias: 'salaryTwoDigitBadge',
  });
  badgeContent$ = new Subject<string | number>();
  private loadingAnimationFactory: AnimationFactory;
  private loadingPlayer: AnimationPlayer;
  private fadeInPlayer: AnimationPlayer;
  private divElement: HTMLDivElement;
  private badge = inject(MatBadge);
  private builder = inject(AnimationBuilder);
  private fadeInAnimation = inject(AnimationBuilder).build([
    animate(
      '1s ease-out',
      keyframes([
        style({ color: 'transparent', offset: 0 }),
        style({ color: '*', offset: 1 }),
      ]),
    ),
  ]);
  private cdRef = inject(ChangeDetectorRef);

  constructor() {
    this.badgeContent$
      .pipe(distinctUntilChanged(), debounceTime(200), takeUntilDestroyed())
      .subscribe((value) => {
        this.badge.content = value;
        this.animateBadgeChange(this.value() === 'loading' ? 'start' : 'end');
        this.cdRef.markForCheck();
      });
    effect(() => this.updateBadgeContent());
  }

  private updateBadgeContent() {
    if (this.value() === 'loading') {
      this.badgeContent$.next('\u200B');
    } else if (+this.value() >= 100) {
      this.badgeContent$.next('99+');
    } else if (this.value() === 0) {
      this.badgeContent$.next(undefined);
    } else {
      this.badgeContent$.next(this.value());
    }
  }

  private animateBadgeChange(animate: 'start' | 'end') {
    if (animate === 'end' && this.loadingPlayer?.hasStarted()) {
      this.startFadeInAnimation();
      this.stopLoadingAnimation();
    } else if (animate === 'start' && !this.loadingPlayer?.hasStarted()) {
      const badgeElement = this.badge.getBadgeElement();
      if (!badgeElement) return;
      if (!this.loadingPlayer) {
        this.createDivElement();
        this.initLoadingAnimation();
        this.loadingPlayer = this.loadingAnimationFactory.create(
          this.divElement,
        );
        this.loadingPlayer.play();
        this.loadingPlayer.onDone(() => {
          this.loadingPlayer?.restart();
        });
      }
    }
  }

  private startFadeInAnimation() {
    const badgeElement = this.badge.getBadgeElement();
    if (!badgeElement || this.fadeInPlayer != null) return;
    this.fadeInPlayer = this.fadeInAnimation.create(badgeElement);
    this.fadeInPlayer.onDone(() => {
      this.fadeInPlayer.destroy();
      this.fadeInPlayer = undefined;
    });
    this.fadeInPlayer.play();
  }

  private createDivElement() {
    this.divElement = document.createElement('div');
    this.divElement.className = 'badge-loading';
    this.badge.getBadgeElement().appendChild(this.divElement);
  }

  private initLoadingAnimation() {
    if (this.loadingAnimationFactory == null) {
      const bgColor = getComputedStyle(this.divElement).backgroundColor;
      const rgbaColor1 = bgColor
        .replace('rgb', 'rgba')
        .replace(')', `,${0.6})`);
      const rgbaColor2 = bgColor
        .replace('rgb', 'rgba')
        .replace(')', `,${0.1})`);
      this.loadingAnimationFactory = this.builder.build([
        animate(
          '2s',
          keyframes([
            style({ scale: 1, background: bgColor }),
            style({ scale: 1.4, background: rgbaColor1 }),
            style({ scale: 0.6, background: rgbaColor2 }),
            style({ scale: 1, background: bgColor }),
          ]),
        ),
      ]);
    }
  }

  private stopLoadingAnimation() {
    this.loadingPlayer?.destroy();
    this.loadingPlayer = undefined;
    if (this.divElement.parentElement === this.badge.getBadgeElement()) {
      this.badge.getBadgeElement().removeChild(this.divElement);
    }
  }
}

export function createTooltipForBadgeCount(count: Signal<number | string>) {
  return computed(() => {
    const value = count();
    return untracked(() => {
      if (typeof value === 'string') {
        return undefined;
      }
      return value > 99 ? value + ' Datensätze' : undefined;
    });
  });
}
