import {
  CellPosition,
  ColDef,
  ColumnState,
  GetContextMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideSelectionState,
  MenuItemDef,
  ProcessCellForExportParams,
  ProcessRowParams,
  RowDoubleClickedEvent,
  RowNode,
  RowPinnedType,
  RowSelectionOptions,
  RowValueChangedEvent,
  SelectionChangedEvent,
  SortController,
  TabToNextCellParams,
} from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
  Self,
  Signal,
  computed,
  effect,
  inject,
  input,
  model,
  output,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import {
  outputToObservable,
  takeUntilDestroyed,
} from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BaseModel,
  Validation,
  ValidationStatus,
  getFieldName,
} from '@salary/common/dumb';
import { QueryPagePayload } from '@salary/common/standard-facade';
import {
  createCopy,
  distinctUntilChangedStringify,
} from '@salary/common/utils';
import {
  Observable,
  Subject,
  debounceTime,
  exhaustMap,
  filter,
  of,
  take,
  withLatestFrom,
} from 'rxjs';
import {
  CheckboxEditorComponent,
  FileDownloadCellRendererComponent,
  GroupRowInnerRendererComponent,
  getRouterLinkValue,
} from '.';
import { HotkeysService } from '../hotkeys-dialog/service/hotkeys.service';
import { StripHtmlPipe } from '../pipes';
import { getRendererCellValue } from '../utils';
import { SortOrder } from '../utils/sort-order.enum';
import {
  convertSortOrder,
  createAgGridColumn,
  createAgGridDefaultColumn,
  createDefaultButtonColumn,
  getColumnDefinitionVisibilityFromHide,
  getColumnHideFromColumnDefinitionAndSecurity,
} from './ag-grid-column-adapter';
import { ColumnDefinition } from './column/column-definition';
import { CustomTooltipComponent } from './custom-tooltip.component';
import { DateInputEditorComponent } from './date-input.editor.component';
import { EnumInputEditorComponent } from './enum-input.editor.component';
import { InputEditorComponent } from './input.editor.component';
import { ListValidationRendererComponent } from './list-validation.renderer.component';
import { ListInputRendererComponent } from './list.input.renderer.component';
import { LoadingHeaderComponent } from './loading-header.component';
import { SearchInputEditorComponent } from './search-input.editor.component';
import { RowSaveEventArgs } from './utils';
import { BaseCellRenderer } from './utils/base-cell-renderer';
import { BaseInputEditor } from './utils/base-input-editor';
import {
  ColumnDefinitionInternal,
  ColumnWidthCalculator,
} from './utils/column-width-calculator';

const DEFAULT_MULTI_ROW_SELECTION_OPTIONS: RowSelectionOptions = {
  mode: 'multiRow',
  checkboxes: false,
  enableClickSelection: true,
  headerCheckbox: false,
  hideDisabledCheckboxes: true,
};

const DEFAULT_SINGLE_ROW_SELECTION_OPTIONS: RowSelectionOptions = {
  mode: 'singleRow',
  checkboxes: false,
  enableClickSelection: true,
  hideDisabledCheckboxes: true,
};

export interface RequestPageParams<T> {
  payload: QueryPagePayload;
  parent?: T;
  callback: (success: boolean, entities?: T[], rowCount?: number) => void;
}

@Component({
  selector: 'salary-list',
  templateUrl: './list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent<T extends BaseModel> implements OnInit, OnDestroy {
  public agGrid = viewChild('agGrid', { read: ElementRef });
  public isValid = signal(true);
  rowModelType = input<'clientSide' | 'serverSide'>('clientSide');
  readyToLoad = input<boolean>(false);
  columnDefinitions = model<ColumnDefinitionInternal<T>[]>();
  newItemRow = input<boolean>();
  requestConfirmation = input<() => Observable<boolean>>();
  suppressShowLoadingOverlay = input(false);
  showLoadingOverlay = model(true);
  sizeColumnsToFitContent = model(false);
  rowData = model<T[]>();
  selectedRowOnSubsequentLoads = input(-1);
  selectedRowOnFirstLoad = input(-1);
  rowSelection = input<'multiple' | 'single'>('multiple');
  checkboxSelection = input(false);
  disableTabToNextCell = input(false);
  selectFirstRow = input(false);
  domLayout = input<'normal' | 'autoHeight' | 'print'>(null);
  disableColumnSorting = input(false);
  disableColumnMoving = input(false);
  validationSettings = input<ValidationSettings>();
  selectableRowCallback = input<(rowData: T) => boolean>();
  suppressNoRowsOverlay = input(false);
  rowDoubleClickHandler = input<() => void>();
  modelCaptionPlural = input<string>(undefined);
  serverModePageSize = input<number>(50);
  treeNodeIsExpandable = input<(data: T) => boolean>();

  requestPage = output<RequestPageParams<T>>();
  loadingOverlayShown = output<boolean>();
  selectionChanged = output<T[]>();
  invisibleRowsSelected = output<boolean>();
  focusedRowChanged = output<number>();
  saveRowData = output<RowSaveEventArgs<T>>();
  validChanged = output<boolean>();
  editingStateChanged = output<boolean>();
  gridReady = output<GridReadyEvent<T>>();
  columnStateChanged = output<ColumnDefinition<T>[]>();
  rowEditingStarted = output<Partial<T>>();

  private translations = new Map<string, string>([
    ['noRowsToShow', 'Keine Daten für die Anzeige verfügbar'],
    ['page', 'Seite'],
    ['more', '...'],
    ['to', 'bis'],
    ['of', 'von'],
    ['copy', 'Kopieren'],
    ['copyWithHeaders', 'Kopieren (mit Spaltenbezeichnungen)'],
    ['ctrlC', 'Strg+C'],
    ['loadingOoo', 'Lade Daten...'],
    ['loadingError', 'Fehler beim Laden'],
  ]);
  private destroyRef = inject(DestroyRef);
  private clearFocusCallback: (event) => void;
  private jumpedToCellBecauseOfTabInPinnedRow = false;
  private lastEditedRowDirty = false;
  private forceKeepContentVisibleRefreshMode = false;
  private alreadySizeChanged = false;
  private columnStateChanged$ = new Subject<void>();
  private focusedRow = -1;
  private rowSelectionOperationsDone = [false, false];
  private changeVisibleRows = new Subject<{ top: number }>();
  private selectionChanged$ = new Subject<T[]>();
  private scrollPosition = 0;
  private visibleRowNodes = [];
  private columnWidthCalculator = new ColumnWidthCalculator<T>(this);
  private firstDataLoaded = false;
  private intersectionObserver: IntersectionObserver;
  private entities = signal<T[]>(undefined);
  private triggerSort = new Subject<() => void>();
  private injector = inject(Injector);
  private get selectedRowToRestore() {
    return this.firstDataLoaded
      ? this.selectedRowOnSubsequentLoads()
      : this.selectedRowOnFirstLoad();
  }
  private rowCount = signal<number>(undefined);
  private allDataInOnePage = computed(() => {
    if (this.rowModelType() === 'clientSide') {
      return true;
    }
    return this.rowCount() <= this.serverModePageSize();
  });
  protected readonly agRowSelection = computed<RowSelectionOptions<T>>(() => {
    const result = {
      ...(this.rowSelection() === 'single'
        ? DEFAULT_SINGLE_ROW_SELECTION_OPTIONS
        : DEFAULT_MULTI_ROW_SELECTION_OPTIONS),
    };
    result.checkboxes = this.checkboxSelection();
    if (result.mode === 'multiRow') {
      result.headerCheckbox = result.checkboxes
        ? this.allDataInOnePage()
        : false;
    }
    const selectableRowCallback = this.selectableRowCallback();
    if (selectableRowCallback) {
      result.isRowSelectable = (node) =>
        node.data ? selectableRowCallback(node.data) : true;
    }
    return result;
  });
  protected readonly treeData = computed(
    () => this.treeNodeIsExpandable() != null,
  );
  protected readonly treeDataGroupKey = (data: T) => data.id;
  protected groupRowRendererParams = {
    innerRenderer: GroupRowInnerRendererComponent,
    suppressCount: true,
  };
  gridApi: GridApi = undefined;
  agGridComponents = {
    inputEditor: InputEditorComponent,
    searchInputEditor: SearchInputEditorComponent,
    dateInputEditor: DateInputEditorComponent,
    customTooltipComponent: CustomTooltipComponent,
    loadingHeaderComponent: LoadingHeaderComponent,
    enumInputEditor: EnumInputEditorComponent,
    checkboxEditor: CheckboxEditorComponent,
  };
  newItemRowData = signal<T[]>([{} as T]);
  selectedRowData: unknown[] = [];
  columnDefs = signal<ColDef[]>([]);
  defaultColDef: ColDef = createAgGridDefaultColumn();
  expandedColumnsDefs: ColDef[] = [];
  gridOptions: GridOptions = {
    rowHeight: 48,
    getRowId: (params) => params.data?.id,
    scrollbarWidth: 8,
    onCellEditingStarted: () => (this.hotkeysService.hotkeysSuspended = true),
    onCellEditingStopped: () => (this.hotkeysService.hotkeysSuspended = false),
    onRowEditingStarted: (event) => {
      this.editingStateChanged.emit(true);
      this.rowEditingStarted.emit(event.node.data);
    },
    onRowEditingStopped: () => this.editingStateChanged.emit(false),
    onRowDoubleClicked: (args) => this.onRowDoubleClicked(args),
    processRowPostCreate: (params) => {
      if (
        this.selectFirstRow() &&
        this.selectedRowToRestore === -1 &&
        params.rowIndex === 0 &&
        !this.isInitialRowOperationDone()
      ) {
        this.tryToSelectRow(params);
        this.rowSelectionOperationsDone = [true, true];
      } else if (
        params.rowIndex === this.selectedRowToRestore &&
        !this.isInitialRowOperationDone()
      ) {
        this.tryToSelectRow(params);
        this.rowSelectionOperationsDone[0] = true;
      }
    },
    context: {
      thisComponent: this,
    },
    onFirstDataRendered: () => {
      if (this.sizeColumnsToFitContent()) {
        setTimeout(() => this.autoSizeColumnsToFitContent());
      }
      this.modifySortController();
    },
    autoSizePadding: 25, //no line break because of sort arrow
    overlayLoadingTemplate: '<span></span>',
    getLocaleText: (params) => {
      if (this.modelCaptionPlural() && params.key === 'loadingOoo') {
        return `Lade ${this.modelCaptionPlural()}...`;
      }
      return this.translations.get(params.key) || params.defaultValue;
    },
    selectionColumnDef: {
      width: 30,
      minWidth: 30,
    },
  };

  private modifySortController() {
    const sortController = this.getSortController();
    const orginialSortFunction =
      sortController.progressSort.bind(sortController);
    if (this.requestConfirmation()) {
      sortController.progressSort = (column, multiSort, source) => {
        if (this.disableColumnSorting()) return;
        this.triggerSort.next(() => {
          orginialSortFunction(column, multiSort, source);
          this.columnStateChanged$.next();
        });
      };
    }
  }

  private getSortController(): SortController {
    return this.gridApi.getColumns()[0]['stubContext'].beans.sortController;
  }

  public updateValid() {
    if (this.gridApi) {
      this.gridApi.stopEditing(false);
      this.getEventService().flushAsyncQueue();
      this.updateValidState();
    }
  }

  private getEventService() {
    return this.gridApi.getColumns()[0]['stubContext'].beans.eventService
      .globalEventService;
  }

  private tryToSelectRow(params: ProcessRowParams, counter = 0) {
    const rowToSelect = params.api.getDisplayedRowAtIndex(params.rowIndex);
    if (rowToSelect?.id) {
      rowToSelect.setSelected(true);
      return;
    }
    if (counter <= 20 && !params.node.id) {
      setTimeout(() => this.tryToSelectRow(params, ++counter), 200);
    } else if (counter > 20) {
      outputToObservable(this.loadingOverlayShown)
        ?.pipe(
          filter((loading) => !loading),
          take(1),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => setTimeout(() => this.tryToSelectRow(params)));
    }
  }

  //used to reduce number of requests to facade. > 0 => event is blocked
  blockColumnsChanged = 0;

  constructor(
    @Self() public element: ElementRef,
    private hotkeysService: HotkeysService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private renderer: Renderer2,
  ) {
    this.intersectionObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            observer.disconnect();
            if (this.sizeColumnsToFitContent()) {
              //grid in mat-tab => could be invisible until tab change
              setTimeout(() => this.autoSizeColumnsToFitContent());
            }
          }
        });
      },
      {},
    );
    this.intersectionObserver.observe(element.nativeElement);

    effect(() => {
      this.columnDefinitions();
      untracked(() => {
        setTimeout(() => this.applyColumnDefinitionsToState());
      });
    });
    effect(() => {
      const showOverlay = this.showLoadingOverlay();
      if (!this.suppressShowLoadingOverlay()) {
        untracked(() => {
          if (showOverlay) {
            this.gridApi?.setGridOption('loading', true);
            this.loadingOverlayShown.emit(true);
          } else {
            this.gridApi?.setGridOption('loading', false);
            this.loadingOverlayShown.emit(false);
          }
        });
      }
    });
    effect(() => {
      const clientSideMode = this.rowModelType() === 'clientSide';
      if (clientSideMode) {
        const clientSideRowCount = this.rowData()?.length;
        untracked(() => this.rowCount.set(clientSideRowCount));
      }
    });

    effect(() => {
      this.validationSettings()?.validationEnabled();
      untracked(() => {
        this.applyColumnDefinitionsToState();
      });
    });

    effect(() => {
      if (!this.validationSettings()?.validationEnabled()) {
        return;
      }
      const entities = this.entities();
      if (!entities?.length) {
        return;
      }
      const validationResults = this.validationSettings().validationResults();
      untracked(() => {
        if (validationResults != null) {
          this.handleValidationResults(entities, validationResults);
        }
      });
    });
    effect(() => this.validChanged.emit(this.isValid()));
  }

  private clearFocusIfGridNotFocused(event: Event) {
    if (!this.gridApi) {
      return;
    }
    const container = this.agGrid().nativeElement;
    const isPartOfOverlay = event
      .composedPath()
      .some((e: HTMLElement) =>
        e.className?.includes?.('cdk-overlay-container'),
      );
    if (
      !isPartOfOverlay &&
      !container.contains(event.target) &&
      event.composedPath()?.[0] != document.body
    ) {
      this.gridApi?.clearFocusedCell();
    }
  }

  ngAfterViewInit() {
    if (!this.renderer || !this.element) {
      return;
    }
    //makes vertical scrollbar visible if horizontal scrollbar is hovered
    const horizontalViewport =
      this.element.nativeElement.getElementsByClassName(
        'ag-body-horizontal-scroll-viewport',
      )[0];
    const viewport =
      this.element.nativeElement.getElementsByClassName('ag-body-viewport')[0];
    if (!horizontalViewport || !viewport) {
      return;
    }
    this.renderer.listen(horizontalViewport, 'mouseover', () =>
      this.renderer.addClass(viewport, 'scrollbar-hover'),
    );
    this.renderer.listen(horizontalViewport, 'mouseout', () =>
      this.renderer.removeClass(viewport, 'scrollbar-hover'),
    );
  }

  ngOnInit() {
    this.clearFocusCallback = (evt) => this.clearFocusIfGridNotFocused(evt);
    document.addEventListener('mouseup', this.clearFocusCallback);
    document.addEventListener('keyup', this.clearFocusCallback);

    this.initializeColumsDefs();

    this.selectionChanged$
      .pipe(
        distinctUntilChangedStringify(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((value) => {
        this.updateInvisibleRowsSelected();
        this.selectionChanged.emit(value);
      });

    this.tabToNextCell = this.tabToNextCell.bind(this);

    this.triggerSort
      .pipe(
        exhaustMap((executeSorting) =>
          this.requestConfirmation()().pipe(withLatestFrom(of(executeSorting))),
        ),
        filter(([confirmation]) => confirmation),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(([, executeSorting]) => executeSorting());
  }

  protected onColumnsInitialized() {
    this.blockColumnsChanged += 2;
  }

  handleValidationResults(rowData: T[], validationList: Validation[]): void {
    rowData.forEach((data) => {
      const validations = validationList.filter((validation) => {
        const idPropertyName = Object.getOwnPropertyNames(validation).find(
          (prop) => prop.endsWith('Id'),
        );
        return validation[idPropertyName] === data.id;
      });
      const result = validations.reduce((acc, curr) => {
        return {
          warningsCount: this.safeAdd(acc.warningsCount, curr.warningsCount),
          errorsCount: this.safeAdd(acc.errorsCount, curr.errorsCount),
          infosCount: this.safeAdd(acc.infosCount, curr.infosCount),
          validationsFailedCount: this.safeAdd(
            acc.validationsFailedCount,
            curr.validationsFailedCount,
          ),
          validationCount: this.safeAdd(
            acc.validationCount,
            curr.validationCount,
          ),
          results: (acc.results ?? []).concat(curr.results ?? []),
          status:
            curr.status !== ValidationStatus.Succeeded
              ? curr.status
              : acc.status,
          personalId: curr.personalId,
        };
      }, {});
      data['validation'] = result ?? { status: ValidationStatus.Succeeded };
    });
  }

  private safeAdd(a: number, b: number) {
    return (a ?? 0) + (b ?? 0);
  }

  displayedColumnsChanged() {
    if (this.blockColumnsChanged > 0) {
      this.blockColumnsChanged--;
      return;
    }
    this.columnStateChanged$.next();
  }

  getColumnDefinitionById(colId: string) {
    return !colId
      ? undefined
      : this.columnDefinitions()?.find((definition) => {
          const fieldName = getFieldName(definition.modelPropertyName);
          return (fieldName ?? definition.columnTitle) === colId;
        });
  }

  applyStateToColumnDefinitions(): ColumnDefinition<T>[] {
    const result: ColumnDefinition<T>[] = [];
    this.gridApi.getColumnState().forEach((columnState) => {
      const columnDefinition = this.getColumnDefinitionById(columnState.colId);
      if (!columnDefinition) return;
      const columnDefinitionResult = createCopy(columnDefinition);
      columnDefinitionResult.visibility = getColumnDefinitionVisibilityFromHide(
        columnState,
        columnDefinitionResult,
      );
      columnDefinitionResult.sortOrder =
        (columnState.sort as SortOrder) ?? undefined;
      result.push(columnDefinitionResult);
    });

    return result;
  }

  applyColumnDefinitionsToState(): void {
    if (!this.alreadySizeChanged) {
      return;
    }
    const states: ColumnState[] = [];
    if (!this.sizeColumnsToFitContent()) {
      this.columnWidthCalculator.setDefaultWidthToColumnDefinitions();
    }
    if (this.validationSettings()?.validationEnabled() != null) {
      const state: ColumnState = {
        colId: 'validation',
        hide: this.validationSettings()?.validationEnabled() !== true,
      };
      states.push(state);
    }
    this.columnDefinitions()?.forEach((colDef) => {
      const fieldName = getFieldName(colDef.modelPropertyName);
      const state: ColumnState = {
        colId: fieldName ?? colDef.columnTitle,
        hide: getColumnHideFromColumnDefinitionAndSecurity(
          colDef,
          this.columnDefs(),
        ),
        sort: convertSortOrder(colDef.sortOrder),
        width: this.sizeColumnsToFitContent() ? null : colDef.width,
      };
      states.push(state);
    });

    if (this.isStateChanged(states)) {
      this.blockColumnsChanged++;
      this.gridApi.applyColumnState({
        state: states,
        applyOrder: true,
      });
    }
  }

  isStateChanged(columnState: ColumnState[]): boolean {
    if (!this.gridApi || this.gridApi.isDestroyed()) {
      return false;
    }
    const currentState = this.gridApi.getColumnState();
    if (columnState.length != currentState.length) {
      return true;
    }
    for (let index = 0; index < currentState.length; index++) {
      const currentElement = currentState[index];
      const elementToCompare = columnState[index];
      if (currentElement.colId != elementToCompare.colId) {
        return true;
      }
      if (currentElement.hide != elementToCompare.hide) {
        return true;
      }
      if (currentElement.sort != elementToCompare.sort) {
        return true;
      }
      if (currentElement.width != elementToCompare.width) {
        return true;
      }
    }
    return false;
  }
  public refreshGrid(refreshMode: GridRefreshMode) {
    if (!this.gridApi) return;
    if (refreshMode !== GridRefreshMode.keepContentVisible) {
      this.deselectAll();
    }
    this.resetNewItemRowData();
    this.rowSelectionOperationsDone = [false, false];
    if (
      refreshMode === GridRefreshMode.keepContentVisible ||
      this.forceKeepContentVisibleRefreshMode
    ) {
      this.forceKeepContentVisibleRefreshMode = false;
      this.gridApi.refreshServerSide();
      return;
    }
    if (refreshMode === GridRefreshMode.hideContentLoadPageOne) {
      this.gridApi.ensureIndexVisible(0);
    }
    this.gridApi.refreshServerSide({ purge: true });
  }

  public refreshColumnDefinitions(columnDefinitions: ColumnDefinition<T>[]) {
    this.columnDefinitions.set(columnDefinitions);
    this.initializeColumsDefs();
  }

  public deselectAll() {
    this.gridApi.deselectAll();
  }

  public tabToNextCell(params: TabToNextCellParams): CellPosition | boolean {
    if (this.disableTabToNextCell() && !params.backwards) {
      return false;
    }
    if (
      params.previousCellPosition.rowPinned === 'top' &&
      params.nextCellPosition?.rowPinned == null
    ) {
      const result = {
        rowIndex: 0,
        column: this.gridApi
          .getColumns()
          .find((col) => col.isCellEditable(params.api.getPinnedTopRow(0))),
        rowPinned: <RowPinnedType>'top',
      };
      this.jumpedToCellBecauseOfTabInPinnedRow = true;
      return result;
    } else if (params.nextCellPosition) {
      return {
        rowIndex: params.nextCellPosition.rowIndex,
        column: params.nextCellPosition.column,
        rowPinned: params.nextCellPosition.rowPinned,
      };
    }
    if (params.editing && !params.nextCellPosition) {
      this.gridApi.stopEditing(false);
    }
    return false;
  }

  private initializeColumsDefs() {
    this.columnDefs.set([]);
    const columns: ColDef[] = [];
    this.addValidationColumn();

    this.columnDefinitions()?.forEach((column) => {
      columns.push(this.createColumnDef(column));
    });
    if (!this.sizeColumnsToFitContent()) {
      this.columnWidthCalculator.setDefaultWidthToColumnDefinitions();
      for (let index = 0; index < columns.length; index++) {
        const colDef = columns[index];
        colDef.width = this.columnDefinitions()?.[index]?.width;
      }
    }
    this.columnDefs.update((cols) => [...cols, ...columns]);
  }

  private addValidationColumn() {
    if (this.validationSettings()?.validationEnabled() != null) {
      const validationColumnDefinition: ColDef & {
        pdfExportOptions?: { skipColumn?: boolean };
      } = {
        ...createDefaultButtonColumn(),
        field: 'validation',
        headerName: 'Status',
        headerComponent: 'loadingHeaderComponent',
        headerComponentParams: {
          loading: this.validationSettings().validationLoading,
        },
        lockPosition: true,
        resizable: false,
        maxWidth: 40,
        cellRenderer: ListValidationRendererComponent,
        headerClass: 'cell-button-style',
        cellStyle: {
          'text-overflow': 'unset',
          'padding-left': '5px',
        },
        pdfExportOptions: {
          skipColumn: true,
        },
        cellRendererParams: {
          fieldId: 'f7b3d1',
          buttonClickedHandler: (node: IRowNode) => {
            const colDef = this.columnDefs().find(
              (cdef) => cdef.cellRendererParams.routerLink != null,
            );
            let routerLinkValue = getRouterLinkValue(
              node.data[colDef.field],
              node.data,
              colDef.cellRendererParams.routerLink,
            );
            routerLinkValue = `${routerLinkValue}/detailextras/validations`;
            this.router.navigate([routerLinkValue], {
              relativeTo: this.activatedRoute,
            });
          },
          isLoading: this.validationSettings().validationLoading,
        },
      };
      this.columnDefs?.update((cols) => [...cols, validationColumnDefinition]);
    }
  }

  private createColumnDef(columnDefinition: ColumnDefinition) {
    const colDef = createAgGridColumn(columnDefinition);
    this.applyDefaultEditorValueSetter(colDef);
    if (!this.isAnyColumnEditable()) {
      colDef.suppressNavigable = false;
    }
    return colDef;
  }

  private isAnyColumnEditable(): boolean {
    return this.columnDefinitions()?.some(
      (column) =>
        column.editable === true || typeof column.editable === 'function',
    );
  }

  applyDefaultEditorValueSetter(definition: ColDef) {
    if (
      !definition.cellEditor ||
      !this.agGridComponents ||
      typeof definition.cellEditor !== 'string'
    )
      return;
    const cellEditorType = this.agGridComponents[definition.cellEditor];
    if (cellEditorType.defaultValueSetter && !definition.valueSetter) {
      definition.valueSetter = cellEditorType.defaultValueSetter;
    }
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    if (this.rowModelType() === 'serverSide') {
      const selectionState =
        this.gridApi.getServerSideSelectionState() as IServerSideSelectionState;
      if (selectionState.selectAll && event?.source === 'uiSelectAll') {
        const nodes = [];
        this.gridApi.forEachNode((n) => nodes.push(n));
        this.gridApi.setNodesSelected({ nodes, newValue: true, source: 'api' });
        return;
      }
    }
    const selectedRows = this.gridApi.getSelectedRows();
    this.selectionChanged$.next(selectedRows);
  }

  onCellFocused(event) {
    this.onFocusedRowChanged(event.rowIndex);
    this.updateValidState();
    if (event.rowPinned === 'top' && this.jumpedToCellBecauseOfTabInPinnedRow) {
      if (this.isCellEditorRowValid()) {
        this.gridApi.stopEditing(false);
      }
      this.jumpedToCellBecauseOfTabInPinnedRow = false;
      this.setFocusFirstCellOfNewItemRow();
    }
  }

  onFocusedRowChanged(rowIndex: number) {
    if (rowIndex != this.focusedRow) {
      this.focusedRow = rowIndex;
      this.focusedRowChanged.emit(rowIndex);
    }
  }

  updateValidState() {
    this.isValid.set(this.isCellEditorRowValid() && this.isRowNodeValid());
  }

  onCellValueChanged() {
    this.lastEditedRowDirty = true;
  }
  onRowValueChanged(event: RowValueChangedEvent<T>) {
    if (event.rowPinned !== 'top' && this.lastEditedRowDirty) {
      this.updateRow(event.node);
    }
    if (event.rowPinned === 'top') {
      this.saveNewRow();
    }
    this.lastEditedRowDirty = false;
  }
  saveNewRow() {
    const newRowData = this.newItemRowData()[0];
    if (Object.keys(newRowData).length === 0) {
      this.resetNewItemRowData();
      return;
    }
    const newItemRowNode = this.gridApi.getPinnedTopRow(0);
    if (!this.isRowNodeValid(newItemRowNode)) {
      return;
    }

    this.forceKeepContentVisibleRefreshMode =
      this.rowModelType() === 'serverSide' &&
      this.gridApi.paginationGetRowCount() !== 0;
    this.saveRowData.emit({
      rowIndex: -1,
      data: newRowData,
      new: true,
    });
    this.resetNewItemRowData();
  }
  resetNewItemRowData() {
    this.newItemRowData.set([{} as T]);
  }
  setFocusFirstCellOfNewItemRow() {
    if (this.gridApi.getEditingCells()?.[0]?.rowPinned === 'top') {
      return;
    }
    const firstColumn = this.gridApi
      .getColumns()
      .find((col) => col.isCellEditable(this.gridApi.getPinnedTopRow(0)));
    setTimeout(() => this.gridApi.setFocusedCell(0, firstColumn, 'top'));
  }
  updateRow(dataRow?: IRowNode<T>) {
    if (!this.isRowNodeValid(dataRow)) {
      return;
    }
    this.saveRowData.emit({
      data: dataRow.data,
      new: false,
      rowIndex: dataRow.rowIndex,
    });
  }

  isCellEditorRowValid() {
    return !this.gridApi
      .getCellEditorInstances({})
      .some(
        (cellEditorComponent: BaseInputEditor) => !cellEditorComponent?.isValid,
      );
  }

  isRowNodeValid(rowNode?) {
    const cellRendererInstances = rowNode
      ? this.gridApi.getCellRendererInstances({ rowNodes: [rowNode] })
      : this.gridApi.getCellRendererInstances();
    return !cellRendererInstances.some(
      (rendererComponent: BaseCellRenderer) =>
        rendererComponent?.isValid === false,
    );
  }

  onGridReady(readyParams: GridReadyEvent) {
    this.gridApi = readyParams.api;
    this.changeVisibleRows.next({ top: this.scrollPosition });
    this.handleVisibleRows();
    this.gridReady.emit(readyParams);
    this.initializeDataSource(readyParams);
    this.columnStateChanged$
      .pipe(debounceTime(200), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const columnDefinitionsWithStateInfo =
          this.applyStateToColumnDefinitions();
        this.columnStateChanged.emit(columnDefinitionsWithStateInfo);
      });

    this.applyColumnDefinitionsToState();
  }

  private initializeDataSource(readyParams: GridReadyEvent) {
    const datasource: IServerSideDatasource = {
      getRows: (params) => {
        this.requestRowData(params);
      },
    };
    if (this.rowModelType() === 'serverSide') {
      effect(
        () => {
          if (this.readyToLoad()) {
            setTimeout(() =>
              readyParams.api.setGridOption('serverSideDatasource', datasource),
            );
          }
        },
        { injector: this.injector },
      );
    }
  }

  private handleSelectionAfterPageLoad(currentPage: number, rowCount: number) {
    if (
      this.selectedRowToRestore != -1 &&
      !this.isInitialRowOperationDone() &&
      currentPage === 1
    ) {
      if (this.selectedRowToRestore < rowCount) {
        this.gridApi.ensureIndexVisible(this.selectedRowToRestore, 'middle');
      } else if (rowCount === 1 && this.selectFirstRow()) {
        const firstRow = this.gridApi.getDisplayedRowAtIndex(0);
        firstRow.setSelected(true);
      }
      this.rowSelectionOperationsDone[1] = true;
    }
    this.onSelectionChanged(undefined); //ag-grid bug => after delete/restore event is not triggered, but selection is changed
  }

  private requestRowData(params: IServerSideGetRowsParams) {
    this.showLoadingOverlay.set(true);
    const pageNumberToRequest: number =
      Math.ceil(params.request.startRow / this.serverModePageSize()) + 1;
    const isChildrenQuery = params.parentNode?.data != null;
    this.requestPage.emit({
      payload: {
        endpointConfiguration: {
          queryParameters: {
            page: pageNumberToRequest,
            pageSize: this.serverModePageSize(),
            orderBy: getFieldName(
              this.getColumnDefinitionById(params.request.sortModel[0]?.colId)
                ?.sortModelPropertyName ?? params.request.sortModel[0]?.colId,
            ),
            direction: params.request.sortModel[0]?.sort,
          },
        },
      },
      parent: isChildrenQuery ? params.parentNode.data : undefined,
      callback: (success: boolean, entities: T[], rowCount: number) => {
        if (success) {
          params.success({
            rowData: entities,
            rowCount,
          });
          if (!isChildrenQuery) {
            this.rowCount.set(rowCount);
            this.handleSelectionAfterPageLoad(pageNumberToRequest, rowCount);
            this.entities.set(entities);
            if (!this.firstDataLoaded) {
              this.firstDataLoaded = true;
              if (window['Cypress']) {
                window['listFirstDataLoaded'] = true;
              }
            }
          }
        } else {
          params.fail();
        }
        this.showLoadingOverlay.set(false);
      },
    });
  }

  onGridSizeChanged() {
    this.alreadySizeChanged = true;
    setTimeout(() => this.applyColumnDefinitionsToState());
  }

  ngOnDestroy(): void {
    this.intersectionObserver.disconnect();
    document.removeEventListener('mouseup', this.clearFocusCallback);
    document.removeEventListener('keyup', this.clearFocusCallback);
  }

  isInitialRowOperationDone() {
    return (
      this.rowSelectionOperationsDone[0] && this.rowSelectionOperationsDone[1]
    );
  }

  public selectRow(index: number | 'next' | 'previous') {
    const selectedRows = this.gridApi.getSelectedNodes();
    let indexToSelect = selectedRows[0]?.rowIndex;
    if (typeof index === 'number') {
      indexToSelect = index;
    } else if (index === 'next' && selectedRows.length > 0) {
      selectedRows.forEach((row) => {
        if (row.rowIndex > indexToSelect) {
          indexToSelect = row.rowIndex;
        }
      });
      indexToSelect++;
    } else if (index === 'previous' && selectedRows.length > 0) {
      selectedRows.forEach((row) => {
        if (row.rowIndex < indexToSelect) {
          indexToSelect = row.rowIndex;
        }
      });
      indexToSelect--;
    }

    const rowToSelect = this.gridApi.getDisplayedRowAtIndex(indexToSelect ?? 0);
    if (!rowToSelect) {
      return;
    }
    this.deselectAll();
    rowToSelect.setSelected(true);
    this.gridApi.setFocusedCell(
      rowToSelect.rowIndex,
      this.gridApi.getAllDisplayedColumns()[0],
    );
    this.gridApi.ensureIndexVisible(rowToSelect.rowIndex, 'middle');
  }

  public selectRows(ids: string[]) {
    const nodesToSelect = ids
      .map((id) => this.gridApi.getRowNode(id))
      .filter((x) => x != null);
    if (nodesToSelect.length > 0) {
      this.gridApi.deselectAll();
      this.gridApi.setNodesSelected({
        nodes: nodesToSelect,
        newValue: true,
      });
    }
  }

  focusFirstCellInNewItemRow() {
    const firstEditableColumn = this.gridApi
      .getAllDisplayedColumns()
      .filter((c) => c.getColDef().editable)?.[0];
    if (!firstEditableColumn) return;
    this.gridApi.setFocusedCell(0, firstEditableColumn, 'top');
    this.gridApi.startEditingCell({
      rowIndex: 0,
      colKey: firstEditableColumn,
      rowPinned: 'top',
    });
  }

  private handleVisibleRows() {
    this.gridApi.setGridOption('onBodyScroll', (event) => {
      if (event.direction === 'vertical') {
        this.scrollPosition = event.top;
        this.changeVisibleRows.next(event);
      }
    });
    this.gridApi.setGridOption('onModelUpdated', () => {
      this.changeVisibleRows.next({ top: this.scrollPosition });
    });

    window.onresize = () =>
      this.changeVisibleRows.next({ top: this.scrollPosition });

    this.changeVisibleRows
      .pipe(debounceTime(500), takeUntilDestroyed(this.destroyRef))
      .subscribe((scrollPosition) => {
        const headerElement =
          this.element.nativeElement.querySelectorAll('.ag-header');
        const agGrid =
          this.element.nativeElement.querySelectorAll('ag-grid-angular');
        if (!headerElement[0] || !agGrid[0]) {
          return;
        }
        const gridBodyHeight =
          agGrid[0].offsetHeight - headerElement[0].offsetHeight;
        const topPixel = scrollPosition.top;
        const bottomPixel = scrollPosition.top + gridBodyHeight;

        this.visibleRowNodes = this.gridApi
          .getRenderedNodes()
          .filter(
            (node) =>
              node.rowTop >= topPixel - this.gridOptions.rowHeight / 2 &&
              node.rowTop +
                this.gridOptions.rowHeight -
                this.gridOptions.rowHeight / 2 <=
                bottomPixel,
          );
        this.updateInvisibleRowsSelected();
      });
  }

  private updateInvisibleRowsSelected() {
    const invisibleRowsSelected =
      this.gridApi
        .getSelectedNodes()
        .filter((selectedNode) => !this.visibleRowNodes.includes(selectedNode))
        .length > 0;
    this.invisibleRowsSelected.emit(invisibleRowsSelected);
  }

  getContextMenuItems(
    params: GetContextMenuItemsParams,
  ): (string | MenuItemDef)[] {
    changeSelectionOnOpeningContextMenu();

    const component: ListComponent<T> = params.context.thisComponent;
    const result: (string | MenuItemDef)[] = [
      {
        name: 'Kopieren',
        action: () => params.api.copySelectedRowsToClipboard(),
        shortcut: 'Strg+C',
      },
      {
        name: 'Kopieren (mit Spaltenbezeichnungen)',
        action: () =>
          params.api.copySelectedRowsToClipboard({ includeHeaders: true }),
      },
      {
        name: 'Spaltenbreite an Inhalt anpassen',
        action: () => {
          component.sizeColumnsToFitContent.set(
            !component.sizeColumnsToFitContent(),
          );
          if (component.sizeColumnsToFitContent()) {
            component.autoSizeColumnsToFitContent();
          } else {
            component.applyColumnDefinitionsToState();
          }
        },
        checked: params.context.thisComponent.sizeColumnsToFitContent(),
      },
    ];
    if (component.sizeColumnsToFitContent()) {
      result.push({
        name: 'Spaltenbreite aktualisieren',
        action: () => {
          component.autoSizeColumnsToFitContent();
        },
      });
    }
    return result;

    function changeSelectionOnOpeningContextMenu() {
      if (!params.node.isSelected()) {
        (<RowNode>params.node).setSelectedParams({
          newValue: true,
          clearSelection: true,
          rangeSelect: false,
          source: 'api',
        });
      }
    }
  }

  private autoSizeColumnsToFitContent() {
    if (!this.gridApi) {
      return;
    }
    this.blockColumnsChanged++;
    this.gridApi.autoSizeAllColumns();
  }

  processCellForClipboard(params: ProcessCellForExportParams) {
    const cellRenderer = params.api.getCellRendererInstances({
      columns: [params.column],
      rowNodes: [params.node],
    })?.[0] as BaseCellRenderer;
    if (cellRenderer instanceof ListInputRendererComponent) {
      const lineValue = getRendererCellValue(cellRenderer.params, 1);
      return new StripHtmlPipe().transform(lineValue);
    }
    return params.value;
  }

  private onRowDoubleClicked(args: RowDoubleClickedEvent) {
    if (this.isAnyColumnEditable()) {
      return;
    }
    if (this.rowDoubleClickHandler()) {
      this.rowDoubleClickHandler()();
      return;
    }
    const navigatedByRouterLink = this.tryNavigateByRouterLink(args.data);
    if (!navigatedByRouterLink) {
      this.tryNavigateByFileDownloadRenderer(args);
    }
  }

  private tryNavigateByFileDownloadRenderer(event: RowDoubleClickedEvent) {
    const fileDownloadRenderer = this.gridApi
      .getCellRendererInstances({
        rowNodes: [event.node],
      })
      .find(
        (renderer) => renderer instanceof FileDownloadCellRendererComponent,
      ) as FileDownloadCellRendererComponent;
    if (fileDownloadRenderer) {
      fileDownloadRenderer.onLinkClick();
    }
  }

  private tryNavigateByRouterLink(data: T): boolean {
    const columnDefinitionWithRouterLink = this.columnDefinitions()?.find(
      (cd) => cd.cellRendererOptions?.routerLink,
    );
    if (!columnDefinitionWithRouterLink) {
      return false;
    }
    const routerLinkValue = getRouterLinkValue(
      data[getFieldName(columnDefinitionWithRouterLink.modelPropertyName)],
      data,
      columnDefinitionWithRouterLink.cellRendererOptions.routerLink,
    );
    if (!routerLinkValue) {
      return false;
    }
    this.router.navigate([routerLinkValue], {
      relativeTo: this.activatedRoute,
    });
    return true;
  }

  public setEditorValue(fieldName: string, value: undefined) {
    const editor = this.gridApi.getCellEditorInstances({
      columns: [fieldName],
    }) as BaseInputEditor[];
    editor?.[0]?.setValue?.(value, false);
  }
}

export interface ValidationSettings {
  validationEnabled: Signal<boolean>;
  validationResults: Signal<Validation[]>;
  validationLoading: Signal<boolean>;
}

export enum GridRefreshMode {
  keepContentVisible,
  hideContent,
  hideContentLoadPageOne,
}
