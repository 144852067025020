import {
  DestroyRef,
  Injectable,
  Signal,
  computed,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StandardFacade } from '@salary/common/standard-facade';
import { PROCESS_MANAGER_SERVICE_TOKEN } from '@salary/common/utils';
import { Observable, filter, take } from 'rxjs';

@Injectable()
export class ProgressIndicationService {
  private activeOperations = signal<ProgressOperation[]>([]);
  private destroyRef = inject(DestroyRef);
  private processManagerService = inject(PROCESS_MANAGER_SERVICE_TOKEN);

  public isProgressActive = computed(() => this.activeOperations().length > 0);

  public isProgressActiveByOperationType(
    operationType: ProgressOperationType,
  ): Signal<boolean> {
    return computed(() => {
      const operations = this.activeOperations();
      return untracked(() =>
        operations.some(
          (operation) => operation.operationType === operationType,
        ),
      );
    });
  }

  public registerProgressWithCorrelationId(
    progressOperation: ProgressOperation,
    facade: StandardFacade<unknown>,
  ) {
    this.setOperationActive(progressOperation);
    facade
      .commandFinished(progressOperation.key)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setOperationInactive(progressOperation));
  }

  public registerProgressWithSubject(
    progressOperation: ProgressOperation,
    subject: Observable<unknown>,
  ) {
    this.setOperationActive(progressOperation);
    subject
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.setOperationInactive(progressOperation));
  }

  public registerProgressWithProcessId(progressOperation: ProgressOperation) {
    this.setOperationActive(progressOperation);
    this.processManagerService.processCompleted$
      .pipe(
        filter((pd) => pd.id === progressOperation.key),
        take(1),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => this.setOperationInactive(progressOperation));
  }

  private setOperationActive(operation: ProgressOperation) {
    this.activeOperations.update((currentIds) => [...currentIds, operation]);
  }

  private setOperationInactive(operation: ProgressOperation) {
    this.activeOperations.update((currentIds) =>
      currentIds.filter(
        (activeOperation) => activeOperation.key != operation.key,
      ),
    );
  }
}

export type ProgressOperationType =
  | 'Loading'
  | 'Saving'
  | 'OtherLoading'
  | 'Other';

interface ProgressOperation {
  key: string;
  operationType?: ProgressOperationType;
}
