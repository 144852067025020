import {
  computed,
  effect,
  Injectable,
  InjectionToken,
  signal,
  untracked,
} from '@angular/core';
import { BaseModel } from '@salary/common/dumb';
import { debounceSignal } from '@salary/common/utils';
import { Subject } from 'rxjs';

@Injectable()
export class MasterDetailInteractionService<T extends BaseModel> {
  public selectedMasterObjects = signal<T[]>(undefined, { equal: () => false });
  public singleSelectedMasterObject = signal<T>(undefined);
  public beforeSelectedDataChanging$ = new Subject<void>();
  public masterlistEmpty = signal(false);
  public masterComponentLoading = signal(true);
  public showEmptyStateDetailComponent = debounceSignal(
    computed(() => {
      const loading = this.masterComponentLoading();
      if (loading) {
        return false;
      }
      return this.singleSelectedMasterObject() == null;
    }),
    (showEmpty) => (showEmpty ? 500 : 0),
  );
  public numberOfSelectedObjects = computed(
    () => this.selectedMasterObjects()?.length,
  );

  constructor() {
    effect(() => {
      const selection = this.selectedMasterObjects()?.[0];
      untracked(() => {
        this.beforeSelectedDataChanging$.next();
        this.singleSelectedMasterObject.set(selection);
      });
    });
  }
}

export const MASTER_DETAIL_INTERACTION_SERVICE = new InjectionToken<
  MasterDetailInteractionService<BaseModel>
>('MASTER_DETAIL_INTERACTION_SERVICE');
