import {
  animate,
  AnimationBuilder,
  AnimationMetadata,
  style,
} from '@angular/animations';
import {
  Directive,
  effect,
  ElementRef,
  inject,
  input,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[salarySearchAnimation]',
})
export class SearchAnimationDirective {
  private input: HTMLElement;
  private icon: HTMLElement;
  private listeners = [];
  searchAnimationEnabled = input<boolean>(true);
  private builder = inject(AnimationBuilder);
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);

  constructor() {
    effect(() => {
      if (this.searchAnimationEnabled()) {
        this.addListeners();
      } else {
        this.removeListeners();
      }
    });
  }

  private addListeners() {
    this.input = this.el.nativeElement.querySelector('input');
    this.renderer.setStyle(this.input, 'min-width', '0px');
    this.icon = this.el.nativeElement.querySelector('mat-icon');
    this.renderer.setAttribute(this.icon, 'tabindex', '-1');
    this.listeners.push(
      this.renderer.listen(this.input, 'focus', () => {
        this.onAnimate();
      }),
    );
    this.listeners.push(
      this.renderer.listen(this.icon, 'click', () => {
        this.onAnimate();
      }),
    );
  }

  private onAnimate() {
    const factory = this.builder.build(this.fadeOut());
    const player = factory.create(this.icon);
    player.play();
    this.input.focus();
    const listener = this.renderer.listen(this.input, 'blur', (event) => {
      if (
        !this.el.nativeElement.contains(event.relatedTarget) &&
        !event.relatedTarget?.closest('.cdk-overlay-container')
      ) {
        listener();
        const factory = this.builder.build(this.fadeIn());
        const player = factory.create(this.icon);
        player.play();
      }
    });
  }

  private fadeOut(): AnimationMetadata[] {
    return [
      style({ 'min-width': '0px' }),
      animate('160ms ease-in', style({ opacity: 0, width: '0px' })),
    ];
  }

  private fadeIn(): AnimationMetadata[] {
    return [animate('160ms ease-in', style({ opacity: 1, width: '24px' }))];
  }

  private removeListeners() {
    this.listeners.forEach((l) => l());
  }

  ngOnDestroy() {
    this.removeListeners();
  }
}
