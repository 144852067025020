import { WritableSignal } from '@angular/core';
import {
  EndpointConfigurationQuery,
  SalaryError,
  filterSalaryError,
  isSalaryError,
} from '@salary/common/api/base-http-service';
import { BasePageModel } from '@salary/common/dumb';
import { StandardFacade } from '@salary/common/standard-facade';
import { INotificationService, mergeDeep } from '@salary/common/utils';
import { EMPTY, Observable, forkJoin, map, of, switchMap } from 'rxjs';

export class ClientModeLoader {
  constructor(
    private facade: StandardFacade<unknown>,
    private notificationService: INotificationService,
    private pageRequestError: WritableSignal<SalaryError>,
  ) {}

  public queryPages(
    endpointConfiguration: EndpointConfigurationQuery,
  ): Observable<unknown[]> {
    const config: EndpointConfigurationQuery = mergeDeep(
      {},
      endpointConfiguration,
      {
        queryParameters: { pageSize: 1000, page: 1 },
      },
    );
    return this.facade
      .queryPage({
        endpointConfiguration: config,
      })
      .pipe(
        switchMap((result) => {
          if (isSalaryError(result)) {
            this.pageRequestError.set(result);
            return EMPTY;
          }
          if (result.pageCount <= 1) {
            return of(result.results);
          } else {
            this.notificationService.show(
              'Es sind sehr viele Daten vorhanden. Die Abfrage kann etwas länger dauern.',
            );
            return this.queryRemainingPages(result, endpointConfiguration);
          }
        }),
      );
  }

  private queryRemainingPages(
    firstResult: BasePageModel<unknown>,
    endpointConfig: EndpointConfigurationQuery,
  ) {
    return forkJoin(
      Array.from({ length: firstResult.pageCount - 1 }).map((_v, index) =>
        this.facade
          .queryPage({
            endpointConfiguration: mergeDeep({}, endpointConfig, {
              queryParameters: { page: index + 2 },
            }),
          })
          .pipe(filterSalaryError()),
      ),
    ).pipe(
      map((results) =>
        results.reduce(
          (acc, cur) => acc.concat(cur.results),
          firstResult.results,
        ),
      ),
    );
  }
}
