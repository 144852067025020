import {
  ChangeDetectionStrategy,
  Component,
  Signal,
  computed,
  inject,
  input,
  output,
  signal,
  untracked,
} from '@angular/core';
import { getFadeInAnimation } from '@salary/common/dumb-components';
import { ConfigService } from '@salary/common/facade';
import { ValidationService } from '../detail-container';
import {
  ToolbarDefinition,
  createTooltipFromDetailResult,
  getValidationToDisplayFromDetailResult,
} from '../utils';
@Component({
  selector: 'salary-detail-header',
  template: `
    <div (salaryOnResize)="handleResize($event)" style="display:flex">
      @let currentValidationState = validationService.validationState();
      <salary-header-container
        style="min-width:0; flex-shrink: 1; flex-grow: 0;"
        [title]="title()"
        [subTitle]="subTitle()"
      />
      @if (currentValidationState !== 'deactivated' && !printMode) {
        <div style="padding: 0 5px 0 5px; display: flex; align-items: center">
          @if (
            validationService.showClosedIcon() ||
            currentValidationState === 'success' ||
            currentValidationState === 'failed'
          ) {
            <button
              [@fadeInAnimation]
              mat-icon-button
              class="large-button"
              [attr.data-testid]="'validation_button'"
              (click)="validationButtonClicked.emit()"
              [matTooltip]="iconTooltipText()"
              color="primary"
            >
              @let badge = badgeContent();
              <mat-icon
                [class.symbol-badge]="currentValidationState !== 'failed'"
                [matBadge]="badge?.text"
                [salaryBadgeColor]="
                  badge?.category === 'error' ? 'warn' : 'primary'
                "
                [class.validation-warning-badge]="badge?.category === 'warning'"
                [class.validation-info-badge]="badge?.category === 'info'"
                >{{ validationIconName() }}</mat-icon
              >
            </button>
          } @else {
            <mat-spinner
              diameter="25"
              style="margin-left:9px"
              data-testid="validation_loading"
              matTooltip="Validierungsergebnisse werden geladen..."
            />
          }
        </div>
      }
      @if (!printMode) {
        <salary-toolbar
          style="flex:auto; max-width: 100%"
          [toolbarDefinitions]="toolbarDefinitions()"
          [parentWidth]="width()"
        />
      }
    </div>
    <mat-progress-bar
      class="progress-bar-position"
      data-testid="detail_loading_bar"
      mode="indeterminate"
      [style.visibility]="showProgressIndicator() ? 'visible' : 'hidden'"
    />
  `,
  styles: `
    .progress-bar-position {
      position: absolute;
      top: 45px;
    }
    .large-button {
      display: flex;
      justify-content: center;
      align-items: center;
      .mat-icon {
        height: 48px !important;
        width: 48px !important;
        font-size: 30px !important;
        line-height: 48px !important;
      }
    }
    .progress-bar-position {
      position: absolute;
      top: 45px;
    }
    .large-button {
      display: flex;
      justify-content: center;
      align-items: center;
      .mat-icon {
        height: 48px !important;
        width: 48px !important;
        font-size: 30px !important;
        line-height: 48px !important;
      }
    }
    :host ::ng-deep mat-icon .mat-badge-content {
      top: 0;
      margin-top: 0 !important;
    }
  `,
  animations: [getFadeInAnimation(400)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailHeaderComponent {
  private readonly VALIDATION_ICON_SUCCESS = 'check_circle';
  private readonly VALIDATION_ICON_ERROR = 'info';
  private readonly CLOSED_ICON = 'lock';
  protected width = signal<number>(undefined);
  protected validationService = inject(ValidationService);
  protected validationIconName = computed(() => {
    const closed = this.validationService.showClosedIcon();
    const state = this.validationService.validationState();
    return untracked(() => {
      if (closed) {
        return this.CLOSED_ICON;
      }
      if (state === 'failed') {
        return this.VALIDATION_ICON_ERROR;
      }
      return this.VALIDATION_ICON_SUCCESS;
    });
  });

  protected badgeContent: Signal<{
    text: number | string;
    category?: string;
  }> = computed(() => {
    const closed = this.validationService.showClosedIcon();
    const validationResults = this.validationService.validationResults();
    const state = this.validationService.validationState();
    return untracked(() => {
      if (state === 'loading') {
        return undefined;
      }
      if (validationResults?.length > 0) {
        return getValidationToDisplayFromDetailResult(validationResults);
      }
      if (closed) {
        return { text: 'check' };
      }
      return undefined;
    });
  });

  protected iconTooltipText = computed(() => {
    const results = this.validationService.validationResults();
    return untracked(() => createTooltipFromDetailResult(results));
  });

  title = input<string>();
  subTitle = input<string>();
  toolbarDefinitions = input<ToolbarDefinition[]>();
  showProgressIndicator = input<boolean>(false);
  validationButtonClicked = output();
  protected printMode = inject(ConfigService).printing.printMode;

  protected handleResize(event: ResizeObserverEntry) {
    this.width.set(event.contentRect.width - 50);
  }
}
