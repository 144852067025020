import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BoolListSubject } from '@salary/common/utils';
import {
  ComponentSublinksInteractionService,
  SubLinkValidChangedArgs,
} from '../detail-container/component-sublinks-interaction.service';

@Injectable()
export class SubNavigationValidationService {
  private readonly subLinksInteractionService = inject(
    ComponentSublinksInteractionService,
  );
  private readonly validStates: { [key: string]: BoolListSubject } = {};

  constructor() {
    this.subLinksInteractionService.componentValidState$
      .pipe(takeUntilDestroyed())
      .subscribe((validState) => this.updateIsValid(validState));
  }

  private updateIsValid(stateChangedArgs: SubLinkValidChangedArgs) {
    const validState$ = this.getValidStateSubject$(
      stateChangedArgs.subLinkName,
    );
    if (stateChangedArgs.valid) {
      validState$.removeKey(stateChangedArgs.componentId);
    } else {
      validState$.nextValue(stateChangedArgs.componentId, false);
    }
  }

  private getValidStateSubject$(subLinkName: string) {
    if (!this.validStates?.[subLinkName]) {
      this.validStates[subLinkName] = new BoolListSubject(true);
    }
    return this.validStates[subLinkName];
  }

  public getValidState$(subLinkName: string) {
    return this.getValidStateSubject$(subLinkName).asObservable();
  }
}
