<div style="display: flex; flex-direction: column">
  @for (i of repeat(); track $index) {
    <div class="shimmer-config-block">
      @for (element of shimmerConfigResult(); track $index) {
        @switch (element) {
          @case ('AvatarWithLines') {
            <div style="display: flex" salaryGap="16px">
              <div class="shimmer-avatar shimmer-shine"></div>
              <div style="display: flex; flex-direction: column">
                <div class="shimmer-large-line shimmer-shine"></div>
                <div class="shimmer-small-line shimmer-shine"></div>
              </div>
            </div>
          }
          @case ('SmallLine') {
            <div class="shimmer-small-line shimmer-shine"></div>
          }
          @case ('LargeLine') {
            <div class="shimmer-large-line shimmer-shine"></div>
          }
        }
      }
    </div>
  }
</div>
