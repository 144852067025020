import {
  DestroyRef,
  Injectable,
  Injector,
  Signal,
  computed,
  inject,
  isSignal,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import {
  SalaryError,
  isSalaryError,
} from '@salary/common/api/base-http-service';
import {
  SettingsCommandService,
  SettingsQueryService,
} from '@salary/common/api/data-services';
import { AuthenticationService } from '@salary/common/authentication';
import {
  NOTIFICATION_SERVICE_TOKEN,
  NotificationSource,
  createCopy,
  parseDateTimeProperties,
  stringifyDateTimeProperties,
  stringifyEquals,
} from '@salary/common/utils';
import {
  catchError,
  combineLatest,
  concat,
  defer,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Setting } from './models/benutzer-settings.model';

@Injectable({ providedIn: 'root' })
export class SettingsFacade {
  private injector = inject(Injector);
  private authenticationService = inject(AuthenticationService);
  private isSettingsLoaded = signal(false);
  private isLocalStorageSettingsLoaded = signal(false);
  private settings = signal<Setting<unknown>[]>([], {
    equal: stringifyEquals,
  });
  private queryService = inject(SettingsQueryService);
  private commandService = inject(SettingsCommandService);
  private notificationService = inject(NOTIFICATION_SERVICE_TOKEN);
  private readonly notificationSource: NotificationSource = {
    name: 'Einstellungen',
    iconName: 'settings',
  };
  private destroyRef = inject(DestroyRef);
  public select = this.createSelectors();

  private createSelectors() {
    const isSettingsLoaded = this.isSettingsLoaded.asReadonly();
    const isLocalStorageSettingsLoaded =
      this.isLocalStorageSettingsLoaded.asReadonly();
    const settings = this.settings.asReadonly();
    return {
      isSettingsLoaded,
      isLocalStorageSettingsLoaded,
      settings,
    };
  }

  public initializeSettings() {
    this.initializeUserIndependentSettings();
    this.initializeUserDependentSettings();
  }

  private initializeUserDependentSettings() {
    this.authenticationService.isAuthenticated$
      .pipe(
        filter(
          (isAuthenticated) =>
            isAuthenticated && !!this.authenticationService.benutzerRolle(),
        ),
        take(1),
        switchMap(() => {
          return combineLatest([
            of(window.localStorage).pipe(
              map((storage) => {
                const settings = storage.getItem(
                  this.authenticationService.benutzer().id,
                );
                return JSON.parse(settings) ?? [];
              }),
            ),
            this.queryService
              .getAllSettings()
              .pipe(map((settings) => settings.results)),
          ]);
        }),
        catchError((error: SalaryError) => of(error)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (isSalaryError(result)) {
          this.notificationService.showError(
            'Laden der Benutzereinstellungen fehlgeschlagen:',
            this.notificationSource,
            result.message,
          );
        } else {
          const allSettings = [...result[0], ...result[1]];
          this.settings.update((s) => s.concat(allSettings));
          this.isSettingsLoaded.set(true);
        }
      });
  }
  private initializeUserIndependentSettings() {
    const settings = window.localStorage.getItem('settings');
    const settingsParsed = JSON.parse(settings);
    this.settings.update((s) => (settingsParsed ?? []).concat(s));
    this.isLocalStorageSettingsLoaded.set(true);
  }

  public createOrUpdateUserSetting<T>(
    setting: Setting<T>,
    location: SettingLocation = 'api',
    successMessage?: string,
    errorMessage?: string,
  ) {
    const settingAsString = {
      ...setting,
      value: JSON.stringify(
        stringifyDateTimeProperties(createCopy(setting.value)),
      ),
    };
    this.authenticationService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1),
        switchMap(() => {
          if (location !== 'api') {
            return toObservable(this.isSettingsLoaded, {
              injector: this.injector,
            }).pipe(
              filter((isLoaded) => isLoaded),
              take(1),
              tap(() => {
                if (
                  this.settings().find((s) => s.key === settingAsString.key)
                ) {
                  this.updateInCache(
                    this.authenticationService.benutzer().id,
                    {
                      key: settingAsString.key,
                      value: settingAsString.value,
                    },
                    location,
                  );
                } else {
                  this.createInCache(
                    this.authenticationService.benutzer().id,
                    {
                      key: settingAsString.key,
                      value: settingAsString.value,
                    },
                    location,
                  );
                }
              }),
            );
          } else {
            return this.commandService
              .create(settingAsString)
              .pipe(catchError((error: SalaryError) => of(error)));
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (isSalaryError(result)) {
          this.notificationService.showError(
            errorMessage ??
              `Benutzereinstellung ${setting?.key} konnte nicht gespeichert werden.`,
            this.notificationSource,
            result.message,
          );
        } else {
          const indexOfSettingToUpdate = this.settings().findIndex(
            (s) => s.key === settingAsString.key,
          );
          let settingsResult = [...this.settings()];
          if (indexOfSettingToUpdate !== -1) {
            settingsResult = settingsResult.map((_s, index) =>
              index === indexOfSettingToUpdate
                ? { ..._s, value: settingAsString.value }
                : _s,
            );
          } else {
            settingsResult = [...settingsResult, settingAsString];
          }
          this.settings.set(settingsResult);
          if (successMessage) {
            this.notificationService.show(successMessage);
          }
        }
      });
  }

  public deleteUserSetting(
    settingKey: string,
    location: SettingLocation,
    successMessage?: string,
    errorMessage?: string,
  ) {
    this.authenticationService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1),
        switchMap(() => {
          if (location !== 'api') {
            return of(
              this.deleteInCache(
                this.authenticationService.benutzer().id,
                settingKey,
                location,
              ),
            );
          } else {
            return this.commandService.deleteByKey(settingKey);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (isSalaryError(result)) {
          this.notificationService.showError(
            errorMessage ??
              `Benutzereinstellung: ${settingKey} konnte nicht gelöscht werden`,
            this.notificationSource,
            result.message,
          );
        } else {
          const settingsIndex = this.settings().findIndex(
            (setting) => setting.key === settingKey,
          );
          if (settingsIndex === -1) {
            return;
          }
          const settingsCopy = [...this.settings()];
          settingsCopy.splice(settingsIndex, 1);
          this.settings.set(settingsCopy);
          this.notificationService.show(successMessage);
        }
      });
  }

  public deleteAllUserSettings() {
    this.authenticationService.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1),
        switchMap(() =>
          concat(
            this.commandService.deleteAll(),
            defer(() =>
              of(
                this.deleteLocalUserStorage(
                  this.authenticationService.benutzer().id,
                ),
              ),
            ),
          ),
        ),
        catchError((error: SalaryError) => of(error)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (isSalaryError(result)) {
          this.notificationService.showError(
            'Einstellungen konnten nicht zurückgesetzt werden',
            this.notificationSource,
            result.message,
          );
        } else {
          this.settings.set([]);
          this.notificationService.show(
            'Alle Einstellungen erfolgreich zurückgesetzt',
          );
        }
      });
  }

  selectBenutzerSettingByPrefix<T>(
    prefix: string | Signal<string>,
  ): Signal<Setting<T>[]> {
    return computed(
      () => {
        const settings = this.select.settings();
        const pre = isSignal(prefix) ? prefix() : prefix;
        return untracked(() => {
          const selectedSettings = settings.filter((setting) =>
            setting.key.startsWith(pre),
          );
          return selectedSettings.map((setting) => {
            return {
              ...setting,
              value: parseDateTimeProperties(
                JSON.parse(setting.value as string),
              ) as T,
            };
          });
        });
      },
      { equal: stringifyEquals },
    );
  }

  selectBenutzerSettingByKey<T>(key: string): Signal<Setting<T>> {
    return computed(
      () => {
        const settings = this.select.settings();
        return untracked(() => {
          const setting = settings.find((setting) => setting.key === key);
          return setting != null
            ? {
                ...setting,
                value: parseDateTimeProperties(
                  JSON.parse(setting.value as string),
                ) as T,
              }
            : undefined;
        });
      },
      { equal: stringifyEquals },
    );
  }

  //#region deleteSetting
  private deleteInCache(
    userId: string,
    settingKey: string,
    location: SettingLocation,
  ) {
    const key = location === 'localStorage' ? 'settings' : userId;
    let settings = JSON.parse(window.localStorage.getItem(key)) ?? [];
    settings = settings.filter((setting) => setting.key !== settingKey);
    window.localStorage.setItem('settings', JSON.stringify(settings));
  }

  private deleteLocalUserStorage(userId: string) {
    window.localStorage.removeItem(userId);
  }

  //#endregion

  //#region createSetting
  private createInCache(
    userId: string,
    value: Setting<string>,
    location: SettingLocation,
  ) {
    const key = location === 'localStorage' ? 'settings' : userId;
    let settings = JSON.parse(window.localStorage.getItem(key));
    if (settings) {
      settings = [...settings, value];
    } else {
      settings = [value];
    }
    window.localStorage.setItem(key, JSON.stringify(settings));
  }

  //#endregion

  //#region updateSetting

  private updateInCache(
    userId: string,
    value: Setting<string>,
    location: SettingLocation,
  ) {
    const key = location === 'localStorage' ? 'settings' : userId;
    let settings = JSON.parse(window.localStorage.getItem(key));
    if (settings) {
      const settingToUpdate = settings.find(
        (setting) => setting.key === value.key,
      );
      if (settingToUpdate) {
        settingToUpdate.value = value.value;
      }
    } else {
      settings = [value];
    }
    window.localStorage.setItem(key, JSON.stringify(settings));
  }

  //#endregion
}

type SettingLocation = 'api' | 'localStorage' | 'localUserStorage';
