import { Injectable, computed, inject } from '@angular/core';
import { TipsService } from '@salary/common/api/data-services';
import { SettingsFacade } from '../settings';

@Injectable({ providedIn: 'root' })
export class TipsFacade {
  static readonly LAST_TIP_SETTINGS_KEY = 'lastTipSeen';
  private settingsFacade = inject(SettingsFacade);
  tips = inject(TipsService).tips;
  lastIdSeen = computed(
    () =>
      this.settingsFacade.selectBenutzerSettingByKey<string>(
        TipsFacade.LAST_TIP_SETTINGS_KEY,
      )()?.value,
  );
  lastIdSeenLoaded = this.settingsFacade.select.isSettingsLoaded;

  updateLastIdSeen(id: string) {
    this.settingsFacade.createOrUpdateUserSetting({
      value: id,
      key: TipsFacade.LAST_TIP_SETTINGS_KEY,
    });
  }
}
