import { computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { KeysOfType } from '@salary/common/dumb';
import {
  ArrayBuilderCore,
  FieldConfig,
  FieldConfigBuilder,
  FormConfigCoreBuilder,
  GroupType,
  LayoutBuilderCore,
} from '@salary/common/formly';
import { mergeDeep } from '@salary/common/utils';
import {
  AccordionChildConfig,
  AccordionFieldConfig,
  ButtonFieldConfig,
  CardFieldConfig,
  CheckboxFieldConfig,
  ChipListFieldConfig,
  ComponentFieldConfig,
  CrudSearchFieldConfig,
  DatepickerFieldConfig,
  ExpandablePanelFieldConfig,
  FxLayoutFieldChildConfig,
  FxLayoutFormlyFieldConfig,
  IBANInputFormlyFieldConfig,
  IdentificationPanelFieldConfig,
  InputFieldConfig,
  LabelFieldConfig,
  RepeatExpandablePanelChildConfig,
  RepeatExpandablePanelFieldConfig,
  RepeatFieldConfig,
  SearchFieldConfigForBuilder,
  SelectableChipListFieldConfig,
  SelectFieldConfig,
  TabGroupChildConfig,
  TabGroupFieldConfig,
  TextareaFieldConfig,
} from '../types';

export class FormConfigBuilder<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MODEL = any,
  PARENTCONFIG extends FieldConfig = unknown,
> extends FormConfigCoreBuilder<MODEL, PARENTCONFIG> {
  //#region simple fields
  public input<K extends keyof MODEL>(
    key: K,
    config?: InputFieldConfig<MODEL> & PARENTCONFIG,
  ) {
    return this.createField(key, config, 'input');
  }

  public textarea<K extends keyof MODEL>(
    key: K,
    config?: TextareaFieldConfig<MODEL> & PARENTCONFIG,
  ) {
    return this.createField(key, config, 'textarea');
  }

  public datepicker<K extends keyof MODEL>(
    key: K,
    config?: DatepickerFieldConfig<MODEL> & PARENTCONFIG,
  ) {
    return this.createField(key, config, 'datepicker');
  }

  public dateRangePicker(
    config: FieldConfig<MODEL, DatepickerFieldConfig<MODEL>> & PARENTCONFIG,
  ): LayoutBuilder<MODEL, DatepickerFieldConfig<MODEL>> {
    return this.layout(null, config, 'dateRangepicker');
  }

  public search<SEARCHMODEL, K extends keyof MODEL = keyof MODEL>(
    key: K,
    config?: SearchFieldConfigForBuilder<MODEL, SEARCHMODEL> & PARENTCONFIG,
  ) {
    return this.createField(key, config, 'search');
  }

  public crudSearch<SEARCHMODEL, K extends keyof MODEL = keyof MODEL>(
    key: K,
    config?: CrudSearchFieldConfig<MODEL, SEARCHMODEL> & PARENTCONFIG,
  ) {
    return this.createField(key, config, 'crudSearch');
  }

  public label<K extends keyof MODEL>(
    key: K,
    config?: LabelFieldConfig<MODEL> & PARENTCONFIG,
  ) {
    return this.createField(key, config, 'label');
  }

  public checkbox<K extends keyof MODEL>(
    key: K,
    config?: CheckboxFieldConfig<MODEL> & PARENTCONFIG,
  ) {
    return this.createField(key, config, 'checkbox');
  }

  public select<K extends keyof MODEL>(
    key: K,
    config?: SelectFieldConfig<MODEL> & PARENTCONFIG,
  ) {
    return this.createField(key, config, 'select');
  }

  public email<K extends keyof MODEL>(
    key: K,
    config?: InputFieldConfig<MODEL> & PARENTCONFIG,
  ) {
    return this.input(
      key,
      mergeDeep(config, {
        maxLength: 200,
        validators: {
          formatMatch: {
            expression: (c) =>
              /^\w+([-_.]\w+)*@\w+([-_.]\w+)*\.\w{2,3}$/.test(c.value) ||
              !c.value,
            message: 'Die eingegebene E-Mail hat kein gültiges Format.',
          },
        },
        iconNameSuffixButton: (field) => {
          const valueChange = toSignal(field.formControl.valueChanges, {
            injector: field._injector,
            initialValue: field.formControl.value,
          });
          return computed(() => {
            const value = valueChange();
            return value?.length > 0 &&
              (field.formControl.valid === true || field.formControl.disabled)
              ? 'mail'
              : undefined;
          });
        },
        suffixButtonClickHandler: (field) => {
          window.location.href = `mailto:${field.formControl.value}`;
        },
        tooltipSuffixButton: 'E-Mail verfassen',
      } as InputFieldConfig),
    );
  }

  public url<K extends keyof MODEL>(
    key: K,
    config?: InputFieldConfig<MODEL> & PARENTCONFIG,
  ) {
    return this.input(
      key,
      mergeDeep(config, {
        maxLength: 2083,
        iconNameSuffixButton: (field) => {
          const valueChange = toSignal(field.formControl.valueChanges, {
            injector: field._injector,
            initialValue: field.formControl.value,
          });
          return computed(() => {
            const value = valueChange();
            return value?.length > 0 &&
              (field.formControl.valid === true || field.formControl.disabled)
              ? 'travel_explore'
              : undefined;
          });
        },
        suffixButtonClickHandler: (field) => {
          window.open(`${field.formControl.value}`, '_blank');
        },
        tooltipSuffixButton: 'Url im Webbrowser öffnen',
      } as InputFieldConfig),
    );
  }

  public telephone<K extends keyof MODEL>(
    key: K,
    config?: InputFieldConfig<MODEL> & PARENTCONFIG,
  ) {
    return this.input(
      key,
      mergeDeep(config, {
        maxLength: 30,
        maskConfig: { mask: /^\+?[^a-z|+]*$/ },
        iconNameSuffixButton: (field) => {
          const valueChange = toSignal(field.formControl.valueChanges, {
            injector: field._injector,
            initialValue: field.formControl.value,
          });
          return computed(() => {
            const value = valueChange();
            return value ? 'call' : undefined;
          });
        },
        suffixButtonClickHandler: (field) => {
          window.location.href = `tel:${field.formControl.value}`;
        },
        tooltipSuffixButton: 'Anrufen',
      }),
    );
  }

  public component<COMPONENT>(
    config?: ComponentFieldConfig<COMPONENT> & PARENTCONFIG,
  ) {
    return this.createField(null, config, 'component');
  }

  public button(config?: ButtonFieldConfig<MODEL> & PARENTCONFIG) {
    return this.createField(null, config, 'button');
  }

  public formlyFieldConfig<CONFIG extends FieldConfig = FieldConfig>(
    fieldConfig: CONFIG[],
  ) {
    const builder = new FieldConfigBuilder(this, fieldConfig);
    this.add(builder);
    return builder;
  }

  public ibanInput<K extends keyof MODEL>(
    key: K,
    config?: IBANInputFormlyFieldConfig<MODEL> & PARENTCONFIG,
  ) {
    return this.createField(key, config, 'ibanInput');
  }

  public bicInput<K extends keyof MODEL>(
    key: K,
    config?: FieldConfig<MODEL> & PARENTCONFIG,
  ) {
    return this.input(
      key,
      mergeDeep(config, {
        maxLength: 11,
        parsers: [
          (bic: string) => (bic === '' ? undefined : bic?.toLocaleUpperCase()),
        ],
      }),
    );
  }

  public chipList<K extends keyof MODEL>(
    key: K,
    config?: ChipListFieldConfig<MODEL> & PARENTCONFIG,
  ) {
    return this.createField(key, config, 'chipList');
  }

  public chipListSelect<K extends keyof MODEL>(
    key: K,
    config?: SelectableChipListFieldConfig<MODEL> & PARENTCONFIG,
  ) {
    return this.createField(key, config, 'chipListSelect');
  }

  //#endregion

  //#region groupTypes

  // #region group

  public group(): LayoutBuilder<MODEL, PARENTCONFIG>;
  public group<K extends keyof MODEL>(
    key: K,
  ): LayoutBuilder<MODEL[K], PARENTCONFIG>;
  public group(
    config: GroupType<FieldConfig<MODEL, PARENTCONFIG>, PARENTCONFIG>,
  ): LayoutBuilder<MODEL>;
  public group<K extends keyof MODEL>(
    key: K,
    config: GroupType<FieldConfig<MODEL[K], PARENTCONFIG>, PARENTCONFIG>,
  ): LayoutBuilder<MODEL[K]>;
  public group(keyOrConfig?, config?): unknown {
    return this.layout(keyOrConfig, config, undefined);
  }
  //#endregion

  //#region tabGroup

  public tabGroup(): LayoutBuilder<MODEL, TabGroupChildConfig<MODEL>>;
  public tabGroup<K extends keyof MODEL>(
    key: K,
  ): LayoutBuilder<MODEL[K], TabGroupChildConfig<MODEL>>;
  public tabGroup(
    config: GroupType<TabGroupFieldConfig<MODEL, PARENTCONFIG>, PARENTCONFIG>,
  ): LayoutBuilder<MODEL, TabGroupChildConfig>;
  public tabGroup<K extends keyof MODEL>(
    key: K,
    config: GroupType<
      TabGroupFieldConfig<MODEL[K], PARENTCONFIG>,
      PARENTCONFIG
    >,
  ): LayoutBuilder<MODEL[K], TabGroupChildConfig<MODEL[K]>>;

  public tabGroup(keyOrConfig?, config?): unknown {
    return this.layout(keyOrConfig, config, 'tabgroup');
  }
  //#endregion

  //#region fxLayout
  public fxLayout(): LayoutBuilder<MODEL, FxLayoutFieldChildConfig>;
  public fxLayout<K extends keyof MODEL>(
    key: K,
  ): LayoutBuilder<MODEL[K], FxLayoutFieldChildConfig<MODEL>>;
  public fxLayout(
    config: GroupType<
      FxLayoutFormlyFieldConfig<MODEL, FxLayoutFieldChildConfig<MODEL>>,
      PARENTCONFIG
    >,
  ): LayoutBuilder<MODEL, FxLayoutFieldChildConfig>;
  public fxLayout<K extends keyof MODEL>(
    key: K,
    config: GroupType<
      FxLayoutFormlyFieldConfig<MODEL[K], FxLayoutFieldChildConfig<MODEL[K]>>,
      PARENTCONFIG
    >,
  ): LayoutBuilder<MODEL[K], FxLayoutFieldChildConfig<MODEL>>;
  public fxLayout(keyOrConfig?, config?): unknown {
    return this.layout(keyOrConfig, config, 'fxLayout');
  }

  //#endregion

  //#region card
  public card(): LayoutBuilder<MODEL>;
  public card<K extends keyof MODEL>(key: K): LayoutBuilder<MODEL[K]>;
  public card(
    config: GroupType<CardFieldConfig<MODEL>, PARENTCONFIG>,
  ): LayoutBuilder<MODEL>;
  public card<K extends keyof MODEL>(
    key: K,
    config: GroupType<CardFieldConfig<MODEL[K]>, PARENTCONFIG>,
  ): LayoutBuilder<MODEL[K]>;

  public card(keyOrConfig?, config?): unknown {
    return this.layout(keyOrConfig, config, 'card');
  }
  //#endregion

  //#endregion

  //#region fieldArrayTypes

  /** use this for aggregate part lists with a custom key */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public list<ITEMMODEL = any>(
    key: string,
    config?: GroupType<
      RepeatExpandablePanelFieldConfig<
        ITEMMODEL[],
        RepeatExpandablePanelChildConfig
      >,
      PARENTCONFIG
    >,
    arrayconfig?: RepeatExpandablePanelChildConfig<ITEMMODEL>,
  ): ArrayBuilder<ITEMMODEL, RepeatExpandablePanelChildConfig<ITEMMODEL>> {
    return this.array(key, 'repeatPanel', config, arrayconfig);
  }

  /** use this for valueObject lists */
  public nestedList<
    K extends KeysOfType<MODEL, unknown[]>,
    ITEMMODEL = MODEL[K] extends (infer U)[] ? U : never,
  >(
    key: K,
    config?: GroupType<
      RepeatExpandablePanelFieldConfig<
        ITEMMODEL[],
        RepeatExpandablePanelChildConfig
      >,
      PARENTCONFIG
    >,
    arrayconfig?: RepeatExpandablePanelChildConfig<ITEMMODEL>,
  ): ArrayBuilder<ITEMMODEL, RepeatExpandablePanelChildConfig<ITEMMODEL>> {
    return this.array(key, 'repeatPanel', config, arrayconfig);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public repeat<ITEMMODEL = any>(
    key: string,
    config?: GroupType<
      RepeatFieldConfig<ITEMMODEL[], RepeatFieldConfig>,
      PARENTCONFIG
    >,
  ): ArrayBuilder<ITEMMODEL, RepeatFieldConfig>;

  public repeat<
    K extends KeysOfType<MODEL, unknown[]>,
    ITEMMODEL = MODEL[K] extends (infer U)[] ? U : never,
  >(
    key: K,
    config?: GroupType<RepeatFieldConfig<ITEMMODEL[]>, PARENTCONFIG>,
  ): ArrayBuilder<ITEMMODEL, unknown> {
    return this.array(key, 'repeat', config, {});
  }

  //#endregion

  //#region expandablePanel
  public expandablePanel(): LayoutBuilder<MODEL>;
  public expandablePanel<K extends keyof MODEL>(
    key: K,
  ): LayoutBuilder<MODEL[K]>;
  public expandablePanel(
    config: GroupType<ExpandablePanelFieldConfig<MODEL>, PARENTCONFIG>,
  ): LayoutBuilder<MODEL>;

  public expandablePanel<K extends keyof MODEL>(
    key: K,
    config: GroupType<ExpandablePanelFieldConfig<MODEL[K]>, PARENTCONFIG>,
  ): LayoutBuilder<MODEL[K]>;

  public expandablePanel(keyOrConfig?, config?): unknown {
    return this.layout(keyOrConfig, config, 'expandablePanel');
  }
  //#endregion

  //#region identificationPanel
  public identificationPanel(
    config: GroupType<IdentificationPanelFieldConfig<MODEL>, PARENTCONFIG>,
  ): LayoutBuilder<MODEL>;

  public identificationPanel(): LayoutBuilder<MODEL>;

  public identificationPanel(config?): unknown {
    return this.layout(undefined, config, 'identificationPanel');
  }
  //#endregion

  //#region accordion

  public accordion(): LayoutBuilder<MODEL, AccordionChildConfig>;
  public accordion<K extends keyof MODEL>(
    key: K,
  ): LayoutBuilder<MODEL[K], AccordionChildConfig>;
  public accordion(
    config: GroupType<AccordionFieldConfig<MODEL>, PARENTCONFIG>,
  ): LayoutBuilder<MODEL, AccordionChildConfig>;
  public accordion<K extends keyof MODEL>(
    key: K,
    config: GroupType<AccordionFieldConfig<MODEL[K]>, PARENTCONFIG>,
  ): LayoutBuilder<MODEL[K], AccordionChildConfig>;

  public accordion(keyOrConfig?, config?): unknown {
    return this.layout(keyOrConfig, config, 'accordion');
  }

  //#endregion
}

type LayoutBuilder<T = unknown, C = unknown> = LayoutBuilderCore<
  T,
  C,
  FormConfigBuilder<T, C>
>;

type ArrayBuilder<T = unknown, C = unknown> = ArrayBuilderCore<
  T,
  C,
  FormConfigBuilder<T, C>
>;
