import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  signal,
  untracked,
  viewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatInput } from '@angular/material/input';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import {
  GlobalSearchAbrechnungskreiseQueryService,
  GlobalSearchPersonalQueryService,
} from '@salary/common/api/data-services';
import { BaseModel, BasePageModel } from '@salary/common/dumb';
import { debounceSignal, integerFormatter } from '@salary/common/utils';
import { catchError, of, Unsubscribable } from 'rxjs';
import { GlobalSearchGroup } from './global-search.model';

@Component({
  selector: 'salary-global-search',
  templateUrl: './global-search.component.html',
  styleUrl: './global-search.component.scss',
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'global-search-group-names-panel' },
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlobalSearchComponent {
  private destroyRef = inject(DestroyRef);
  protected readonly globalSearchGroups: GlobalSearchGroup[] = [
    {
      name: 'Abrechnungskreise',
      tooltip: `Abrechnungskreise durchsuchen nach:
        Nummer,
        Bezeichnung,
        Beschäftigungsbetriebnummer,
        Beschäftigungsbetriebname,
        Sozialkassennummer`,
      queryService: inject(GlobalSearchAbrechnungskreiseQueryService),
      orderBy: 'nummer',
    },
    {
      name: 'Personal',
      tooltip: `Personal durchsuchen nach:
        Personalnummer,
        Vorname,
        Nachname,
        Steueridentifikationsnummer,
        Sozialversicherungsnummer,
        AN-ZVK-Nummer`,
      queryService: inject(GlobalSearchPersonalQueryService),
      orderBy: 'personalnummer',
    },
  ];
  private isLoading = signal<boolean>(false);
  private currentPageModel = signal<BasePageModel<BaseModel>>(undefined);
  private triggerLoadMore = signal<void>(undefined, { equal: () => false });
  private searchResults = signal<BaseModel[]>([]);
  protected selectedGroup = signal(this.globalSearchGroups[0]);
  protected userInput = signal<string>(undefined);
  protected userInputDebounced = debounceSignal(this.userInput, 200);
  protected isLoadingMoreItems = signal<boolean>(false);
  protected currentPageModelText = computed(
    () =>
      `${integerFormatter.format(
        this.currentPageModel()?.lastRowOnPage,
      )} von ${integerFormatter.format(this.currentPageModel()?.rowCount)} geladen`,
  );
  protected moreItemsExists = computed(
    () =>
      this.currentPageModel()?.pageCount > this.currentPageModel()?.currentPage,
  );
  private inputEmpty = computed(() => !this.userInput());
  private emptyResults = computed(() => this.searchResults().length === 0);

  private matInput = viewChild(MatInput);
  private matInputElement = viewChild(MatInput, { read: ElementRef });
  private focusMonitor = inject(FocusMonitor);

  protected viewState = debounceSignal(
    computed(() => {
      if (this.inputEmpty()) {
        return 'INFO';
      }
      if (this.isLoading()) {
        return 'LOADING';
      }
      if (!this.emptyResults()) {
        return this.selectedGroup().name === 'Abrechnungskreise'
          ? 'RESULTS_ABRECHNUNGSKREISE'
          : 'RESULTS_PERSONAL';
      }
      return 'EMPTY_RESULTS';
    }),
    300,
  );

  protected searchResultsDebounced = debounceSignal(this.searchResults, () =>
    (this.selectedGroup().name === 'Abrechnungskreise' &&
      this.viewState() === 'RESULTS_ABRECHNUNGSKREISE') ||
    (this.selectedGroup().name === 'Personal' &&
      this.viewState() === 'RESULTS_PERSONAL')
      ? 0
      : 300,
  );

  constructor() {
    effect(() => {
      this.userInput();
      untracked(() => this.isLoadingMoreItems.set(false));
    });
    let querySubscription: Unsubscribable;
    effect(() => {
      const searchTerm = this.userInputDebounced();
      const selectedGroup = this.selectedGroup();
      this.triggerLoadMore();
      untracked(() => {
        const isLoadingMoreItems = this.isLoadingMoreItems();
        querySubscription?.unsubscribe();
        if (!searchTerm) {
          this.isLoading.set(false);
          this.searchResults.set([]);
          return;
        }
        if (!isLoadingMoreItems) {
          this.isLoading.set(true);
        }
        querySubscription = selectedGroup.queryService
          .getPerPage({
            queryParameters: {
              page: !isLoadingMoreItems
                ? 1
                : this.currentPageModel()?.currentPage + 1,
              searchString: searchTerm,
              orderBy: selectedGroup.orderBy,
              direction: 'asc',
            },
          })
          .pipe(
            catchError(() => of(undefined)),
            takeUntilDestroyed(this.destroyRef),
          )
          .subscribe((result) => {
            if (isLoadingMoreItems) {
              this.searchResults.update((prevResults) =>
                prevResults.concat(result?.results ?? []),
              );
            } else {
              this.searchResults.set(result?.results ?? []);
            }
            this.currentPageModel.set(result);
            this.isLoadingMoreItems.set(false);
            this.isLoading.set(false);
          });
      });
    });
  }

  protected onMoreItemsButtonClick() {
    this.isLoadingMoreItems.set(true);
    this.matInputElement()?.nativeElement?.focus();
    this.triggerLoadMore.set();
  }

  protected onClearButtonClick() {
    this.matInput().value = '';
    this.userInput.set('');
    this.currentPageModel.set(undefined);
    this.focusMonitor.focusVia(this.matInputElement().nativeElement, 'program');
  }
}
