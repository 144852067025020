import { Injectable, computed, inject, untracked } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { TermineQueryService } from '@salary/common/api/data-services';
import { AppSettingsFacade } from '@salary/common/facade';
import { DateTime } from 'luxon';
import { filter, shareReplay, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TermineService {
  private queryService = inject(TermineQueryService);
  private appSettingsFacade = inject(AppSettingsFacade);
  public termine$ = toObservable(this.appSettingsFacade.select.all).pipe(
    filter((settings) => settings.display?.includes('termineAnzeigen')),
    switchMap(() =>
      this.queryService.getPerPage({
        queryParameters: {
          datumVon: DateTime.now(),
          datumBis: DateTime.now().plus({ weeks: 4 }),
        },
      }),
    ),
    shareReplay(1),
  );
  private termine = toSignal(this.termine$);
  public showTermine = computed(() => {
    const settings = this.appSettingsFacade.select.all();
    const termine = this.termine();
    return untracked(() => {
      return (
        settings.display?.includes('termineAnzeigen') &&
        termine?.results?.length > 0
      );
    });
  });
}
