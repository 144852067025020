import { InjectionToken, isSignal } from '@angular/core';
import { MatAutocompleteOrigin } from '@angular/material/autocomplete';
import { DateTime } from 'luxon';
import { isObservable } from 'rxjs';

function isObjectToMerge(item: unknown) {
  return (
    item &&
    typeof item === 'object' &&
    !Array.isArray(item) &&
    !DateTime.isDateTime(item) &&
    !isObservable(item) &&
    !(item instanceof InjectionToken) &&
    !(item instanceof RegExp) &&
    !isSignal(item) &&
    !(item instanceof MatAutocompleteOrigin)
  );
}

/**
 * merges the given {@link sources} into the given {@link target}
 * @example
 * mergeDeep(
 *   { prop1: 1, prop2: { prop1: 1 } },
 *   { prop2: { prop2: 2 } },
 *   { prop3: 3 }
 * ) =>
 * { prop1: 1, prop2: { prop1: 1, prop2: 2 }, prop3: 3 }
 */
export function mergeDeep<S, T, U, V>(
  target: S,
  source1: T,
  source2?: U,
  source3?: V,
): S & T & U & V {
  return mergeDeepCore(target, [source1, source2, source3] as object[]);
}

export function mergeDeepWithoutOverride<S, T, U, V>(
  target: S,
  source1: T,
  source2?: U,
  source3?: V,
): S & T & U & V {
  return mergeDeepCore(target, [source1, source2, source3] as object[], false);
}

function mergeDeepCore(target, sources: object[], override = true) {
  if (target == null) {
    target = {};
  }
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObjectToMerge(target) && isObjectToMerge(source)) {
    for (const key in source) {
      if (isObjectToMerge(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeepCore(target[key], [source[key]], override);
      } else {
        if (override || target[key] == null) {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }
  }
  return mergeDeepCore(target, sources, override);
}
