import { FocusMonitor } from '@angular/cdk/a11y';
import { ElementRef, Injectable, inject } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { FieldConfig, convertToSignal } from '@salary/common/formly';
import { LogService } from '@salary/common/logger';
import {
  EMPTY,
  Observable,
  Subject,
  catchError,
  concat,
  concatMap,
  defer,
  delayWhen,
  from,
  map,
  of,
  retry,
  shareReplay,
  takeLast,
  timer,
} from 'rxjs';
import { BaseComponent } from '..';
import {
  isExpandablePanelField,
  isGroupField,
  isIdentificationPanelField,
  isRepeatPanelField,
  isSearchField,
} from '../../salary-formly';
import { SUB_NAVIGATION_PATH } from '../../sub-navigation/sub-navigation-path.token';
import { ComponentInteractionService } from '../component-interaction.service';

@Injectable()
export class FocusFieldService {
  private static readonly STEPDELAY = 350;
  focusing = new Subject<void>();
  // will be updated during process of finding field in components
  private currentFieldPath = '';
  private objectId: string;
  private componentInteractionService = inject(ComponentInteractionService);
  private focusMonitor = inject(FocusMonitor);
  private logger = inject(LogService);

  /**
   * set the focus to the specified field
   * @param fieldPath path of field to focus (i.e. einUndAustritt.eintrittsdatum, verguetungen.1.stundensatzGehalt)
   * @returns fieldPath which can be differ from given fieldPath (i.e. modelMapping of search fields)
   */
  public focus(fieldPath: string, objectId?: string): Observable<string> {
    return this.focusCore(fieldPath, objectId);
  }

  public focusByFieldConfig(fieldConfig) {
    return this.focusCore(undefined, undefined, fieldConfig);
  }

  private focusCore(
    fieldPath: string,
    objectId?: string,
    fieldConfig?,
  ): Observable<string> {
    this.focusing.next();
    this.currentFieldPath = fieldPath;
    this.objectId = objectId;
    return concat([
      defer(() => of(this.openSubNavigationLink(fieldConfig))),
      defer(() => this.makeFieldVisible(fieldConfig)),
      defer(() => of(this.focusField(fieldConfig))),
    ]).pipe(
      concatMap((obs) => obs),
      delayWhen((delayNecessary) =>
        timer(delayNecessary ? FocusFieldService.STEPDELAY : 0),
      ),
      takeLast(1),
      retry({ count: 5, delay: 1000 }),
      map(() => this.currentFieldPath),
      catchError((err) => {
        this.logger.error(err);
        return EMPTY;
      }),
      shareReplay(1),
    );
  }

  getFormlyField(fieldPath: string): FieldConfig {
    const targetComponent = this.findComponent(fieldPath);
    if (!targetComponent) {
      throw new Error(
        `FocusFieldService: can not find component with field "${fieldPath}".`,
      );
    }
    const formControl = this.findWithAlternatives(
      fieldPath,
      targetComponent.form,
    );
    return this.getFormlyFieldOfControl(formControl);
  }

  private getFormlyFieldOfControl(formControl: AbstractControl): FieldConfig {
    return formControl['_fields']?.find((f) => !convertToSignal('hide', f)());
  }

  private openSubNavigationLink(fieldConfig: FieldConfig = null) {
    const targetComponent = this.findComponent(
      this.currentFieldPath,
      fieldConfig,
    );
    if (!targetComponent) {
      throw new Error(
        `FocusFieldService: can not find component with field "${this.currentFieldPath}".`,
      );
    }
    const rootManager =
      this.componentInteractionService.getRootRequestManager(targetComponent);
    if (rootManager.rootComponent === targetComponent) return false;
    const subNavigationName = this.findSubNavigationLink(targetComponent);
    return this.activateSubNavigationLink(
      subNavigationName,
      rootManager.rootComponent,
    );
  }

  private findSubNavigationLink(targetComponent: BaseComponent): string {
    return targetComponent.injector.get(SUB_NAVIGATION_PATH, '', {
      optional: true,
    });
  }

  private activateSubNavigationLink(
    subNavigationName: string,
    rootComponent: BaseComponent,
  ) {
    const rootComponentElement: HTMLElement =
      rootComponent.injector.get(ElementRef).nativeElement;
    const subLinkElement = rootComponentElement.querySelector<HTMLElement>(
      `salary-sub-navigation a[href$="#${subNavigationName}"]`,
    );
    if (!subLinkElement) {
      throw new Error(
        `FocusFieldService: can not find subNavigationLink element "${subNavigationName}"`,
      );
    }
    if (subLinkElement.hasAttribute('activeSubLink')) return false;
    subLinkElement.click();
    return true;
  }

  private findComponent(
    fieldPath: string,
    fieldConfig?: FieldConfig,
  ): BaseComponent {
    if (fieldConfig) {
      return fieldConfig.options?.formState?.component;
    }
    const activeRootComponents =
      this.componentInteractionService.registeredRootRequestManagers.filter(
        (root) => !root.markedForDestroy,
      );
    if (!activeRootComponents?.length) {
      throw new Error(
        `FocusFieldService: no active root component found. Can not focus property "${fieldPath}".`,
      );
    }
    if (activeRootComponents.length !== 1) {
      throw new Error(
        `FocusFieldService: multiple active root components found. Try find property "${fieldPath}" of first one.`,
      );
    }
    return activeRootComponents[0].allComponents.find((component) =>
      this.isFieldInComponent(fieldPath, component),
    );
  }

  private isFieldInComponent(
    fieldPath: string,
    component: BaseComponent,
  ): boolean {
    const formControl = this.findWithAlternatives(fieldPath, component.form);
    return formControl != null;
  }

  private findWithAlternatives(
    fieldPath: string,
    formControl: AbstractControl,
  ) {
    if (!fieldPath || !formControl) return undefined;
    const dotIndex = fieldPath.indexOf('.');
    const isPathNested = dotIndex !== -1;
    const firstPartOfPath = isPathNested
      ? fieldPath.slice(0, dotIndex)
      : fieldPath;
    const restOfPath = isPathNested ? fieldPath.slice(dotIndex + 1) : undefined;
    if (formControl instanceof FormGroup) {
      const control = Object.entries(formControl.controls).find(
        ([key, value]) =>
          this.isFormControlMatching(key, firstPartOfPath, value),
      )?.[1];
      if (control) {
        return !isPathNested
          ? control
          : this.findWithAlternatives(restOfPath, control);
      }
      return this.findInFormArray(formControl, fieldPath);
    }
    return undefined;
  }

  private findInFormArray(formControl: FormGroup, fieldPath: string) {
    for (const [, control] of Object.entries(formControl.controls)) {
      if (!(control instanceof FormArray)) {
        continue;
      }
      for (const [, arrayControl] of Object.entries(control.controls)) {
        const result = this.findWithAlternatives(fieldPath, arrayControl);
        if (result != null) {
          return result;
        }
      }
    }
    return undefined;
  }

  private isFormControlMatching(
    nameOfControl: string,
    fieldPath: string,
    control: AbstractControl,
  ) {
    const formlyFieldConfig = this.getFormlyFieldOfControl(control);
    if (
      this.objectId != null &&
      this.getModelId(formlyFieldConfig) !== this.objectId
    ) {
      return false;
    }

    if (nameOfControl === fieldPath) {
      return true;
    }
    if (
      isSearchField(formlyFieldConfig) &&
      formlyFieldConfig.modelMapping?.find(
        (mapping) => mapping[1] === fieldPath,
      )
    ) {
      this.updateCurrentFieldPath(nameOfControl);
      return true;
    }
    return false;
  }

  private getModelId(fieldConfig: FieldConfig) {
    if (!fieldConfig) {
      return null;
    }
    return fieldConfig.model()?.id ?? this.getModelId(fieldConfig.parent);
  }

  private updateCurrentFieldPath(updatedFieldName: string) {
    const indexOfLastDot = this.currentFieldPath.lastIndexOf('.');
    if (indexOfLastDot === -1) {
      this.currentFieldPath = updatedFieldName;
      return;
    }
    this.currentFieldPath =
      this.currentFieldPath.slice(0, indexOfLastDot) + '.' + updatedFieldName;
  }

  private makeFieldVisible(fieldConfig?: FieldConfig) {
    const targetComponent = this.findComponent(
      this.currentFieldPath,
      fieldConfig,
    );
    fieldConfig ??= this.getFormlyField(this.currentFieldPath);

    const parentFieldsToActivate = this.getParentFieldHierarchy(
      fieldConfig,
      targetComponent,
    ).reverse();
    return from(parentFieldsToActivate).pipe(
      concatMap((element) =>
        of(element).pipe(
          map((fieldInfo) => {
            if (element.activationHandler) {
              element.activationHandler();
              return true;
            }
            let tabClickResult = false;
            if (this.isTabPage(fieldInfo.field)) {
              tabClickResult = this.clickTabPage(
                fieldInfo.targetComponentElement,
                fieldInfo.field,
              );
            }
            if (this.isSomeKindOfExpandable(fieldInfo.field)) {
              const headerToClick =
                fieldInfo.targetComponentElement.querySelector<HTMLElement>(
                  `#${fieldInfo.field.id} .mat-expansion-panel-header[aria-expanded="false"]`,
                );
              if (headerToClick) {
                headerToClick.click();
                return true;
              } else return tabClickResult;
            }
            return false;
          }),
          delayWhen((delayNecessary) =>
            timer(delayNecessary ? FocusFieldService.STEPDELAY : 0),
          ),
        ),
      ),
    );
  }

  clickTabPage(targetComponentElement: HTMLElement, field: FieldConfig) {
    const tabLabelToClick = targetComponentElement.querySelector<HTMLElement>(
      `.mat-mdc-tab[aria-selected="false"] #${field.id}`,
    );
    if (tabLabelToClick) {
      tabLabelToClick.click();
      return true;
    }
    return false;
  }

  private getParentFieldHierarchy(
    formlyField: FieldConfig,
    targetComponent: BaseComponent,
  ): ActivationPathEntry[] {
    let parentFieldsToActivate: ActivationPathEntry[] = [];
    let field = formlyField;
    const targetComponentElement: HTMLElement =
      targetComponent.injector.get(ElementRef).nativeElement;
    while (field) {
      if (this.isSomeKindOfExpandable(field) || this.isTabPage(field)) {
        parentFieldsToActivate.push({
          field: field,
          targetComponentElement: targetComponentElement,
        });
      }
      field = field.parent;
    }
    if (!field) {
      if (targetComponent.componentTypeParent) {
        parentFieldsToActivate = parentFieldsToActivate.concat(
          this.getParentFieldHierarchy(
            targetComponent.componentTypeParent.formly,
            targetComponent.componentTypeParent.component,
          ),
        );
      }
    }
    const activationHandler = targetComponent['focusActivationHandler'];
    if (activationHandler) {
      activationHandler.bind(targetComponent)(
        formlyField,
        parentFieldsToActivate,
      );
    }
    return parentFieldsToActivate;
  }

  private isSomeKindOfExpandable(field: FieldConfig) {
    return (
      isExpandablePanelField(field) ||
      isIdentificationPanelField(field) ||
      (isGroupField(field) && isRepeatPanelField(field.parent))
    );
  }

  private isTabPage(field: FieldConfig) {
    return field['tabTitle'] != null;
  }

  private focusField(fieldConfig?: FieldConfig) {
    fieldConfig ??= this.getFormlyField(this.currentFieldPath);
    const targetComponent = this.findComponent(
      this.currentFieldPath,
      fieldConfig,
    );
    const targetComponentElement: HTMLElement =
      targetComponent.injector.get(ElementRef).nativeElement;

    if (this.isTabPage(fieldConfig)) {
      return this.clickTabPage(targetComponentElement, fieldConfig);
    }

    const fieldElement = this.getFieldElement(
      fieldConfig,
      targetComponentElement,
    );
    if (!fieldElement) return false;
    this.focusMonitor.focusVia(fieldElement, 'keyboard');
    if (fieldElement.ariaHasPopup) {
      setTimeout(() =>
        fieldElement.dispatchEvent(
          new PointerEvent('click', {
            bubbles: true,
            cancelable: true,
          }),
        ),
      );
    }
    return true;
  }

  private getFieldElement(
    formlyField,
    targetComponentElement: HTMLElement,
  ): HTMLElement {
    let fieldElement = targetComponentElement.querySelector<HTMLElement>(
      `#${formlyField.id}`,
    );
    if (!fieldElement) return undefined;
    if (
      fieldElement.tagName !== 'INPUT' &&
      fieldElement.tagName !== 'TEXTAREA' &&
      !fieldElement.tagName.includes('SELECT')
    ) {
      fieldElement = targetComponentElement.querySelector<HTMLElement>(
        `#${formlyField.id} input`,
      );
    }
    return fieldElement;
  }
}

export interface ActivationPathEntry {
  field: FieldConfig;
  targetComponentElement?: HTMLElement;
  activationHandler?: () => void;
}
