import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Guid } from '@salary/common/dumb';
import {
  INotificationService,
  NotificationSource,
  UINotification,
} from '@salary/common/utils';
import {
  EMPTY,
  Observable,
  ReplaySubject,
  Subject,
  concatMap,
  filter,
  map,
  take,
} from 'rxjs';
import {
  NotificationSnackBarComponent,
  NotificationSnackBarData,
} from '../components';
@Injectable({ providedIn: 'root' })
export class NotificationService implements INotificationService {
  private readonly matSnackBar = inject(MatSnackBar);
  private readonly registerNotificationToShow$ = new Subject<{
    id: string;
    show: () => Observable<void>;
  }>();
  private readonly notificationShown$ = new ReplaySubject<string>(2);
  private readonly _notifications$ = new Subject<UINotification>();
  readonly notifications$ = this._notifications$.asObservable();

  constructor() {
    this.registerNotificationToShow$
      .pipe(
        concatMap((request) => request.show().pipe(map(() => request.id))),
        takeUntilDestroyed(),
      )
      .subscribe((id) => this.notificationShown$.next(id));
  }

  show(
    message: string,
    options?: { duration?: number; classname?: string; action?: string },
  ) {
    if (!message) {
      return EMPTY;
    }
    const notificationId = Guid.create();
    this.registerNotificationToShow$.next({
      id: notificationId,
      show: () =>
        this.showWithComponent({
          message,
          action: options?.action,
          closeButton: false,
          classname: options?.classname,
          duration: options?.duration,
        }),
    });
    return this.notificationShown$.pipe(
      filter((id) => id === notificationId),
      map(() => undefined),
      take(1),
    );
  }

  showError(
    message: string,
    source: NotificationSource,
    additionalMessage?: string,
  ) {
    const notificationId = Guid.create();
    additionalMessage = additionalMessage?.replace(message, '');
    additionalMessage = additionalMessage?.replace('ValidationError', '');
    this._notifications$.next({
      message,
      additionalMessage,
      source,
    });
    this.registerNotificationToShow$.next({
      id: notificationId,
      show: () => {
        return this.showWithComponent({
          message,
          additionalMessage,
          icon: 'error',
          closeButton: true,
          duration: 10000,
          expanded: true,
          classname: ['red-snackbar', 'break-all-message'],
        });
      },
    });
    return this.notificationShown$.pipe(
      filter((id) => id === notificationId),
      map(() => undefined),
      take(1),
    );
  }

  private showWithComponent(config: NotificationSnackBarData) {
    config.duration ??= 3000;
    return this.matSnackBar
      .openFromComponent<
        NotificationSnackBarComponent,
        NotificationSnackBarData
      >(NotificationSnackBarComponent, {
        data: config,
        panelClass: config.classname,
      })
      .onAction();
  }
}
