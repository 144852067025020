import { caption, euroField, fieldId, textField } from '../../../decorators';

export class PersonalOrganisation {
  @caption('Kürzel')
  @fieldId('e0219e')
  kuerzel?: string;
  @textField({ format: '{abteilung} - {abteilungBezeichnung}' })
  @fieldId('bbfded')
  @caption('Abteilung')
  abteilungId?: string;
  abteilung?: string;
  abteilungBezeichnung?: string;
  @textField({
    format: '{kugAbteilungNummer} - {kugAbteilungBezeichnung}',
  })
  @caption('KUG-Abteilung')
  @fieldId('86f18a')
  kugAbteilungId?: string;
  @fieldId('054b21')
  kugAbteilungNummer?: string;
  @fieldId('e3bbf4')
  kugAbteilungBezeichnung?: string;
  @textField({
    format: '{stammkostenstelleNummer} - {stammkostenstelleBezeichnung}',
    routerLink: '/masterdata/kostenstellen/{stammkostenstelleId}',
  })
  @caption('Stammkostenstelle')
  @fieldId('69388f')
  stammkostenstelleId?: string;
  stammkostenstelleNummer?: string;
  stammkostenstelleBezeichnung?: string;
  @textField({
    format: '{sozialkostenstelleNummer} - {sozialkostenstelleBezeichnung}',
    routerLink: '/masterdata/kostenstellen/{sozialkostenstelleId}',
  })
  @caption('Sozialkostenstelle')
  @fieldId('09da3e')
  sozialkostenstelleId?: string;
  sozialkostenstelleNummer?: string;
  sozialkostenstelleBezeichnung?: string;
  @textField({
    format: '{verbuchungskennzeichen} - {verbuchungskennzeichenBezeichnung}',
  })
  @fieldId('22442e')
  verbuchungskennzeichen?: number;
  verbuchungskennzeichenBezeichnung?: string;
  verbuchungskennzeichenId?: string;
  @euroField({ min: 0, max: 9999.99 })
  @fieldId('270945')
  internerVerrechnungssatz?: number;
  @textField({ format: '{berufsgruppe} - {berufsgruppeBezeichnung}' })
  @fieldId('6e5e77')
  @caption('Berufsgruppe')
  berufsgruppeId?: string;
  berufsgruppe?: string;
  berufsgruppeBezeichnung?: string;
}
