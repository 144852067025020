import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Tip } from '@salary/common/dumb';
import { catchError, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TipsService {
  public readonly tips = toSignal(
    inject(HttpClient)
      .get<Tip[]>('/assets/tips/tips.json')
      .pipe(catchError(() => of<Tip[]>([]))),
    { initialValue: [] },
  );
}
