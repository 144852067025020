import {
  computed,
  DestroyRef,
  effect,
  inject,
  Injectable,
  Signal,
  signal,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  isSalaryError,
  SalaryError,
} from '@salary/common/api/base-http-service';
import { LohnkontextAbrechnungenQueryService } from '@salary/common/api/data-services';
import { Abrechnung, AbrechnungsStatus } from '@salary/common/dumb';
import { updateAndInsertItems } from '@salary/common/standard-facade';
import { NOTIFICATION_SERVICE_TOKEN } from '@salary/common/utils';
import { DateTime } from 'luxon';
import { catchError, of } from 'rxjs';
import { LohnkontextFacade } from '../lohnkontext.facade';

interface AbrechnungenRequestPayload {
  abrechnungskreisId: string;
  abrechnungszeitraum: DateTime;
}

@Injectable({ providedIn: 'root' })
export class LohnkontextAbrechnungenFacade {
  private lohnkontextFacade = inject(LohnkontextFacade);
  private abrechnungenQueryService = inject(
    LohnkontextAbrechnungenQueryService,
  );
  private notificationService = inject(NOTIFICATION_SERVICE_TOKEN);
  private destroyRef = inject(DestroyRef);

  private readonly state = signal<{
    abrechnungen: Abrechnung[];
    yearsLoaded: number[];
  }>({ abrechnungen: [], yearsLoaded: [] });

  constructor() {
    this.initClearStateOnAbrechnungskreisChanged();
    this.initUpdateStateOnLohnkontextChange();
  }

  private static upsertArray<T>(array: T[], element: T) {
    if (array.includes(element)) {
      return [...array];
    }
    return [...array, element];
  }

  readonly select = {
    entities: computed(() => this.state().abrechnungen),
    isClosedByMonth: (abrechnungszeitraum: Signal<DateTime>) =>
      computed(() =>
        isMonthDone(
          this.state().abrechnungen,
          abrechnungszeitraum(),
          this.state().yearsLoaded,
        ),
      ),
    isClosed: computed(() =>
      isMonthDone(
        this.state().abrechnungen,
        this.lohnkontextFacade.select.abrechnungszeitraum(),
        this.state().yearsLoaded,
      ),
    ),
  };

  getIsClosedByMonth(month: DateTime) {
    return isMonthDone(
      this.state().abrechnungen,
      month,
      this.state().yearsLoaded,
    );
  }

  queryAbrechnungen(payload: AbrechnungenRequestPayload) {
    this.abrechnungenQueryService
      .getPerPage({
        queryParameters: {
          pageSize: 12,
          abrechnungskreisId: payload.abrechnungskreisId,
          abrechnungsmonatVon: payload.abrechnungszeitraum.startOf('year'),
          abrechnungsmonatBis: payload.abrechnungszeitraum
            .endOf('year')
            .startOf('day'),
        },
      })
      .pipe(
        catchError((error: SalaryError) => of(error)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((result) => {
        if (!isSalaryError(result)) {
          this.state.update((oldState) => ({
            abrechnungen: updateAndInsertItems(
              oldState.abrechnungen,
              ...result.results,
            ),
            yearsLoaded: LohnkontextAbrechnungenFacade.upsertArray(
              oldState.yearsLoaded,
              payload.abrechnungszeitraum.year,
            ),
          }));
        } else {
          this.state.update((oldState) => ({
            ...oldState,
            yearsLoaded: LohnkontextAbrechnungenFacade.upsertArray(
              oldState.yearsLoaded,
              payload.abrechnungszeitraum.year,
            ),
          }));
          this.notificationService.showError(
            'Fehler beim Ermitteln der abgeschlossenen Monate.',
            { name: 'Lohnkontext', iconName: 'today' },
            result.message,
          );
        }
      });
  }

  private clearAbrechnungenCache() {
    this.state.set({
      abrechnungen: [],
      yearsLoaded: [],
    });
  }

  private initClearStateOnAbrechnungskreisChanged() {
    const abrechnungskreisChanged = computed(
      () => this.lohnkontextFacade.abrechnungskreis()?.id,
    );
    effect(() => {
      abrechnungskreisChanged();
      untracked(() => this.clearAbrechnungenCache());
    });
  }

  private initUpdateStateOnLohnkontextChange() {
    const lohnkontextRelevantFields = computed(
      () => ({
        abrechnungskreisId: this.lohnkontextFacade.abrechnungskreis()?.id,
        abrechnungszeitraum: this.lohnkontextFacade.abrechnungszeitraum(),
      }),
      {
        equal: (a, b) =>
          a.abrechnungskreisId === b.abrechnungskreisId &&
          a.abrechnungszeitraum?.hasSame(b.abrechnungszeitraum, 'year'),
      },
    );
    effect(() => {
      const queryPayload = lohnkontextRelevantFields();
      if (
        !queryPayload.abrechnungskreisId ||
        !queryPayload.abrechnungszeitraum
      ) {
        return;
      }
      untracked(() => this.queryAbrechnungen(queryPayload));
    });
  }
}

function isMonthDone(
  abrechnungen: Abrechnung[],
  month: DateTime,
  yearsLoaded: number[],
): boolean | undefined {
  return untracked(() => {
    if (!yearsLoaded.includes(month?.year)) {
      return undefined;
    }
    return (
      abrechnungen.find((a) => a.abrechnungsmonat?.hasSame(month, 'month'))
        ?.abrechnungsStatus === AbrechnungsStatus.Abschluss
    );
  });
}
