import { Directive, effect, inject, signal, untracked } from '@angular/core';
import { BaseModel } from '@salary/common/dumb';
import { ColumnDefinition, ListConfiguration } from '../list';
import { ListContainerComponent } from '../list-container';
import { MasterDetailNavigationSettingsServiceBase } from '../settings';
import { ToolbarDefinition } from '../utils';
import { MASTER_DETAIL_INTERACTION_SERVICE } from './master-detail-interaction-service';

@Directive()
export abstract class MasterDetailMasterComponentBase<
  T extends BaseModel,
  U,
> extends ListContainerComponent<T> {
  override perspectiveKeySuffix = '-MasterDetailMasterComponent';
  protected masterDetailInteractionService = inject(
    MASTER_DETAIL_INTERACTION_SERVICE,
  );
  protected settingsService = inject<
    MasterDetailNavigationSettingsServiceBase<U>
  >(MasterDetailNavigationSettingsServiceBase, {
    optional: true,
  });

  constructor() {
    super();
    effect(() => {
      const selectedObjects = this.selectedModelObjects();
      untracked(() => {
        this.masterDetailInteractionService.selectedMasterObjects.set(
          selectedObjects,
        );
        this.lohnkontextFacade.setReadonly(selectedObjects.length > 1);
      });
    });
    effect(() => {
      const shown = this.loadingOverlayShown();
      untracked(() => {
        this.masterDetailInteractionService.masterComponentLoading.set(shown);
      });
    });
    effect(
      () => {
        const listEmpty = this.listEmpty();
        const emptySearch = this.listEmptySearch();
        untracked(() => {
          this.masterDetailInteractionService.masterlistEmpty.set(
            listEmpty || emptySearch,
          );
        });
      },
      { allowSignalWrites: true },
    );
  }

  protected override getListConfiguration(): ListConfiguration<T> {
    return {
      toolbarDefinitions: this.getToolbarDefinitions(),
      columnDefinitions: this.columnDefinitions ?? [],
    } as ListConfiguration<T>;
  }

  protected getToolbarDefinitions(): ToolbarDefinition[] {
    const toolbarDefinitions = [];
    this.searchToolbarDefinition.alignment = 'left';
    this.searchToolbarDefinition.title =
      this.facade?.pluralModelCaption + ' suchen...';
    toolbarDefinitions.push(this.searchToolbarDefinition);
    toolbarDefinitions.push(this.previousRecordToolbarDefinition);
    toolbarDefinitions.push(this.nextRecordToolbarDefinition);
    return toolbarDefinitions;
  }

  private readonly previousRecordToolbarDefinition: ToolbarDefinition = {
    title: 'Vorheriger Datensatz',
    actionHandler: () => {
      this.salaryList().selectRow('previous');
    },
    buttonCSSClass: 'no-margin',
    iconName: 'keyboard_arrow_up',
    hotkey: signal({
      keys: 'control.arrowUp',
      description: 'Strg + Pfeil oben = Vorheriger Datensatz',
    }),
    alwaysRoot: true,
  };

  private readonly nextRecordToolbarDefinition: ToolbarDefinition = {
    title: 'Nächster Datensatz',
    actionHandler: () => {
      this.salaryList().selectRow('next');
    },
    buttonCSSClass: 'no-margin',
    iconName: 'keyboard_arrow_down',
    hotkey: signal({
      keys: 'control.arrowDown',
      description: 'Strg + Pfeil unten = Nächster Datensatz',
    }),
    alwaysRoot: true,
  };

  protected setColumnDefinitions(columnDefinitions: ColumnDefinition<T>[]) {
    columnDefinitions.forEach((cd) => {
      cd.lockVisible = true;
    });
    this.applyPropertyDecoratorsToColumnDefinitions(columnDefinitions);
    this.columnDefinitions = columnDefinitions;
    this.salaryList()?.refreshColumnDefinitions(this.columnDefinitions);
  }

  getSettingsToolbarDefinition(): ToolbarDefinition {
    return {
      title: '...',
      testId: 'moreoptionsnavigationbutton',
      alignment: 'right',
      actionType: 'moreOptionButton',
      children: [
        {
          title: 'Einstellungen',
          actionHandler: () => this.settingsService.openDialog(),
          visibleInEmptyState: true,
        },
      ],
      visibleInEmptyState: true,
    };
  }
}
