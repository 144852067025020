import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ColDef, ITooltipParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'salary-tooltip',
  template: ` @if (showTooltip) {
    <div class="ag-tooltip mat-tooltip" data-testid="header-tooltip">
      {{ toolTip }}
    </div>
  }`,
  styles: `
    :host {
      position: absolute;
      width: 100%;
      height: 70px;
      pointer-events: none;
      transition: opacity 1s;
    }

    :host.ag-tooltip-hiding {
      opacity: 0;
    }
    :host .ag-tooltip {
      transform: none;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTooltipComponent implements ITooltipAngularComp {
  toolTip: string;
  showTooltip = false;
  agInit(params: ITooltipParams) {
    if (params.rowIndex == null) {
      this.toolTip = params.colDef.headerTooltip ?? params.colDef?.headerName;
      if (
        (<ColDef>params.colDef)?.headerComponentParams.alwaysShowHeaderTooltip
      ) {
        this.showTooltip = true;
        return;
      }
      const hoveredElements = document.querySelectorAll(':hover');
      const captionElement = hoveredElements[
        hoveredElements.length - 1
      ] as HTMLElement;
      if (captionElement?.offsetWidth < captionElement?.scrollWidth) {
        this.showTooltip = true;
      }
    }
  }
}
