import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { FormlyDialogComponent, FormlyDialogData } from '../components';
import { DialogComponent } from '../components/dialog/dialog.component';
import { DialogData } from '../components/dialog/dialog.data';
@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(private dialog: MatDialog) {}

  /**
   * @param config default of count = 1
   */
  confirmDeletion(config: {
    fromPapierkorb: boolean;
    count?: number;
  }): Observable<boolean> {
    const itemText =
      config.count > 1
        ? `Gewählte ${config.count} Datensätze`
        : 'Gewählten Datensatz';
    const dialogRef = this.openDialog({
      message:
        itemText +
        (config.fromPapierkorb
          ? ' entgültig entfernen?'
          : ' in den Papierkorb verschieben?'),
      button1Caption: 'Ja',
      button2Caption: 'Abbrechen',
    });
    return dialogRef.afterClosed().pipe(map((result) => result === 'button1'));
  }

  showMessageNoChoice(message: string) {
    const dialogRef = this.openDialog({
      message: message,
      button1Caption: 'Schließen',
    });
    return dialogRef.afterClosed();
  }

  openDialog(dialogData: DialogData) {
    return this.dialog.open(DialogComponent, {
      data: {
        button1Caption: dialogData.button1Caption,
        button2Caption: dialogData.button2Caption,
        message: dialogData.message,
        title: dialogData.title,
      },
      maxWidth: dialogData.maxWidth,
      panelClass: dialogData.panelClass,
    });
  }

  openFormlyDialog<T>(config: MatDialogConfig<FormlyDialogData<T>>) {
    return this.dialog.open<
      FormlyDialogComponent<T>,
      FormlyDialogData<T>,
      T | undefined
    >(FormlyDialogComponent<T>, config);
  }

  isAnyDialogOpened() {
    return this.dialog.openDialogs.length > 0;
  }
}
