import { KeyValue } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  signal,
  untracked,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getFieldName } from '@salary/common/dumb';
import {
  getFadeInAnimation,
  getFadeOutAnimation,
} from '@salary/common/dumb-components';
import { groupBy, mergeDeep } from '@salary/common/utils';
import { filter, startWith } from 'rxjs';
import { ColumnDefinition } from '..';
import { ToolbarDefinition } from '../../utils';
import {
  ColumnChooserData,
  ColumnDefinitionChooserData,
} from './column-definition-chooser-data';

@Component({
  selector: 'salary-column-chooser-dialog',
  templateUrl: './column-chooser-dialog.component.html',
  styleUrl: './column-chooser-dialog.component.scss',
  animations: [getFadeInAnimation(), getFadeOutAnimation()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnChooserDialogComponent {
  protected animationDisabled = signal(false);
  protected data = inject<ColumnChooserData>(MAT_DIALOG_DATA);
  private columnsData: ColumnDefinitionChooserData[] = this.data.columns;
  protected columnsDataFiltered = computed(() => {
    this.selectedColumnsChanged();
    return untracked(() =>
      this.columnsData.filter((item) => this.filterByVisible(item)),
    );
  });
  protected searchColumnControl = new UntypedFormControl();
  private searchTerm = toSignal(
    this.searchColumnControl.valueChanges.pipe(
      startWith(''),
      filter((value) => typeof value === 'string' || !value),
    ),
  );
  filteredColumns = computed(() => {
    this.selectedColumnsChanged();
    const searchTerm = this.searchTerm();
    return untracked(() => this.filterColumnsBySearchTerm(searchTerm));
  });
  selectedColumnsChanged = signal<string>('', { equal: () => false });
  toolbarDefinitions: ToolbarDefinition[] = [
    {
      title: '...',
      actionType: 'moreOptionButton',
      children: [
        {
          title: 'Zurücksetzen',
          actionHandler: () => {
            this.animationDisabled.set(true);
            setTimeout(() => {
              this.columnsData = this.data.resetPerspective().columns;
              this.selectedColumnsChanged.set('');
              setTimeout(() => this.animationDisabled.set(false), 600);
            });
          },
        },
      ],
    },
  ];

  constructor() {
    effect(() => {
      this.selectedColumnsChanged();
      untracked(() => {
        const enableSearch = this.addingNewColumnsAllowed();
        if (enableSearch != this.searchColumnControl.enabled) {
          enableSearch
            ? this.searchColumnControl.enable()
            : this.searchColumnControl.disable();
        }
      });
    });
  }

  private filterColumnsBySearchTerm(searchTerm: string) {
    const columns = this.search(searchTerm).map((columnData) => {
      const column = columnData.column;
      const columnTitleAndGroupName = this.getColumnTitleAndGroupName(column);
      return mergeDeep(null, columnData, {
        column: {
          columnTitleWithoutSuffix: columnTitleAndGroupName.title,
        },
        groupName: columnTitleAndGroupName.groupName,
      });
    });
    const result = new Map<string, ColumnDefinitionChooserData[]>();
    const groupedResult = groupBy(columns, (column) => column.groupName);
    Object.entries(groupedResult).forEach((entry) => {
      result.set(entry[0], entry[1]);
    });
    return result;
  }

  private getColumnTitleAndGroupName(column: ColumnDefinition<unknown>) {
    const columnTitle = column.columnTitle;
    const fieldName = getFieldName(column.modelPropertyName);

    const index = columnTitle.lastIndexOf('(');
    if (
      index === -1 ||
      (!fieldName?.includes('.') && !column.columnGroupName)
    ) {
      return { title: columnTitle, groupName: undefined };
    }
    const titleWithoutGroup = columnTitle.slice(0, index);
    const groupName = columnTitle.slice(index + 1, columnTitle.length - 1);
    return { title: titleWithoutGroup, groupName: groupName };
  }

  protected onCheckedChanged(
    columnData: ColumnDefinitionChooserData,
    checked: boolean,
  ) {
    columnData.column.visibility =
      columnData.column.visibility === false
        ? false
        : !checked
          ? 'onlyInColumnChooser'
          : true;
    columnData.onVisibilityChange(this.columnsData);
  }

  private search(value: string): ColumnDefinitionChooserData[] {
    if (value == null) {
      value = '';
    }
    const additionalColumns = this.columnsData
      .filter(
        (columnData) =>
          !columnData.fixed && columnData.column.visibility === false,
      )
      .sort((a, b) =>
        a.column.columnTitle.toLowerCase() > b.column.columnTitle.toLowerCase()
          ? 1
          : -1,
      );

    const filterValue = value?.toLowerCase();
    return additionalColumns.filter((columnData) =>
      columnData.column.columnTitle.toLowerCase().includes(filterValue),
    );
  }

  protected displayWithFunction(columnData: ColumnDefinitionChooserData) {
    return columnData?.column.columnTitle;
  }

  protected removeColumn(columnData: ColumnDefinitionChooserData) {
    columnData.column.visibility = false;
    columnData.onVisibilityChange(this.columnsData);
    this.selectedColumnsChanged.set('');
  }

  protected addColumn(columnsDataToAdd: ColumnDefinitionChooserData) {
    const columnToAdd = columnsDataToAdd.column;
    let columnsDataColumn = this.columnsData.find((cd) => {
      return (
        cd.column.modelPropertyName != null &&
        cd.column.modelPropertyName === columnToAdd.modelPropertyName
      );
    });
    if (!columnsDataColumn) {
      columnsDataColumn = this.columnsData.find((cd) => {
        return cd.column.columnTitle === columnToAdd.columnTitle;
      });
    }
    columnsDataColumn.column.visibility = true;
    columnsDataColumn.onVisibilityChange(this.columnsData);
    this.clearSearchColumnField();
    this.selectedColumnsChanged.set('');
  }

  protected clearSearchColumnField() {
    this.searchColumnControl.setValue(null);
  }

  protected addingNewColumnsAllowed() {
    return (
      this.additionalColumnsAvailable() && !this.maxVisibleColumnsReached()
    );
  }
  protected maxVisibleColumnsReached() {
    return (
      this.columnsData.filter(
        (columnData) =>
          columnData.fixed ||
          columnData.column.visibility === true ||
          columnData.column.visibility === 'onlyInColumnChooser',
      ).length >= 12
    );
  }
  private additionalColumnsAvailable() {
    return this.columnsData.some(
      (columnData) =>
        !columnData.fixed && columnData.column.visibility === false,
    );
  }
  private filterByVisible(columnData: ColumnDefinitionChooserData) {
    return (
      columnData.column.visibility === true ||
      columnData.column.visibility === 'onlyInColumnChooser' ||
      columnData.column.visibility == null
    );
  }

  protected onlyWithGroup(
    column: KeyValue<string, ColumnDefinitionChooserData<unknown>[]>,
  ) {
    return column.key != 'undefined';
  }

  protected noGroup(
    column: KeyValue<string, ColumnDefinitionChooserData<unknown>[]>,
  ) {
    return column.key == 'undefined';
  }
}
