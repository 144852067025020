import { signal } from '@angular/core';
import { Router } from '@angular/router';
import { BaseModel, Guid } from '@salary/common/dumb';
import {
  CreateOrUpdatePayload,
  DeleteOrRestorePayload,
} from '@salary/common/standard-facade';
import {
  IProcessManagerService,
  ProcessDefinition,
  distinct,
  spliceWithCondition,
} from '@salary/common/utils';
import {
  Observable,
  Unsubscribable,
  catchError,
  concat,
  debounceTime,
  defer,
  filter,
  map,
  merge,
  mergeMap,
  of,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { RouteBackService } from '../../router-management';
import { BaseComponent } from './base-component';
import {
  ComponentOperation,
  ComponentOperationType,
} from './component-operation';
import { EntityType } from './detail-base-configuration';
import { ProgressIndicationService } from './progress-indication.service';

export class RootRequestManager {
  private _childComponents = new Array<BaseComponent>();
  private processes = new Array<string>();
  saveButtonDisabled = signal<boolean>(false);
  private subscriptions: Unsubscribable[] = [];
  markedForDestroy = false;

  constructor(
    public rootComponent: BaseComponent,
    public processManagerService: IProcessManagerService,
  ) {
    if (!rootComponent.facade) return;
    this.subscriptions.push(
      rootComponent.model$.subscribe((modelObject) =>
        this.passModelObjectToValueObjectComponents(modelObject),
      ),
    );
    this.subscriptions.push(
      this.processManagerService.processCompleted$
        .pipe(filter((p) => !!this.getProcessById(p.id)))
        .subscribe((p) => this.processCompleted(p)),
    );
    this.registerFormStateChanges(rootComponent);
  }

  addChildComponent(child: BaseComponent) {
    this._childComponents.push(child);
    if (
      child.entityType === EntityType.ValueObject &&
      this.rootComponent.model()
    ) {
      child.setModelObject(this.rootComponent.model());
    }
    this.registerFormStateChanges(child);
  }

  removeChildComponent(child: BaseComponent) {
    this._childComponents = this.childComponents.filter((c) => c != child);
  }

  get childComponents(): ReadonlyArray<BaseComponent> {
    return this._childComponents;
  }
  get allComponents(): ReadonlyArray<BaseComponent> {
    return (this.rootComponent ? [this.rootComponent] : []).concat(
      this.childComponents,
    );
  }

  submit(
    afterCompletedOperation: () => void,
    additionalPostOperations?: AdditionalPostOperations,
  ) {
    const processId = additionalPostOperations?.processId ?? Guid.create();
    this.processes.push(processId);
    const componentsToProcessCandidates = this.allComponents.filter(
      (component) =>
        component.form.dirty &&
        component.facade &&
        component.entityType !== EntityType.ValueObject,
    );
    const operationsToExecuteBeforeAll = new Array<ComponentOperation>();
    const operationsToExecuteFirst = new Array<ComponentOperation>();
    const operationsToExecuteRest = new Array<ComponentOperation>();
    const operationsToExecuteLast = new Array<ComponentOperation>();

    componentsToProcessCandidates.forEach((c) => {
      const operations = this.getOperations(c);
      operations.forEach((op) => (op.payload.processId = processId));
      if (c.entityType === EntityType.AggregateRoot && c.isModelObjectNew()) {
        operationsToExecuteBeforeAll.push(...operations);
        if (operations.length === 1) {
          const operation = operations[0];
          const correlationId = Guid.create();
          operation.payload = {
            ...operation.payload,
            correlationId: correlationId,
          };
          const router = c.injector?.get(Router);
          c.injector
            ?.get(RouteBackService)
            .announceSaveOperation(router.url, c.facade, correlationId);
        }
      } else {
        if (c.operationExecutionDefinition) {
          operationsToExecuteFirst.push(
            ...this.getOperationsInOrder(
              c.operationExecutionDefinition.executeBeforeRest,
              operations,
            ),
          );
          operationsToExecuteLast.push(
            ...this.getOperationsInOrder(
              c.operationExecutionDefinition.executeAfterRest,
              operations,
            ),
          );
        }
        if (operations.length > 0) {
          operationsToExecuteRest.push(...operations);
        }
      }
    });

    const numberOfNeededSubmits =
      operationsToExecuteBeforeAll.length +
      operationsToExecuteFirst.length +
      operationsToExecuteRest.length +
      operationsToExecuteLast.length +
      +(additionalPostOperations?.operationsCount ?? 0);
    if (numberOfNeededSubmits === 0) {
      afterCompletedOperation();
      return undefined;
    }
    this.rootComponent.injector
      ?.get(ProgressIndicationService)
      .registerProgressWithProcessId({
        key: processId,
        operationType: 'Saving',
      });
    this.processManagerService.registerProcess(
      new ProcessDefinition(
        processId,
        numberOfNeededSubmits,
        this.rootComponent.facade.singularModelCaption +
          ProcessDefinition.CREATE_OR_UPDATE_SUCCESS_MESSAGE,
        ProcessDefinition.CREATE_OR_UPDATE_FAILED_MESSAGE,
        this.rootComponent.facade.notificationSource,
        afterCompletedOperation,
      ),
    );

    this.subscriptions.push(
      concat(
        this.executeRequests(operationsToExecuteBeforeAll, 'BeforeAll'),
        this.executeRequests(operationsToExecuteFirst, 'First'),
        this.executeRequests(operationsToExecuteRest, 'Rest'),
        this.executeRequests(operationsToExecuteLast, 'Last'),
        defer(() => {
          additionalPostOperations?.executeAdditionalOperations();
          return of(null);
        }),
      )
        .pipe(
          catchError((error) => {
            const listTypeWithError = error.listType as ListType;
            const numberOfCanceledOperations =
              this.getNumberOfOperationsToCancel(
                listTypeWithError,
                operationsToExecuteFirst,
                operationsToExecuteRest,
                operationsToExecuteLast,
              ) + (additionalPostOperations?.operationsCount ?? 0);
            this.processManagerService.cancelOperations(
              processId,
              numberOfCanceledOperations,
            );
            return of(undefined);
          }),
        )
        .subscribe(),
    );
    this.calculateSaveButtonState();
    return processId;
  }

  private getNumberOfOperationsToCancel(
    listTypeWithError: string,
    operationsToExecuteFirst: ComponentOperation[],
    operationsToExecuteRest: ComponentOperation[],
    operationsToExecuteLast: ComponentOperation[],
  ): number {
    let result = 0;
    if (listTypeWithError === 'BeforeAll') {
      result =
        operationsToExecuteFirst.length +
        operationsToExecuteRest.length +
        operationsToExecuteLast.length;
    } else if (listTypeWithError === 'First') {
      result = operationsToExecuteRest.length + operationsToExecuteLast.length;
    } else if (listTypeWithError === 'Rest') {
      result = operationsToExecuteLast.length;
    }
    return result;
  }

  private executeRequests(
    operationsToExecute: ComponentOperation[],
    listType: ListType,
  ): Observable<void> {
    return defer(() =>
      of(operationsToExecute.map((o) => this.executeComponentOperation(o))),
    ).pipe(
      switchMap((operationsToWaitFor) =>
        operationsToExecute.length === 0
          ? of(void 0)
          : merge(
              ...operationsToExecute
                .map((o) => o.facade)
                .filter(distinct)
                .map((f) =>
                  f.commandFinished().pipe(
                    filter(
                      (result) =>
                        result.succeeded ||
                        operationsToWaitFor.includes(result.correlationId),
                    ),
                    mergeMap((result) =>
                      result.succeeded
                        ? of(result.correlationId)
                        : throwError(() => ({
                            message: `operation failed (correlationId:${result.correlationId})`,
                            listType: listType,
                          })),
                    ),
                  ),
                ),
            ).pipe(
              map((succeededId) =>
                spliceWithCondition(
                  operationsToWaitFor,
                  (i) => i === succeededId,
                ),
              ),
              filter(
                (deletedItems) =>
                  deletedItems.length > 0 && operationsToWaitFor.length === 0,
              ),
              map(() => void 0),
              take(1),
            ),
      ),
    );
  }

  private getOperationsInOrder(
    operationOrder: ComponentOperationType[],
    operations: ComponentOperation[],
  ): ComponentOperation[] {
    const result = new Array<ComponentOperation>();
    operationOrder?.forEach((operationType) => {
      result.push(
        ...spliceWithCondition(operations, (o) => o.type === operationType),
      );
    });
    return result;
  }

  private getOperations(component: BaseComponent) {
    return component.getRequests();
  }

  private executeComponentOperation(operation: ComponentOperation) {
    if (operation.updateParentKeyCallback) {
      operation.updateParentKeyCallback(
        operation.payload as CreateOrUpdatePayload<BaseModel>,
        this.rootComponent.model(),
      );
    }
    operation.payload.correlationId ??= Guid.create();
    switch (operation.type) {
      case ComponentOperationType.Create: {
        operation.facade.create(
          operation.payload as CreateOrUpdatePayload<BaseModel>,
        );
        break;
      }
      case ComponentOperationType.Update: {
        operation.facade.update(
          operation.payload as CreateOrUpdatePayload<BaseModel>,
        );
        break;
      }
      case ComponentOperationType.Delete: {
        operation.facade.delete(
          operation.payload as DeleteOrRestorePayload<BaseModel>,
        );
        break;
      }
    }
    return operation.payload.correlationId;
  }

  reloadComponents() {
    this.allComponents.forEach((comp) => {
      comp.reload();
    });
  }

  resetComponents(switchModelObject: boolean) {
    this.allComponents.forEach((comp) => {
      comp.resetFormState(switchModelObject);
    });

    this.calculateSaveButtonState();
  }

  private calculateSaveButtonState() {
    const allComponentsValid = this.getAllComponentsValid();
    const atLeastOneFormDirty = this.atLeastOneFormDirty();
    const saveButtonDisabled = !(allComponentsValid && atLeastOneFormDirty);
    this.saveButtonDisabled.set(saveButtonDisabled);
  }

  getAllComponentsValid(): boolean {
    // for disabled forms (authorization), valid is always false
    return !this.allComponents.some((component) => component.form.invalid);
  }

  atLeastOneFormDirty(): boolean {
    return this.allComponents.some((component) => component.form.dirty);
  }

  private processCompleted(process: ProcessDefinition) {
    this.removeProcess(process.id);
    if (!process.hasErrors()) {
      const afterCompletedOperation = process.tag;
      if (!this.markedForDestroy && afterCompletedOperation) {
        (afterCompletedOperation as () => void)();
      }

      if (this.processes.length === 0 && this.markedForDestroy) {
        this.destroy();
      }
    }
  }

  private getProcessById(processId: string) {
    return this.processes.find((p) => p === processId);
  }

  private removeProcess(processId: string) {
    const index = this.processes.indexOf(processId);
    if (index < 0) return;
    this.processes.splice(index, 1);
  }

  private passModelObjectToValueObjectComponents(modelObject) {
    this.childComponents
      .filter((c) => c.entityType === EntityType.ValueObject)
      .forEach((component) => component.setModelObject(modelObject));
  }
  private registerFormStateChanges(component: BaseComponent) {
    this.subscriptions.push(
      component.formStateChanged$.pipe(debounceTime(100)).subscribe(() => {
        if (
          component.entityType === EntityType.ValueObject &&
          component.form.dirty
        ) {
          this.rootComponent.form.markAsDirty();
        }
        this.calculateSaveButtonState();
      }),
    );
  }
  destroy() {
    if (this.processes.length > 0) {
      this.markedForDestroy = true;
      return;
    }
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.rootComponent = undefined;
    this._childComponents = [];
    this.processes = [];
    this.processManagerService = undefined;
  }
}

type ListType = 'BeforeAll' | 'First' | 'Rest' | 'Last';
export interface AdditionalPostOperations {
  processId: string;
  operationsCount: number;
  executeAdditionalOperations: () => void;
}
