import { Injectable } from '@angular/core';
import {
  BaseHttpCommandService,
  encodeToBase64,
  EnvironmentConfigService,
} from '@salary/common/api/base-http-service';
import { HTMLText } from '@salary/common/dumb';

@Injectable({ providedIn: 'root' })
export class HTMLTextCommandService extends BaseHttpCommandService<HTMLText> {
  constructor(configurationService: EnvironmentConfigService) {
    super(configurationService.apiAdministrationUrl, 'HtmlText');
  }

  override modifyItemBeforeRequest(item: HTMLText): HTMLText {
    return { ...item, text: encodeToBase64(item.text) };
  }
}
