import { animate, AnimationBuilder, query, style } from '@angular/animations';
import { HttpStatusCode } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  Injector,
  input,
  Renderer2,
  signal,
  untracked,
} from '@angular/core';
import { BaseModel } from '@salary/common/dumb';
import { startPulseAnimation } from '@salary/common/dumb-components';
import { WrappedList } from './utils/wrapped-list';

@Component({
  selector: 'salary-list-empty-state-wrapper',
  template: `
    <div class="outer-container">
      @let listEmpty = wrappedList().listEmpty();
      @let loadingOverlayShown = wrappedList().loadingOverlayShown?.();
      <div style="min-height: 3px">
        @if (loadingOverlayShown) {
          <mat-progress-bar
            data-testid="loading_progress_bar"
            mode="indeterminate"
          />
        }
      </div>
      @if (pageRequestError() == null || loadingOverlayShown) {
        <div
          style="height:100%"
          class="gridParentElement"
          [style]="{
            display: invisibleList() ? 'none' : 'unset',
            'max-height': animationDone() && listEmpty ? '140px' : 'unset',
          }"
        >
          <ng-content />
        </div>
      } @else {
        @if (pageRequestError() != null) {
          <salary-server-error-empty-state />
        }
      }

      @if (listEmpty) {
        <ng-container
          [ngTemplateOutlet]="
            wrappedList().listConfiguration()?.emptyStateTemplate?.() ||
            defaultEmptyState
          "
        >
        </ng-container>
      }

      <ng-template #defaultEmptyState>
        @if (wrappedList().papierkorbListViewMode?.() !== true) {
          <div>
            <salary-list-empty-state
              style="z-index:1"
              [size]="wrappedList().emptyStateSize ?? 'large'"
              [disableCaption]="
                wrappedList().emptyStateSize === 'small' ? true : false
              "
              [toolbarDefinition]="
                wrappedList().listConfiguration()
                  ?.emptyStateToolbarDefinition ??
                wrappedList().hinzufuegenToolbarDefinition
              "
              [newItemRow]="wrappedList().listConfiguration()?.newItemRow"
              [newItemRowButtonClicked]="
                this.wrappedList().newItemRowVisibleByUser?.()
              "
              [captionPlural]="wrappedList().facade?.pluralModelCaption"
              [targetLohnkontextProperty]="
                wrappedList().getEmptyStateLohnkontextProperty != null
                  ? wrappedList().getEmptyStateLohnkontextProperty()
                  : 'none'
              "
              [notFoundText]="
                wrappedList().listConfiguration()?.notFoundText?.()
              "
            />
          </div>
        } @else {
          <div class="special-state">
            <salary-list-papierkorb-empty-state />
          </div>
        }
      </ng-template>
      @if (wrappedList().listEmptySearch?.()) {
        <salary-list-search-empty-state class="special-state" />
      }
    </div>
  `,
  styles: `
    .outer-container {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListSearchEmptyStateWrapperComponent<T extends BaseModel> {
  wrappedList = input.required<WrappedList<T>>();
  private injector = inject(Injector);
  private renderer = inject(Renderer2);
  private animationBuilder = inject(AnimationBuilder);
  private elementRef = inject(ElementRef);
  protected pageRequestError = computed(() => {
    const error = this.wrappedList().pageRequestError();
    return untracked(() => {
      const statusCode = error?.statusCode;
      if (
        typeof statusCode !== 'string' &&
        this.ERRORSTATUSCODES_TO_HANDLE.includes(statusCode)
      ) {
        return statusCode;
      }
      return undefined;
    });
  });
  private readonly ERRORSTATUSCODES_TO_HANDLE = [
    HttpStatusCode.InternalServerError,
    HttpStatusCode.ServiceUnavailable,
  ];
  protected animationDone = signal(false);
  protected invisibleList = computed(() => {
    const listEmpty = this.wrappedList().listEmpty();
    const listEmptySearch = this.wrappedList().listEmptySearch?.() ?? false;
    const animationAlreadyRun = this.animationDone();
    const newItemRowVisibleByUser =
      this.wrappedList().newItemRowVisibleByUser?.() ?? false;
    const papierkorbVisible =
      this.wrappedList().papierkorbListViewMode?.() ?? false;
    return untracked(() => {
      return (
        (listEmpty &&
          !animationAlreadyRun &&
          (newItemRowVisibleByUser === false || papierkorbVisible)) ||
        listEmptySearch
      );
    });
  });

  private showGridByUserActionMethod() {
    const animation = this.animationBuilder.build([
      query('.gridParentElement', [
        style({ 'max-height': '0px' }),
        animate(
          '1000ms cubic-bezier(0.4,0.0,0.2,1)',
          style({ 'max-height': '140px' }),
        ),
      ]),
    ]);
    const player = animation.create(this.elementRef.nativeElement);
    player.play();
    player.onDone(() => {
      this.animationDone.set(true);
      player.destroy();
    });
  }

  ngAfterViewInit() {
    if (this.wrappedList().newItemRowVisibleByUser) {
      effect(
        () => {
          const show = this.wrappedList().newItemRowVisibleByUser();
          untracked(() => {
            this.animationDone.set(false);
            if (show) {
              this.startShowNewItemRowAnimation();
              if (this.wrappedList().listEmpty()) {
                this.showGridByUserActionMethod();
              }
            }
          });
        },
        { injector: this.injector },
      );
    }
  }

  private startShowNewItemRowAnimation() {
    const element = this.wrappedList()
      .salaryList()
      .agGrid()
      .nativeElement.querySelector('.ag-floating-top');

    if (element) {
      const animation = this.animationBuilder.build([
        style({ 'max-height': '0px', 'min-height': '0px', opacity: '0' }),
        animate(
          '500ms cubic-bezier(0.4,0.0,0.2,1)',
          style({ 'min-height': '49px', opacity: '1' }),
        ),
      ]);
      const player = animation.create(element);
      player.onStart(() => {
        setTimeout(
          () =>
            startPulseAnimation({
              parentElement: element,
              renderer: this.renderer,
              animationBuilder: this.animationBuilder,
              duration: 1000,
              iconName: 'edit_note',
              doneHandler: () => {
                setTimeout(() =>
                  this.wrappedList().focusFirstCellInNewItemRow(),
                );
              },
            }),
          250,
        );
      });
      player.play();
    }
  }
}
