import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  numberAttribute,
} from '@angular/core';

type ShimmerTemplateStyle = 'listWithAvatarLines' | 'largeAndSmallLine';

type ShimmerElement = 'AvatarWithLines' | 'SmallLine' | 'LargeLine';

@Component({
  selector: 'salary-shimmer',
  templateUrl: './shimmer.component.html',
  styleUrl: './shimmer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShimmerComponent {
  shimmerTemplateStyle = input<ShimmerTemplateStyle>('listWithAvatarLines');
  shimmerConfig = input<ShimmerElement[]>();
  repeat = input([0], {
    transform: (count: number | string) =>
      Array(numberAttribute(count, 1)).fill(0),
  });

  protected shimmerConfigResult = computed(
    () => this.shimmerConfig() ?? this.initilizeShimmerConfigByTemplate(),
  );

  private initilizeShimmerConfigByTemplate() {
    if (this.shimmerTemplateStyle() === 'listWithAvatarLines') {
      return ['AvatarWithLines', 'SmallLine', 'SmallLine', 'SmallLine'];
    } else if (this.shimmerTemplateStyle() === 'largeAndSmallLine') {
      return ['LargeLine', 'SmallLine'];
    }
    return [];
  }
}
