import { Injectable, signal } from '@angular/core';
import {
  FieldConfig,
  convertToSignal,
  getModelClass,
} from '@salary/common/formly';
import { CaptionHelper, stringifyEquals } from '@salary/common/utils';

@Injectable({ providedIn: 'root' })
export class FocusFieldInfoService {
  public elementFocused = signal<FocusInfo>(undefined, {
    equal: stringifyEquals,
  });

  public reportFieldFocused(fieldConfig: FieldConfig) {
    const label = convertToSignal('label', fieldConfig)?.();
    const propertyName =
      label ?? convertToSignal('placeholder', fieldConfig)?.();
    if (!propertyName || !fieldConfig?.fieldId) {
      return;
    }
    const modelClass = getModelClass(fieldConfig);
    const focusInfo: FocusInfo = {
      propertyName: propertyName,
      fieldId: fieldConfig.fieldId,
      modelName: modelClass ? CaptionHelper.getCaptionSingular(modelClass) : '',
    };
    this.elementFocused.set(focusInfo);
  }

  public reportColumnFocused(focusInfo: FocusInfo) {
    this.elementFocused.set(focusInfo);
  }
}

export interface FocusInfo {
  modelName: string;
  propertyName: string;
  fieldId: string;
}
