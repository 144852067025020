<span
  class="search-input-container"
  (focusout)="onInputLostFocus($any($event.relatedTarget))"
  (focusin)="onInputGotFocus()"
>
  @let isInvalidReference = isInvalidReference$ | async;
  @if (isInvalidReference) {
    <mat-icon
      [attr.data-testid]="testId() + '_search_field_reference_icon'"
      [matTooltip]="invalidReferenceMessage"
      class="search-input-prefix-icon"
      >link_off</mat-icon
    >
  }
  <input
    #input
    [salaryPopupEnabled]="this"
    [enablePopupWithF7]="enablePopupWithF7()"
    matInput
    [class.invalidReference]="isInvalidReference"
    [matAutocomplete]="auto"
    [matAutocompleteConnectedTo]="autocompleteConnectedTo()"
    [matAutocompleteDisabled]="hideItems()"
    [formControl]="formControl()"
    [salaryFormlyAttributes]="formlyField()"
    [placeholder]="placeholder()"
    [errorStateMatcher]="errorStateMatcher()"
    [readonly]="inputDisabled()"
    [required]="requiredInput()"
    [attr.data-testid]="testId() | convertSpecialCharacter"
    [class.select-style]="inputDisabled()"
    (keydown)="onKeyDown($event)"
    (click)="onInputClick()"
    (input)="onInput($event)"
  />
  <ng-template #searchInputSuffixContainer>
    <span class="search-input-suffix-buttons">
      @if (isLoading()) {
        <mat-spinner class="search-input-spinner" diameter="16" />
      }
      @if (!printMode) {
        <salary-button-speed-dial [testId]="testId()">
          <a
            type="button"
            class="small-button"
            *salaryWithPermission="
              REQUIRED_PERMISSION_FOR_LINK;
              byRoute: referenceRouterLink();
              visibleIf: isLinkActive()
            "
            mat-icon-button
            [routerLink]="[
              referenceRouterLink() | formatObject: mappingParent(),
            ]"
            [state]="{ createRouteBackInfo: true }"
            [attr.data-testid]="
              testId() + '_search_field_link_button' | convertSpecialCharacter
            "
            tabIndex="-1"
            matTooltip="Datensatz anzeigen"
          >
            <mat-icon>link</mat-icon>
          </a>
          @for (
            customInputSuffixTemplate of customInputSuffixTemplates();
            track $index
          ) {
            <ng-container [ngTemplateOutlet]="customInputSuffixTemplate">
            </ng-container>
          }
          @if (!disabled) {
            @if (formControlValue() != null && formControlValue() !== '') {
              <button
                type="button"
                class="small-button"
                mat-icon-button
                (click)="onClearButtonClick()"
                [attr.data-testid]="
                  testId() + '_search_field_clear_button'
                    | convertSpecialCharacter
                "
                tabindex="-1"
                matTooltip="Auswahl löschen"
              >
                <mat-icon>close</mat-icon>
              </button>
            } @else if (!hideSearchIcon()) {
              <div
                (click)="onInputClick()"
                [attr.data-testid]="
                  testId() + '_search_field_search_icon'
                    | convertSpecialCharacter
                "
              >
                <mat-icon class="small-button colorAccent">search</mat-icon>
              </div>
            }
          }
        </salary-button-speed-dial>
      }
    </span>
  </ng-template>
  <ng-container
    [ngTemplateOutlet]="
      embedInputSuffixButtons() ? searchInputSuffixContainer : undefined
    "
  >
  </ng-container>
</span>
<mat-autocomplete
  [salaryAutocompleteheight]="
    emptyStateHinzufuegenLink != null || emptyStateExtendedDescription() != null
  "
  [class]="searchOverlayClass() + ' search-animation'"
  #auto="matAutocomplete"
  autoActiveFirstOption
  [displayWith]="
    useDisplayTextOrFormatForInput() ? createDisplayTextLine1.bind(this) : null
  "
  #searchFieldInput
  (optionSelected)="onOptionSelected($event)"
>
  @if (customPanelHeader()) {
    <div class="panel-header">
      <ng-container [ngTemplateOutlet]="customPanelHeader()" />
    </div>
  }
  @let itemsToShow = optionItemsToShow$ | async;
  <div [@slideInOut]="itemsToShow?.length">
    @if (popupModeEnabled$ | async) {
      @if (!isLoading() && itemsToShow?.length > 0) {
        @for (
          optionItem of itemsToShow;
          track identifyOptionItem(optionItem);
          let i = $index
        ) {
          <mat-option
            [value]="getBoundModelValue(optionItem)"
            [salaryMatOptionData]="optionItem"
            [attr.data-testid]="
              testId() + '_option_item_' + i | convertSpecialCharacter
            "
            class="search-option-item"
            tabindex="0"
          >
            <ng-container
              [ngTemplateOutlet]="
                optionItemTemplate() || defaultOptionItemTemplate
              "
              [ngTemplateOutletContext]="{
                $implicit: optionItem,
                line1Text: createDisplayTextLine1(optionItem),
                line2Text: createDisplayTextLine2(optionItem),
                searchTerm: formControlValue(),
                index: i,
                testId: testId(),
              }"
            >
            </ng-container>
            <ng-template
              #defaultOptionItemTemplate
              let-line1Text="line1Text"
              let-line2Text="line2Text"
              let-searchTerm="searchTerm"
              let-index="index"
              let-testId="testId"
            >
              <salary-two-line-option
                [line1Text]="line1Text"
                [line2Text]="line2Text"
                [searchTerm]="searchTerm"
                [index]="index"
                [testId]="testId"
              >
              </salary-two-line-option>
            </ng-template>
          </mat-option>
        }
      } @else {
        @if (isLoading()) {
          <mat-option disabled>
            <salary-shimmer
              style="display: inherit"
              data-testid="search_loading_shimmer"
              [shimmerConfig]="['LargeLine']"
              [repeat]="shimmerLines$ | async"
            />
          </mat-option>
        } @else {
          @if (itemsToShow?.length === 0) {
            <mat-option disabled class="special-option-item" id="empty-state">
              <salary-search-input-empty-state
                [hinzufuegenLink]="emptyStateHinzufuegenLink"
                [extendedDescription]="emptyStateExtendedDescription()"
              >
              </salary-search-input-empty-state>
            </mat-option>
          }
        }
      }
    }
  </div>
  @if (moreItemsExists()) {
    <div style="flex-direction: column; align-items: center; display: flex">
      <label class="current-page-info">{{ currentPageModelText() }}</label>
      <mat-divider style="margin-top: 1%; align-self: stretch" />
      <button
        class="more-items-button"
        mat-button
        (click)="onMoreItemsButtonClick()"
        [attr.data-testid]="
          testId() + '_search_field_more_items_button' | convertSpecialCharacter
        "
        [salaryLoadingButton]="isLoadingMoreItems()"
      >
        Weitere anzeigen
      </button>
    </div>
  }
</mat-autocomplete>
