import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseHttpQueryService,
  BaseHttpUtils,
  decodeBase64,
  EnvironmentConfigService,
  GetByKeyEndpointConfiguration,
} from '@salary/common/api/base-http-service';
import { HTMLText } from '@salary/common/dumb';
import { catchError, map, of, retry, switchMap, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HTMLTextQueryService extends BaseHttpQueryService<HTMLText> {
  constructor(configurationService: EnvironmentConfigService) {
    super({
      url: configurationService.apiAdministrationUrl,
      endpointFormat: 'HtmlText',
    });
  }

  override getByKey(endpointConfiguration: GetByKeyEndpointConfiguration) {
    return this.httpClient
      .get<HTMLText>(
        `${this.url}/${this.endpointFormat}/byKey/${endpointConfiguration.key}`,
        { observe: 'response' },
      )
      .pipe(
        retry(1),
        switchMap((response) =>
          response.status !== HttpStatusCode.NoContent
            ? of(response.body).pipe(
                map((htmlText) => {
                  return { ...htmlText, text: decodeBase64(htmlText.text) };
                }),
              )
            : throwError(() => response),
        ),
        catchError(BaseHttpUtils.throwHandledError),
      );
  }
}
